<template>
    <transition tag="id" id="active" role="tabpanel" aria-labelledby="active-tab" name="fadeoutin" mode="out-in">

        <div class="wrapper-card-policy-summary" name="fadeoutin" mode="out-in">

            <!-- START Card Policy Summary -->
            <div :class="cardPolicyClass(policy.active)" v-for="policy in policies" :key="policy.id">
                <img src="../../../../assets/svg/expired-policy-badge.svg" class="expired" v-if="!policy.active" />
                <div class="top-row">
                    <div class="icon">
                        <i class="fad fa-car"></i>
                    </div>
                    <div class="heading">
                        <p>Insurance policy for</p>
                        <h3>{{policy.policyType}}, {{policy.destination}}, {{policy.startDate}} - {{policy.endDate}}</h3>
                    </div>
                    <div class="cta">
                        <a @click="$emit('viewPolicy', policy)" class="button-primary">View Policy</a>
                    </div>
                </div>
                <div class="bottom-row">
                    <div>
                        <p class="label">Policy No.</p>
                        <p class="value">{{policy.number}}</p>
                    </div>
                    <div>
                        <p class="label">Purchased</p>
                        <p class="value">{{policy.purchaseDate}}</p>
                    </div>
                    <div>
                        <p class="label">Total cost</p>
                        <p class="value">£{{policy.totalCost}}</p>
                    </div>
                    <div>
                        <p class="label">Policy Type</p>
                        <p class="value">{{policy.policyType}}</p>
                    </div>
                    <div>
                        <p class="label">Drivers</p>
                        <p class="value">x{{policy.mainDrivers.length}} main, x{{policy.namedDrivers.length}} named</p>
                    </div>
                </div>
            </div>
            <!-- END Card Policy Summary -->

        </div>

    </transition>
</template>

<script>
export default {
    name: 'policies-active',
    props: {
        policies: Array
    },
    methods: {
        cardPolicyClass(active) {
            if(active) {
                return 'card-policy-summary';
            }
            else {
                return 'card-policy-summary expired';
            }
        }
    }
}
</script>