<template>
    <div>
        <heroInner :title="title"/>
        <div class="container">

        <a class="back-link" href="#"><i class="fas fa-caret-left"></i>Back</a>

        <nav class="breadcrumbs">
            <ul>
                <li><router-link to="/home">Home</router-link></li>
                <li><router-link to="/sitemap">Sitemap</router-link></li>
            </ul>
        </nav>

        <div class="row">
            <div class="col-md-8 col-sm-12">
                <div class="inner">
                    <h2>Sitemap</h2>
                    <p class="subheading">"We've produced an interactive visual representation of our websites structure using HTML, which showcases the skeletal structure of our website.</p>
                    <div class="main-content">
                        <div class="internal-anchor-links">
                            <h3 id="link1">Navigation</h3>
                            <ul class="sitemap">
                                <li><router-link to="/home">Home</router-link></li>
                                <li><router-link to="/get-a-quote">Get a quote</router-link></li>
                                <li><router-link to="/faq">Faq</router-link></li>
                                <li><router-link to="/contact-us">Contact us</router-link></li>
                            </ul>

                            <h3 id="link2">Care hire excess</h3>
                            <ul class="sitemap">
                                <li><router-link :to="{ path: '/home', hash: '#what-is-car-hire-excess-insurance' }">What is car hire excess insurance?</router-link></li>
                                <li><router-link :to="{ path: '/home', hash: '#who-is-eligible' }">Who is eligible?</router-link></li>
                                <li><router-link :to="{ path: '/home', hash: '#what-is-included' }">What is included?</router-link></li>
                            </ul>

                            <h3 id="link3">Miscellaneous</h3>
                            <ul class="sitemap">
                                <li><router-link to="/privacy-policy">Privacy policy</router-link></li>
                                <li><router-link to="/accessibility">Accessibility</router-link></li>
                                <li><router-link to="/sitemap">Sitemap</router-link></li>
                                <li><router-link to="/legal">Legal</router-link></li>
                            </ul>

                            <h3 id="link4">Find out more</h3>
                            <ul class="sitemap">
                                <li><a href="https://www.switchedoninsurance.com/about-us">About us</a></li>
                                <li><a href="https://www.linkedin.com/company/switchedoninsurance/jobs/">Careers</a></li>
                                <li><a href="https://www.linkedin.com/company/switchedoninsurance/about/">LinkedIn</a></li>
                                <li><a href="https://uk.trustpilot.com/review/switchedoninsurance.com">Trustpilot</a></li>
                            </ul>

                            <h3 id="link5">Form</h3>
                            <ul class="sitemap">
                                <li><router-link to="/your-quote">Your quote</router-link></li>
                                <li><router-link to="/important-information">Important information</router-link></li>
                                <li><router-link to="/your-details">Your details</router-link></li>
                                <li><router-link to="/driver-details">Driver details</router-link></li>
                                <li><router-link to="/your-order">Your order</router-link></li>
                                <li><router-link to="/confirmation" class="active">Confirmation</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <aside class="sidebar" id="sidebar">
                    <h4>Skip to section</h4>
                    <ul>
                        <li><a href="#link1">Navigation</a></li>
                        <li><a href="#link2">Care hire excess</a></li>
                        <li><a href="#link3">Miscellaneous</a></li>
                        <li><a href="#link4">Find out more</a></li>
                        <li><a href="#link5">Form</a></li>
                    </ul>
                </aside>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import heroInner from "../components/heroInner";
export default {
    name: 'Sitemap',
    data: () => ({
        title: 'Sitemap',
        pageDescription: "We've produced a visual representation of our websites structure using HTML, which maps out the sites skeletal structure."
    }),
    components: {
        heroInner
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
}
</script>