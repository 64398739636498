<template>
    <form>
        <fieldset class="common">
            <blockquote>
                If you have changed your email please contact us to change it.
            </blockquote>
        </fieldset>
        <label for="radio-mr">Title</label>
        <div class="radio-toolbar">
            <input type="radio" id="radio-mr" name="title" value="Mr" v-model="title">
            <label class="title" for="radio-mr" tabindex="0">Mr</label>
            <input type="radio" id="radio-mrs" name="title" value="Mrs" v-model="title">
            <label class="title" for="radio-mrs" tabindex="0">Mrs</label>
            <input type="radio" id="radio-miss" name="title" value="Miss" v-model="title">
            <label class="title" for="radio-miss" tabindex="0">Miss</label>
            <input type="radio" id="radio-ms" name="title" value="Ms" v-model="title">
            <label class="title" for="radio-ms" tabindex="0">Ms</label>
            <input type="radio" id="radio-mx" name="title" value="Mx" v-model="title">
            <label class="title" for="radio-mx" tabindex="0">Mx</label>
        </div>

        <fieldset class="common">
            <label for="first-name">First name</label>
            <input type="text" id="first-name" v-model="firstName" v-taurus-validator="'required'" fieldlabel="First name"/>
            <div name="first-name"></div>
        </fieldset>

        <fieldset class="common">
            <label for="last-name">Last name</label>
            <input type="text" id="last-name" v-model="lastName" v-taurus-validator="'required'" fieldlabel="Last name"/>
            <div name="last-name"></div>
        </fieldset>                            
        <fieldset class="common">
            <p>Date or birth</p>
            <span class="ib">
                <label for="dob-day">Day</label>
                <input type="text" id="dob-day" maxlength="2" size="2" v-taurus-validator="'required|maxint:31'" fieldlabel="Day" v-model="day" v-on:keypress="numbersOnly"/>
            </span>
            <span class="ib">
                <label for="dob-month">Month</label>
                <input type="text" id="dob-month" maxlength="2" size="2" v-taurus-validator="'required|maxint:12'" fieldlabel="Month" v-model="month" v-on:keypress="numbersOnly"/>
            </span>
            <span class="ib">
                <label for="dob-year">Year</label>
                <input type="text" id="dob-year" maxlength="4" size="4" v-taurus-validator="'required'" fieldlabel="Year" v-model="year" v-on:keypress="numbersOnly"/>
            </span>
            <div name="dob-day"></div>
            <div name="dob-month"></div>
            <div name="dob-year"></div>
        </fieldset>
        <fieldset class="common">
            <label for="telephone-number">Telephone number</label>
            <input type="tel" id="telephone-number" pattern="[0-9]" maxlength="11" v-model="telephone" v-taurus-validator="'required'" fieldlabel="Telephone number"/>
            <div name="telephone-number"></div>
        </fieldset>

        <fieldset class="common">
            <label for="address-line-1">Address line 1</label>
            <input type="text" id="address-line-1" v-model="addressLine1" v-taurus-validator="'required'" fieldlabel="Address line 1"/>
            <div name="address-line-1"></div>
        </fieldset>

        <fieldset class="common">
            <label for="address-line-2">Address line 2 <span class="optional">&mdash; Optional</span></label>
            <input type="text" name="address-line-2" id="address-line-2" v-model="addressLine2"/>
        </fieldset>

        <fieldset class="common">
            <label for="towncity">Town/city</label>
            <input type="text" id="towncity" v-model="town" v-taurus-validator="'required'" fieldlabel="Town/city"/>
            <div name="towncity"></div>
        </fieldset>

        <fieldset class="common">
            <label for="county">County</label>
            <input type="text" id="county" v-model="county" v-taurus-validator="'required'" fieldlabel="County"/>
            <div name="county"></div>
        </fieldset>

        <fieldset class="common">
            <label for="postcode">Postcode</label>
            <input type="text" class="postcode" id="postcode" v-model="postcode" v-taurus-validator="'required'" fieldlabel="Postcode"/>
            <div name="postcode"></div>
        </fieldset>

        <!-- <fieldset class="common">
            <label for="email-address">Email address</label>
            <input type="email" 
                    id="email-address" 
                    size="30" 
                    v-model="emailAddress" 
                    v-taurus-validator="'required'" 
                    fieldlabel="Email address" />
            <div name="email-address"></div>
        </fieldset> -->
        <a class="button-secondary button-large" v-on:click="$emit('cancelEdit')">Cancel</a>
        <!-- <a class="button-primary button-large">Save details</a> -->
        <loadingButton buttonLabel="Save details" :isLoading="customerLoading" @onClick="updateDetails"/>
        <div class="taurus-invalid" v-if="formErrorSummary">There are errors in this form</div> 
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import commonMixin from '../../mixins/common';
import loadingButton from '../loadingButton.vue';

export default {
    name: 'editCustomerForm',
    data: () => ({
        title: '',
        firstName: '',
        lastName: '',
        day: '',
        month: '',
        year: '',
        telephone: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        postcode: '',
        emailAddress: '',
        formErrorSummary: false
    }),
    mixins: [
        commonMixin
    ],
    components: {
        loadingButton
    },
    mounted() {        
        this.title = this.customer.title
        this.firstName = this.customer.firstName
        this.lastName = this.customer.lastName
        this.emailAddress = this.customer.emailAddress
        this.telephone = this.customer.telephoneNumber
        this.addressLine1 = this.customer.addresses[0].addressLine1
        this.addressLine2 = this.customer.addresses[0].addressLine2
        this.town = this.customer.addresses[0].townCity
        this.county = this.customer.addresses[0].county
        this.postcode = this.customer.addresses[0].postCode
        var dobArr = this.formatDisplayTime(this.customer.dateOfBirth).split('/')
        this.day = dobArr[0];
        this.month = dobArr[1];
        this.year = dobArr[2];
    },
    methods: {
        async updateDetails() {
            if(this.checkForm()) {
                //make call to store
                var data = {
                    title: this.title,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    emailAddress: this.emailAddress,
                    dateOfBirth: this.formatCSharpTime(this.constructDob(this.year, this.month, this.day), 'YYYY/MM/DD'),
                    telephone: this.telephone,
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    town: this.town,
                    county: this.county,
                    postcode: this.postcode,
                    uid: this.customer.uid
                }
                var details = Object.assign({}, data);                
                // console.log(details);
                //create user acc               
                await this.$store.dispatch('customer/updateDetails', details);
                
                if (!this.isCustomerUpdateError) {
                    this.$router.push('main-driver/0');
                }                                        
            } 
        },
        cancelEditCustomer() {
            // this.resetCustDetails();
            this.editDetails = false;
        },
        checkFormErrors() {            
            this.$nextTick(() => { this.formErrors().length > 0 })
        }
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            customerLoading: 'customer/getCustomerLoading',
        })
    },
    watch: {
        firstName() {
            this.checkFormErrors();
        },
        lastName() {
            this.checkFormErrors();
        },
        day() {
            this.checkFormErrors();
        },
        month() {
            this.checkFormErrors();
        },
        year() {
            this.checkFormErrors();
        },
        telephone() {
            this.checkFormErrors();
        },
        addressLine1() {
            this.checkFormErrors();
        },
        addressLine2() {
            this.checkFormErrors();
        },
        townCity() {
            this.checkFormErrors();
        },
        county() {
            this.checkFormErrors();
        },
        postcode() {
            this.checkFormErrors();
        }
    }
}
</script>