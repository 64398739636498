<template>
    <div class="" :id="'sidebar-' + tabId" role="tabpanel" :aria-labelledby="'sidebar-' + tabId + '-tab'">
        <h1>Account</h1>
        <p>Control your personal information and update your account details.</p>
        <tabbed-nav :tabs="tabs" :defaultTab="currentTab" v-on:newTab="switchTab">
            <component v-bind:is="currentTabComp" v-bind="currentTabProps"></component> 
        </tabbed-nav>

    </div>
</template>

<script>
import personalInformation from './tabs/personal-information.vue';
import accountDetails from './tabs/account-details.vue';
import yourAddresses from './tabs/your-addresses.vue';
import tabbedNav from '../../tabbedNav.vue';

export default {
    name: 'account-account',
    props: {
        customer: {
            type: Object,
            default: function() {
                return {
                    customer: {},
                    addresses: [{}],
                    account: {}
                }
            }
        }
    },
    components: {
        personalInformation,
        accountDetails,
        yourAddresses,
        tabbedNav
    },
    data: () => ({
        tabs: [ 
            { id: 'personal-information', text: 'Personal Information' },
            { id: 'your-addresses', text: 'Your Addresses' },
            { id: 'account-details', text: 'Log In Details' }
        ],
        currentTab: 'personal-information'
    }),
    methods: {
        switchTab(t) {
            this.currentTab = t;
        }
    },
    computed: {
        tabId() {
            return this.$options.name.split('-')[1];         
        },
        currentTabComp: function() {
            return this.currentTab;
        },
        currentTabProps: function() {
            if(this.currentTab == 'personal-information') {
                return { customer: this.customer.customer };
            }
            if(this.currentTab == 'your-addresses') {
                return { addresses: this.customer.addresses };
            }
            if(this.currentTab == 'account-details') {
                return { account: this.customer.account };
            }
        }        
    }
}
</script>