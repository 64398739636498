<template>
    <div class="flex-wrapper">
        <div class="flex-container">
            <div class="flex-item">
                <div class="logo-flex">
                    <router-link to="/home" title="Return to the home page">
                        <img :src="getImgUrl()" alt="SOI Logo" class="brand-logo-login" />
                    </router-link>
                    <span class="pipe"></span>
                    <p>Car Hire Excess Insurance</p>
                </div>
                <div class="row no-gutters shadow">
                    <div class="col-lg-6 col-sm-12">
                        <form id="log-in">
                            <p v-if="accConfirmed"><b>Account Confirmed.</b></p>
                            <p>Please enter your username and password to access the car hire excess insurance system.</p>

                            <fieldset class="common">
                                <label for="email-address">Email address</label>
                                <input type="email" name="email-address" id="email-address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" size="30" v-model="email" required />
                            </fieldset>

                            <fieldset class="common">
                                <label for="password">Password</label>
                                <input type="password" name="password" id="password" size="30" v-model="password" required />
                                <small class="signin-error" v-if="signInError">Please check your details and try and again</small>
                            </fieldset>
                            <a @click="login" class="button-primary button-large button-full">Log in</a>
                            <router-link to="/password-reset" class="link forgotten-password">Forgotten password?</router-link>
                        </form>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="register-today">
                            <div>
                                <p>Don't have an account?</p>
                            </div>
                            <div>
                                <router-link to="/create-account" class="button-secondary">Register today</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            title: 'Log in',
            pageDescription: 'Log in to your Car Hire Excess Insurance account today to load your details and access your account in no time.',
            email: '',
            password: '',
            accConfirmed: false,
            signInError: false
        };
    },
    mounted() {
        this.accConfirmed = this.$route.query.confirm
    },
    methods: {
        ...mapActions({
            loginAsync: "account/login",
            testAsync: "customer/testUser"
        }),
        async login() {            
            const { email, password } = this;
            const payload = {email: email, password: password};
            this.signInError = false;

            await this.loginAsync(payload);

            if(this.isAuthenticated){
                //console.log("success");
                if(this.site.hasAccountSection) {
                    this.$router.push('/my-account')
                } else {
                    this.$router.push('/home')
                }                
            } else {
                //console.log("errrrrrrrorrrr")
                this.signInError = true;
            }
        },
        async test() {
            await this.testAsync();
        },
        getImgUrl() {
            return require('../assets/svg/' + this.site.brand + '-logo.svg');
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            site: "template/site",
            isAuthenticated: "account/isAuthenticated"
        })
    }
};
</script>

<style lang="scss" scoped>
.signin-error {
    color:red;
}
</style>