<template>
    <div class="accordion" id="accordionExample">
        <div class="card" v-for="(data, idx) in dataArray" :key="idx">
            <div class="card-header">
                <p class="mb-0">
                    <a data-toggle="collapse" 
                        :data-target="'#collapse' + idx" 
                        :aria-controls="'collapse' + idx" 
                        v-on:click="show(idx)" 
                        :id="'anchor' + idx"
                        class="collapse-anchor">{{data[titleProp]}}</a>
                </p>
            </div>

            <div :id="'collapse' + idx" data-parent="#accordionExample" class="collapsing" :style="{'height': height}">
                <div class="card-body" :ref="'body' + idx" :id="'body' + idx" v-html="data[bodyProp]"></div>
            </div>

        </div>
    </div>    
</template>

<script>
export default {
    props: {
        dataArray: Array,
        bodyProp: String,
        titleProp: String
    },
    data () {
        return {
            toggle: false,
            height: '0px'
        }
    },
    methods: {
        show(id) {
            var contentHeight = document.getElementById('body' + id).offsetHeight;
            var eles = document.getElementsByClassName('collapsing')
            //close all cards
            for (let i of eles) {
                if(i.style.height != '0px') {
                    i.style.height = '0px';
                }
            }            
            //set all but current element expanded to false
            var anchors = document.getElementsByClassName('collapse-anchor');
            for(let i of anchors) {
                if(i.getAttribute('aria-expanded') && i.id != 'anchor' + id) {
                    i.setAttribute('aria-expanded', "");
                }
            }
            
            // hide or show panel clicked
            var anchor = document.getElementById('anchor' + id);
            var body = document.getElementById('collapse' + id);
            var expanded = anchor.getAttribute('aria-expanded');
            if(expanded) {
                anchor.setAttribute('aria-expanded', "");
                body.style.height = '0px';
            }
            else {
                anchor.setAttribute('aria-expanded', true);
                body.style.height = contentHeight + 'px';
            }            
        }        
    }    
}
</script>