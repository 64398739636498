<template>
  <div>
    <heroInner />

    <div class="container">
      <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

      <nav class="breadcrumbs">
        <ul>
          <li><router-link to="/home">Home</router-link></li>
          <li><a href="#" class="active">Create account</a></li>
        </ul>
      </nav>

      <div class="row">
        <div class="col-md-8 col-sm-12">
          <div class="inner">
            <h2>Create account</h2>
            <p class="subheading">
              Create an account with us today to save you time and effort in the
              future, this way you don't have to keep entering your details.
            </p>
            <form id="create-account">
              <!-- <fieldset class="common">
                <label for="first-name">First name</label>
                <input type="text" name="first-name" id="first-name" v-model="firstName" required />
              </fieldset> -->

              <fieldset class="common">
                <label for="last-name">Reference</label>
                <input type="text" name="reference" id="reference" v-model="reference" />
              </fieldset>

              <fieldset class="common">
                <label for="email-address">Email address</label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  size="30"
                  v-model="email"
                  required
                />
              </fieldset>

              <fieldset class="common">
                <label for="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  size="30"
                  v-model="password"
                  required
                />
              </fieldset>

              <fieldset class="common">
                <label for="password-confirm">Confirm password</label>
                <input
                  type="password"
                  name="password-confirm"
                  id="password-confirm"
                  size="30"
                  v-model="passwordConfirm"
                  required
                />
              </fieldset>

              <a @click="createAccount" class="button-primary button-large">Create account</a>
            </form>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <facts :driverAgeMin="site.driverAgeMin" :driverAgeMax="site.driverAgeMax" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import facts from "../components/facts";
export default {
  name: 'create-account',
  data() {
    return {
      title: "Create account",
      pageDescription: "Create an account with us today to save you time and effort in the future, this way you don't have to keep entering your details. Save money with deals.",

      reference: '',
      email: '',
      password: '',
      passwordConfirm: ''
    };
  },
  methods: {
    createAccount() {
      const { email, password, passwordConfirm } = this;
      if(password === passwordConfirm){
        var payload = {
          email: email,
          password: password
        }
        this.$store.dispatch('account/createAccount', payload);
      }
      else {
        var confirmError = "Password must match" //####TODO
      }      
    }
  },
  metaInfo() {
    return { 
        title: this.title,
        meta: [
          { name: 'description', content: this.pageDescription },
          { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
        ]
    }
  },
  components: {
    heroInner,
    facts
  },
  computed: {
    ...mapGetters({
      site: "template/site"
    })
  }
};
</script>
