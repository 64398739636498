<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
					<li><router-link to="/get-a-quote">Get a quote</router-link></li>
					<li><router-link to="/your-quote">Your quote</router-link></li>
					<li><router-link to="/important-information">Important information</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner font-size-form-match">
                        <div class="page-title">
                            <div class="white-bg-box">
                                <h2 class="form-title">Conditions</h2>
                            </div>
                            <div class="dashed-hr"></div>
                        </div>
                        <div class="intro-sect">
                            <p>
                                Please make sure that you have read our <a :href="site.cdnUrl + '/' + site.policyWording" download class="link">policy wording</a> 
                                document especially the important information and general exclusions sections. You can download these documents from our 
                                <router-link to="/faq" class="link">Faq</router-link> at any time.
                            </p>
                            <p>The following statements must be true, for us to provide this quotation for you.</p>
                        </div>
                        <h4>You and all drivers named on the policy:</h4>
                        <ul class="checked">
                            <li>This policy must have been purchased before the commencement of your rental period for it to be valid.</li>
                            <li>You are a resident of the UK.</li>
                            <li>You must hold a valid full UK or internationally recognised driving licence.</li>
                            <li>You have read and agreed to our privacy policy.</li>
                        </ul>
                        <h4>Additionally, any driven vehicle:</h4>
                        <ul class="checked">
                            <li>Must not be a motor home, campervan, commercial vehicle, minibus, motorcycle or moped.</li>
                            <li>Must not be more than 10 years old.</li>
                            <li>Must not be driven off a public highway.</li>
                            <li>Must not have more than 9 seats.</li>
                            <li>Must not have a retail purchase price more than £70,000.</li>
                        </ul>
                        <h4>The following important conditions apply:</h4>
                        <ul class="checked">
                            <li>This policy provides cover for one insured vehicle only.</li>
                        </ul>
                        <form>
                            <fieldset class="common selectable-checkboxes">
                                <div class="blocked-inputs i-can-confirm">
                                    <label for="read-policy-wording" class="selectable" :class="confirmPolicyWording ? 'selected' : ''">
                                        <input type="checkbox" id="read-policy-wording"  value="false" v-model="confirmPolicyWording" v-taurus-validator="'equal_to:true'" fieldlabel="Confirm wording">
                                        I confirm that I have read and understood the policy wording
                                    </label>
                                    <div name="read-policy-wording" invalidfeedback="Please confirm you have read and understood the policy wording"></div>

                                    <label for="all-statements-true" class="selectable" :class="statementTrue ? 'selected' : ''">
                                        <input type="checkbox" id="all-statements-true"  value="false" v-model="statementTrue" v-taurus-validator="'equal_to:true'" fieldlabel="Confirm statements">
                                        I confirm that the above statements are true
                                    </label>
                                    <div name="all-statements-true" invalidfeedback="Please confirm that the above statements are true"></div>
                                </div>
                            </fieldset>
                        </form>
                        <a class="button-primary button-large continue" v-on:click="show">Continue</a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                </div>
            </div>
            <modal id="dialog-great" name="dialog-great" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="false" height="auto" :adaptive="true">
                <div class="body">
                    <p class="heading">Great!</p>
                    <p class="message">Now that’s out of the way, we just need your details and the drivers then we’re good to go.</p>
                    <router-link to="/your-details" class="button-main-cta">Continue</router-link>
                    <img :src="brandSvg" :alt="site.brand + 'graphic logo'" class="icon-soi-graphic" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
export default {
    name: 'important-information',
    mounted() {
        this.$store.dispatch('updateFormProgress', this.$options.name);
    },        
    data() {
        return {
            title: "Important Information",
            pageDescription: "In order for us to proceed with your quote, you must read the important information below and confirm that the below statements are true.",
            confirmPolicyWording: false,
            statementTrue: false,
            confirmStatementError: null,
            confirmWordingError: null
        };
    },
    components: {
        heroInner,
        usefulLinks
    },
    methods: {
        show () {
            // should be checking for existing account 
            if(this.checkForm()) {
                this.$modal.show('dialog-great');
            }            
        }
    },
    computed: {
        ...mapGetters({
            site: "template/site"
        }),
        brandSvg() {
            return require('../assets/svg/icon-' + this.site.brand + '-graphic.svg');
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
};
</script>