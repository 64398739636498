<template>
<div>
    <heroInner :title="title"/>
    <div class="container">
        <router-link class="back-link" to="/home"><i class="fas fa-caret-left"></i>Back</router-link>
        

        <nav class="breadcrumbs">
            <ul>
                <li><router-link to="/home">Home</router-link></li>
                <li><a href="#" class="active">Accessibility</a></li>
            </ul>
        </nav>

        <div class="row">
            <div class="col-md-8 col-sm-12">
                <div class="inner">
                    <h2>Accessibility</h2>
                    <p class="subheading">The web is for everyone regardless of technology or ability and we discuss web accessibility standards and exceptions.</p>

                    <div class="main-content">
                        <div class="internal-anchor-links">
                            <h3 id="statement">Statement</h3>
                            <p>We are committed to providing a website that is accessible to the widest possible audience, regardless of technology or ability. We are actively working to increase the accessibility and usability of our website and in doing so aim to adhere to many of the available standards and guidelines.</p>

                            <h3 id="standards">Standards</h3>
                            <p>This website endeavours to conform to level A of the <a class="link" href="https://www.w3.org/" rel="noopener" target="_BLANK">World Wide Web Consortium (W3C)</a> <a class="link" href="https://www.w3.org/TR/WCAG20/" target="_blank" rel="noopener">Web Content Accessibility Guidelines (WCAG) 2.0</a>. These guidelines explain how to make web content more accessible for people with disabilities. Conformance with these guidelines will help make the web more user friendly for all people.</p>
                            <p>The site displays correctly in current web browsers. If you view our site on an older browser, you may find pages don't display as intended if the browser isn't standards-compliant. I recommend you upgrade your browser to the latest version of one of the following, to get the most from our site.</p>
                            <ul class="disc">
                                <li>Google Chrome - <a href="https://www.google.co.uk/chrome/" rel="noopener" target="_BLANK" class="link">https://www.google.co.uk/chrome/</a></li>
                                <li>Mozilla Firefox - <a href="https://www.mozilla.com" rel="noopener" target="_BLANK" class="link">https://www.mozilla.com</a></li>
                                <li>Apple Safari - <a href="https://support.apple.com/en_GB/downloads/#safari" rel="noopener" target="_BLANK" class="link">https://support.apple.com/en_GB/downloads/#safari</a></li>
                                <li>Microsoft Edge - <a href="https://www.microsoft.com/en-gb/windows/microsoft-edge" rel="noopener" target="_BLANK" class="link">https://www.microsoft.com/en-gb/windows/microsoft-edge</a></li>
                            </ul>

                            <h3 id="exceptions">Exceptions</h3>
                            <p>Whilst we strive to adhere to the accepted guidelines and standards for accessibility and usability, it is not always possible to do so in all areas of this website. We are continually seeking out solutions that will bring all areas of the site up to the same level of overall accessibility. In the meantime should you experience any difficulty in accessing our website, please don't hesitate to <router-link to="/contact-us">contact us.</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <aside class="sidebar" id="sidebar">
                    <h4>Skip to section</h4>
                    <ul>
                        <li><a href="#statement">Statement</a></li>
                        <li><a href="#standards">Standards</a></li>
                        <li><a href="#exceptions">Exceptions</a></li>
                    </ul>
                </aside>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import heroInner from '../components/heroInner';
export default {
    name: 'accessibility',
    metaInfo() {        
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    data () {
        return {
            title: 'Accessibility',
            pageDescription: 'The web is for everyone regardless of technology or ability and we discuss web accessibility standards and exceptions.'
        }
    },
    components: {
        heroInner
    },
    computed: {
        ...mapGetters({
            site: 'template/site'
        })
    }
}
</script>