<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/get-a-quote">Get a quote</router-link></li>
                    <li><router-link to="/your-quote">Your quote</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <div class="page-title">
                            <div class="white-bg-box">
                                <h2 class="form-title">Your price</h2>
                            </div>
                            <div class="dashed-hr"></div>
                        </div>
                        <form>
                            <div class="radio-toolbar">
                                <input type="radio" id="price-single-trip" name="policyType" :value="1" v-model="policyType" v-on:click="showPrice(1)" :disabled="originQuote.TripTypeId == 2">
                                <label for="price-single-trip" tabindex="0">Single Trip</label>
                                <input type="radio" id="price-annual-multi-trip" name="policyType" :value="2" v-model="policyType" v-on:click="showPrice(2)" :disabled="originQuote.GroupTypeId == 2">
                                <label for="price-annual-multi-trip" tabindex="0">Annual multi-trip</label>
                            </div>
                        </form>

                        <!-- START Forms Wrapper -->
                        <div class="forms-wrapper">

                            <!-- START Loading Transition Screen -->                            
                            <loadingTransition :loading="loading" :height="loadingHeight"/>
                            <!-- END Loading Transition Screen -->

                            <div class="price">

                                <!-- START Single Trip price -->
                                <div id="price-single-trip-content" ref="priceSingle">

                                    <div class="price-box">
                                        <p>{{quotation.cost | formatCurrency}}</p>
                                    </div>

                                    <div class="page-title section-title">
                                        <div class="white-bg-box">
                                            <h2 class="form-title">What you get</h2>
                                        </div>
                                        <div class="dashed-hr"></div>
                                    </div>

                                    <table class="what-you-get">
                                        <tr>
                                            <th>Max Duration (Per Trip)</th>
                                            <td v-if="quotation.cover.tripTypeId == 1" v-html="coverDuration"></td>
                                            <td v-else>{{quotation.maxDuration}} Days</td>
                                        </tr>
                                        <tr>
                                            <th>Max Number of Trips</th>
                                            <td v-if="quotation.cover.tripTypeId == 1">{{quotation.maxNumTrips}}</td>
                                            <td v-else>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <th>Excess Reimbursement</th>
                                            <td>£{{quotation.excessReimbursement}}</td>
                                        </tr>
                                        <tr>
                                            <th>Misfuelling Cover</th>
                                            <td>£{{quotation.misfuelCover}}</td>
                                        </tr>
                                        <tr>
                                            <th>Personal Possessions</th>
                                            <td>£{{quotation.personalPossessions}}</td>
                                        </tr>
                                        <tr>
                                            <th class="indented">a. Single Item, Pair Or Set</th>
                                            <td>£{{quotation.singlePairSetItem}}</td>
                                        </tr>
                                        <tr>
                                            <th class="indented">b. Tobacco, Alcohol, Fragrances</th>
                                            <td>£{{quotation.tobaccoAlcoholFragrances}}</td>
                                        </tr>
                                        <tr>
                                            <th>Rental Car Key Cover</th>
                                            <td v-if="quotation.cover.tripTypeId == 1" v-html="coverDuration"></td>
                                            <td v-else>{{quotation.maxDuration}} Days</td>
                                        </tr>
                                        <tr>
                                            <th class="indented">a. Lost, Stolen Or Damaged Keys</th>
                                            <td>£{{quotation.theftLossKeys}}</td>
                                        </tr>
                                        <tr>
                                            <th class="indented">b. Lock Out</th>
                                            <td>£{{quotation.lockOut}}</td>
                                        </tr>
                                        <tr>
                                            <th>Breakdown recovery</th>
                                            <td>£{{quotation.breakdownRecovery}}</td>
                                        </tr>
                                    </table>

                                    <div class="page-title section-title">
                                        <div class="white-bg-box">
                                            <h2 class="form-title">Your cover</h2>
                                        </div>
                                        <div class="dashed-hr"></div>
                                    </div>

                                    <table class="your-cover">
                                        <tr>
                                            <th>Destination</th>
                                            <td v-html="formatDestination(quotation.cover.coverRegionId)"></td>
                                        </tr>
                                        <tr>
                                            <th>Start Date</th>
                                            <td>{{quotation.cover.coverStartDate | dateTimeFormat }}</td>
                                        </tr>
                                        <tr>
                                            <th>End Date</th>
                                            <td v-if="quotation.cover.tripTypeId == 1">{{ quotation.cover.coverEndDate | dateTimeFormat }}</td>
                                            <td v-else v-html="addYearToDate(quotation.cover.coverStartDate, 1)"></td>
                                        </tr>
                                        <tr>
                                            <th>Drivers</th>
                                            <td v-html="driverNumber(mainDrivers, namedDrivers)"></td>
                                        </tr>
                                    </table>

                                    <div class="price-box">
                                        <p>{{ quotation.cost | formatCurrency }}</p>
                                    </div>
                                    <div v-html="quoteError" class="taurus-invalid"></div>
                                    <div class="button-group">
                                        <a class="button-primary button-large purchase" v-on:click="show">Buy now</a>
                                        <router-link class="button-secondary button-large" to="/get-a-quote">Edit quote</router-link>
                                    </div>
                                </div>
                                <!-- END Single Trip price -->
                            </div><!--  END .price -->

                        </div>
                        <!-- END Forms Wrapper -->

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                    <facts />
                </div>
            </div>
            <modal id="trip-dialog" name="trip-dialog" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="false" height="auto" :adaptive="true">
                <div class="body">
                    <p class="heading">But first...</p>
                    <p class="message">We need you to read some important information so we know that this policy is right for you.</p>
                    <router-link to="/important-information" class="button-main-cta">Continue</router-link>
                    <img :src="brandSvg" :alt="site.brand + ' graphic logo'" class="icon-soi-graphic" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Facts from '../components/facts.vue';
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
import loadingTransition from "../components/loadingTransition"
import CommonMixin from "../mixins/common";
export default {
    name: 'your-quote',    
    data() {
        return {
            title: "Your quote",
            pageDescription: "Here is your quote that we've generate for you based on the information that you have provided to us. Includes your price, what you get and cover details.",      
            policyType: 1,
            loading: false,
            loadingHeight: 500,
            quoteError: null
        };
    },
    components: {        
        heroInner,
        usefulLinks,
        Facts,
        loadingTransition,
    },
    mixins: [CommonMixin],
    mounted() {
        this.policyType = this.originQuote.TripTypeId;
        this.$store.dispatch('updateFormProgress', this.$options.name) 
    },
    methods: {    
        async showPrice(id) {     
            this.quoteError = null;      
            this.loadingHeight = this.$refs.priceSingle.clientHeight;
            
            this.loading = true;

            var quote = Object.assign({}, this.originQuote);
            quote.TripTypeId = id;
            if(quote.TripTypeId == 2) {
                quote.CoverEndDate = this.addYearToDate(quote.CoverStartDate, 1, 'YYYY-MM-DD')
            } 
            // console.log(quote)
            var app = this;
            await this.$store.dispatch('quote/getQuote', quote).then(function() {
                app.loading = false;
            });        
        },
        show () {
            // check triptype if 1 max days == 94 days
            //if triptype == 2 --> max days == 365 
            this.quoteError = null;    
            if (this.quotation.cover.netPremium == 0) {
                this.quoteError = "We're unable to provide you with a quote with the options selected"
                return;
            } else {
                this.quoteError = null;  
                this.$modal.show('trip-dialog');
            }            
        }
    },
    computed: {
        ...mapGetters({
            site: "template/site",
            quotation: "quote/getQuotation",
            namedDrivers: 'customer/getNamedDrivers',
            mainDrivers: 'customer/getMainDrivers',
            originQuote: 'quote/getOriginQuote'
        }),
        coverDuration() {
            var html = this.tripDuration(this.quotation.cover.coverStartDate, this.quotation.cover.coverEndDate, 'YYYY-MM-DD hh:mm:ss') + ' Days';
            return html
        },
        brandSvg() {
            return require('../assets/svg/icon-' + this.site.brand + '-graphic.svg');
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
};
</script>