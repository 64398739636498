<template>
    <div>
        <heroInner :title="title"/>
        <div class="container container-flush">
            <div class="my-row">
                <div class="my-account">
                    <aside class="my-account-sidebar">
                        <div class="top-section">
                            <div class="avatar-wrapper">
                                <img src="../../assets/img/default-profile-picture.png" class="avatar" alt="Customer Avatar">
                                <a href="#" class="change-avatar-btn" title="Change picture">
                                    <i class="fas fa-camera-alt"></i>
                                </a>
                            </div>
                            <h4 v-if="isCustomer">{{customerFullName}}</h4>
                            <p v-if="isCustomer">{{customerAddress.townCity}}, {{customerAddress.county}}</p>
                        </div>

                        <div class="sidebar-nav">
                            <div class="nav flex-column nav-pills" aria-orientation="vertical">
                                <a v-for="(tab, idx) in accountTabs" :key="idx"  
                                :class="['nav-link', { active: currentTab == tab.component}]" 
                                @click="currentTab = tab.component" 
                                :title="tab.title">
                                    <i :class="tab.icon"></i>{{tab.name}}
                                </a>							
                            </div>
                        </div>

                    </aside>
                    <main>

                        <!-- START Main Content Desktop -->
                        <div class="main-content-desktop">

                            <!-- START Tab Content -->
                            <div class="tab-content" id="v-pills-tabContent">
                                <transition name="fadeoutin" mode="out-in">
                                    <component v-bind:is="currentTabComp" v-bind="currentTabProps"></component>
                                </transition>
                                
                            </div>
                            <!-- END Tab Content -->

                        </div>
                        <!-- END Main Content Desktop -->

                    </main>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import HeroInner from '../../components/heroInner';
import WelcomeTab from '../../components/my-account-tabs/welcome.vue';
import AccountTab from '../../components/my-account-tabs/account/account.vue';
import PoliciesTab from '../../components/my-account-tabs/policies/policies.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'my-account',
    data: () => ({
        title: 'My Account',        
        policyObj: [
            {
                id: 1234,
                destination: 'Worldwide (excluding USA)',
                number: 'HX-678932',
                purchaseDate: '20 February 2021',
                startDate: '10 March 2021',
                endDate: '10 March 2022',
                totalCost: '28.99',
                policyType: 'Annual Multi Trip',
                mainDrivers: [
                    { name: 'testy mctestington' }
                ],
                namedDrivers: [],
                active: true 
            },
            {
                id: 4567,
                destination: 'Europe',
                number: 'HX-678932',
                purchaseDate: '20 February 2021',
                startDate: '4 Jun 2021',
                endDate: '11 Jun 2021',
                totalCost: '12.99',
                policyType: 'Single Trip',
                mainDrivers: [
                    { name: 'testy mctestington' }
                ],
                namedDrivers: [
                    { name: 'test mctester' }
                ],
                active: true 
            },
            {
                id: 873232,
                destination: 'Europe',
                number: 'HX-678932',
                purchaseDate: '01 January 2021',
                startDate: '4 Dec 2020',
                endDate: '11 Dec 2020',
                totalCost: '12.99',
                policyType: 'Single Trip',
                mainDrivers: [
                    { name: 'testy mctestington' }
                ],
                namedDrivers: [
                    { name: 'test mctester' }
                ],
                active: false
            }
        ],
        accountTabs: [
            { name: 'Welcome', title: 'Welcome', icon: 'fas fa-user', component: 'welcome-tab' },
            { name: 'Account', title: 'View Account', icon: 'fas fa-user', component: 'account-tab' },
            { name: 'Policies', title: 'View Policies', icon: 'fas fa-file-alt', component: 'policies-tab' }
        ],
        currentTab: 'welcome-tab'
    }),
    components: {
        WelcomeTab,
        AccountTab,
        PoliciesTab,
        HeroInner
    },
    computed: {
        currentTabComp: function() {
            return this.currentTab;
        },
        currentTabProps: function() {
            if(this.currentTab == 'welcome-tab') {
                return { userName: this.customerFullName };
            }
            if(this.currentTab == 'account-tab') {
                return { customer: this.customerObj };
            }
            if(this.currentTab == 'policies-tab') {
                return { policies: this.policyObj };
            }
        },
        customerObj() {
            return {
                customer: this.customer,
                address: this.customerAddress,
                account: {}
            }
        },
        ...mapGetters({            
            userName: 'account/getUsername',
            isCustomer: 'customer/isCustomer',
            customerFullName: 'customer/getCustomerFullName',
            customerAddress: 'customer/getAddress',
            customer: 'customer/getCustomer'
        })
    }    
}
</script>

