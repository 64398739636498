import Vue from "vue";
import Vuex from "vuex";

import router from '../router/index.js';

import templateStore from './modules/templateStore';
import quoteStore from './modules/quoteStore';
import customerStore from './modules/customerStore';
import accountStore from './modules/accountStore';
import quoteService from "../api/services/quoteService.js";



Vue.use(Vuex);
const rootInitialState = () => {
  return {
    policy: {},
    nonce: null,
    billingAddress: null,
    purchaseResponse: null,
    quoteLoading: false,
    formProgress: null,
    policyPdf: null,
    paymentError: null
  };
};

export default new Vuex.Store({
  state: rootInitialState(),
  getters: {
    getPolicy: state => {
      return state.policy;
    },
    getQuoteLoading: state => state.quoteLoading,
    getFormProgress: state => state.formProgress,
    getPdf: state => state.policyPdf,
    getPaymentError: state => state.paymentError
  },
  mutations: {
    COMMIT_POLICY(state, p) {
      state.policy = p;
    },
    COMMIT_NONCE(state, n) {
      state.nonce = n;
    },
    RESET_NONCE(state) {
      state.nonce = null;
    },
    COMMIT_BILLING_ADDRESS(state, a) {
      state.billingAddress = a;
    },
    RESET_BILLING_ADDRESS(state) {
      state.billingAddress = null;
    },
    COMMIT_PURCHASE_RESPONSE(state, payload) {
      state.purchaseResponse = payload
    },
    SET_QUOTE_LOADING(state, loading) {
      state.quoteLoading = loading;
    },
    COMMIT_PROGRESS(state, p) {
      state.formProgress = p;
    },
    COMMIT_PDF(state, pdf) {
      state.policyPdf = pdf
    },
    COMMIT_PAYMENT_ERROR(state, err) {
      state.paymentError = err
    },
    RESET_PAYMENT_ERROR(state) {
      state.paymentError = null;
    },
    RESET_STATE(state) {
      Object.assign(state, rootInitialState())
    }
  },
  actions: {
    resetRootState({ commit }) {
      commit('RESET_STATE');
    },
    async submitPolicy({ state, commit, dispatch, rootState }) {
      commit('RESET_PAYMENT_ERROR');
      //collect all objects here and construct final obj to send back to api
      var data = { 
        paymentMethodNonce: state.nonce.nonce,
        deviceData: state.nonce.deviceData,
        title: rootState.customer.customer.title,
        firstName: rootState.customer.customer.firstName,
        lastName: rootState.customer.customer.lastName,
        telephoneNumber: rootState.customer.customer.telephoneNumber,
        emailAddress: rootState.customer.customer.emailAddress,        
        addressLine1: rootState.customer.address.addressLine1,
        addressLine2: rootState.customer.address.addressLine2,
        townCity: rootState.customer.address.townCity,
        county: rootState.customer.address.county,
        postCode: rootState.customer.address.postCode,
        countryId: 1,
        quoteUid: state.policy.uid
      }  
      
      //console.log('policy-presend', data);

      const response = await quoteService.purchaseQuote(data).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);              
        } 
      });

      if(response && response.status === 200) {
        // console.log('response 200: ', response.data)
        commit('COMMIT_PURCHASE_RESPONSE', response.data);
        if(response.data.isSuccess) {          
          await dispatch('getPolicy', state.policy.uid);
          router.push('confirmation');          
        } else {
          commit('RESET_NONCE');
          commit('COMMIT_PAYMENT_ERROR', response.data.processorResponseText);          
        }        
      }
    },
    async createQuote({ commit, rootState }) {
      commit('SET_QUOTE_LOADING', true);
      //make obj
      var quote = { 
        brandId: rootState.quote.quotation.cover.brandId,
        saleCountryId: rootState.template.site.saleCountryId,
        channelId: rootState.template.site.channelId,
        leadTypeId: rootState.template.site.leadTypeId,
        rateSetId: 0, //??
        productId: rootState.template.site.productId,
        currencyId: rootState.template.site.currencyId,
        tripTypeId: rootState.quote.quotation.cover.tripTypeId,
        coverRegionId: rootState.quote.quotation.cover.coverRegionId,
        groupTypeId: rootState.quote.quotation.cover.groupTypeId,
        coverStartDate: rootState.quote.quotation.cover.coverStartDate,
        coverEndDate: rootState.quote.quotation.cover.coverEndDate,
        numberMainDrivers: rootState.customer.mainDrivers.length,
        numberNamedDrivers: rootState.customer.namedDrivers.length,
        customerUid: rootState.customer.customer.uid,
        drivers: rootState.customer.mainDrivers.concat(rootState.customer.namedDrivers)
      }
      //console.log('pre-quote-create: ', quote);
      const response = await quoteService.createQuote(quote).catch(function (error) {
        commit('SET_QUOTE_LOADING', false);
        if (error.response) {
          console.log(error.response.data);                          
        } 
      });

      if(response && response.status === 200) {
          commit('COMMIT_POLICY', response.data);          
          commit('SET_QUOTE_LOADING', false);  
          router.push('your-payment');
      }
    },
    submitPayment({ commit }, payload) {
      commit('COMMIT_NONCE', payload.nonce);
      commit('COMMIT_BILLING_ADDRESS', payload.billingAddress);
    },
    updateFormProgress({ commit }, payload) {
      commit('COMMIT_PROGRESS', payload);
    },
    async getPolicy({ commit }, payload) {
      const response = await quoteService.getQuoteById(payload).catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);              
        } 
      });

      if(response && response.status === 200) {
        // console.log(response)
        commit('COMMIT_POLICY', response.data);
      }
    },
    async getPolicyPdf({ commit }, payload) {
      const response = await quoteService.getPolicyPdf(payload).catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);              
        } 
      });

      if(response && response.status === 200) {
        commit('COMMIT_PDF', response.data)
      }
    }
  },
  modules: {
    template: templateStore,
    quote: quoteStore,
    customer: customerStore,
    account: accountStore
  }
});
