<template>
    <!-- START responsive-menu -->
    <div class="responsive-menu-overlay">
        <div class="responsive-menu-contents">
            <div class="menu-group">
                <p class="menu-title">menu</p>
                <ul>
                    <li v-for="(link, idx) in site.navLinks" :key="idx">
                        <div v-if="link.dropdown">
                            <a class="parent mob-inline" href="#">{{ link.text }} <i class="fal fa-angle-down"></i></a>
                                <a href="#" class="parent-mob">
                                    <img src="@/assets/svg/icon-plus.svg" alt="A simple line drawn plus icon." />
                                </a>
                                <ul class="nav-dropdown">
                                    <li v-for="(subLink, idx) in link.dropdown" :key="idx">
                                        <router-link :to="subLink.url">{{ subLink.text }}</router-link>
                                    </li>                                    
                                </ul>
                        </div>
                        <router-link v-else :to="link.url">{{ link.text }}</router-link>
                    </li>                    
                </ul>
            </div>
            <div class="copyright">&copy; {{ site.current_year }} {{ site.copyright }}</div>
            <div class="bottom-gradient"></div>
        </div>
    </div>
    <!-- END responsive-menu -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            site: 'template/site'
        })
    }
}
</script>