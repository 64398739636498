<template>
    <div>
        <div class="tab-pane fade show active" id="sidebar-policies" role="tabpanel" aria-labelledby="sidebar-policies-tab">
            <div class="policy-wrapper">

                <div class="toolbar">
                    <a @click="$emit('showPolicies')" class="button-primary button-back"><i class="fas fa-chevron-left"></i> Back</a>
                    <!-- <a @click="$emit('showPolicies')" href="" class="link">View all active policies</a> -->
                </div>
                <div class="policy">
                    <div class="header">
                        <div class="title">
                            <p class="policy-no">{{policy.number}}</p>
                            <h1>{{policy.policyType}}, {{policy.destination}}, 4 - 11 Jun 2021</h1>
                        </div>
                        <div class="cost">
                            <h1>£{{policy.totalCost}}</h1>
                            <p>total cost</p>
                        </div>
                    </div>
                    <div class="hr"></div>
                    <div class="body">
                        <div class="overview">
                            <table class="policy-overview">
                                <tr>
                                    <th>Policy Type</th>
                                    <td>{{policy.policyType}}</td>
                                </tr>
                                <tr>
                                    <th>Destination</th>
                                    <td>{{policy.destination}}</td>
                                </tr>
                                <tr>
                                    <th>Start Date</th>
                                    <td>{{policy.startDate}}</td>
                                </tr>
                                <tr>
                                    <th>End Date</th>
                                    <td>{{policy.endDate}}</td>
                                </tr>
                                <tr>
                                    <th>Drivers</th>
                                    <td>x1 Main, x1 Named</td>
                                </tr>
                                <tr>
                                    <th>Main Driver</th>
                                    <td>Mr Mark Emblin</td>
                                </tr>
                                <tr>
                                    <th>Named Driver</th>
                                    <td>Mr Mark Izzard</td>
                                </tr>
                                <tr>
                                    <th>Customer No.</th>
                                    <td>1502</td>
                                </tr>
                                <tr>
                                    <th>Order No.</th>
                                    <td>18095</td>
                                </tr>
                                <tr>
                                    <th>Method</th>
                                    <td>One-off payment</td>
                                </tr>
                                <tr>
                                    <th>Payment</th>
                                    <td>
                                        <img src="../../../../assets/svg/icon-visa.svg" alt="VISA" class="visa">
                                        <span>Card ending in 1234</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="sidebar">
                            <a class="button-primary button-full button-print">Print Policy</a>
                            <a href="/policy-certificate/" class="button-primary button-full">View Certificate</a>
                            <a href="#" class="button-danger button-full cancel-policy">Cancel Policy</a>
                            <div class="policy-purchased">
                                <p>Policy Purchased</p>
                                <h4>{{policy.purchaseDate}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="hr"></div>
                    <div class="useful-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><a href="#" class="link">Insurance product information document</a></li>
                            <li><a href="#" class="link">Initial disclosure document</a></li>
                            <li><a href="#" class="link">Policy wording document</a></li>
                            <li><a href="#" class="link">Privacy policy</a></li>
                        </ul>
                    </div>
                </div>
                <div class="toolbar">
                    <a @click="$emit('showPolicies')" class="button-primary button-back"><i class="fas fa-chevron-left"></i> Back</a>
                    <!-- <a @click="$emit('showPolicies')" class="link">View all active policies</a> -->
                </div>
            </div>
        </div>

        <div id="dialog-cancel-policy" class="dialog zoom-anim-dialog mfp-hide">
            <header>
                <p>Cancel policy confirmation</p>
            </header>
            <div class="body">
                <p class="heading">Are you sure you want to cancel your policy?</p>
                <p class="message">This action cannot be undone, please confirm if you wish to proceed.</p>
            </div>
            <footer>
                <a class="button-secondary close-dialog">Keep my policy</a>
                <a href="#" class="button-primary">Cancel my policy</a>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'single-policy',
    props: {
        policy: Object
    }
}
</script>