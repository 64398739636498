<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
					<li><router-link to="/get-a-quote">Get a quote</router-link></li>
					<li><router-link to="/your-quote">Your quote</router-link></li>
					<li><router-link to="/important-information">Important information</router-link></li>
					<li><router-link to="/your-details">Your details</router-link></li>
                    <li v-if="namedDrivers.length"><router-link to="/named-driver/0">Named drivers</router-link></li>
                    <li v-else><router-link to="/main-driver/0">Main drivers</router-link></li>
                    <li><router-link to="/your-order">Your order</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <div class="page-title">
                            <div class="white-bg-box">
                                <h2 class="form-title">Your policy</h2>
                            </div>
                            <div class="dashed-hr"></div>
                        </div>

                        <div class="price">

                            <!-- START Single Trip price -->
                            <div id="your-policy-summary">

                                <table class="your-cover">
                                    <tr>
                                        <th>Destination</th>
                                        <td v-html="formatDestination(quote.cover.coverRegionId)"></td>
                                    </tr>
                                    <tr>
                                        <th>Policy Type</th>
                                        <td v-html="formatPolicyType(quote.cover.tripTypeId)"></td>
                                    </tr>
                                    <tr>
                                        <th>Start Date</th>
                                        <td>{{ quote.cover.coverStartDate | shortDateFormat }}</td>
                                    </tr>
                                    <tr>
                                        <th>End Date</th>
                                        <td v-if="quote.cover.tripTypeId == 1">{{ quote.cover.coverEndDate | shortDateFormat }}</td>
                                        <td v-else v-html="addYearToDate(quote.cover.coverStartDate, 1)"></td>
                                    </tr>
                                    <tr>
                                        <th>Drivers</th>
                                        <td v-html="driverNumber(mainDrivers, namedDrivers)"></td>
                                    </tr>
                                    <tr v-if="mainDrivers && mainDrivers.length">
                                        <th>Main Driver</th>
                                        <td>
                                            <pre v-for="(d, idx) in mainDrivers" :key="idx">{{d.title}} {{d.firstName}} {{d.lastName}}</pre>
                                        </td>
                                    </tr>
                                    <tr v-if="namedDrivers && namedDrivers.length">
                                        <th>Named Driver</th>
                                        <td>
                                            <pre v-for="(d, idx) in namedDrivers" :key="idx">{{d.title}} {{d.firstName}} {{d.lastName}}</pre>
                                        </td>                                        
                                    </tr>
                                </table>

                                <div class="price-box">
                                    <p>{{quote.cost | formatCurrency}}</p>
                                </div>

                            </div>
                            <!-- END Single Trip price -->

                            <!-- <div class="page-title">
                                <div class="white-bg-box">
                                    <h2 class="form-title">Payment</h2>
                                </div>
                                <div class="dashed-hr"></div>
                            </div> -->
                            <form>
                                <!-- <payment v-on:payment-method-success="paymentOk"/> -->

                                <!-- <a class="button-primary button-large" v-on:click="submit">Continue</a> -->
                                <loadingButton buttonLabel="Continue" :isLoading="quoteLoading" @onClick="submit"/>
                                <router-link class="button-secondary button-large" to="/get-a-quote">Edit quote</router-link>                                      
                            </form> 
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                    <!-- <facts /> -->
                </div>
            </div>

            <!-- <modal name="dialog-warning" id="dialog-warning" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="false" height="auto" :adaptive="true">
                <div class="body">
                    <p class="heading">Processing payment...</p>
                    <p class="message">Please wait. Do not refresh the browser.</p>
                    <div class="fa-4x">
                        <i class="fad fa-spinner-third fa-spin"></i>
                    </div>
                    <img :src="'../assets/svg/icon-' + site.brand + '-graphic.svg'" alt="SOI graphic logo" class="icon-soi-graphic" />
                </div>
            </modal> -->

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
import facts from '../components/facts.vue';
import loadingButton from '../components/loadingButton.vue';
import CommonMixin from "../mixins/common";
// import payment from '../components/payment.vue';

export default {
    name: 'your-order',
    data() {
        return {
            name: 'your-order',
            title: "Your order",
            pageDescription: 'Below is a summary of your order, including your policy start and end dates along with a form for your payment details for processing your order.',
            paymentMethodOk: false,
        };
    },
    components: {
        heroInner,
        usefulLinks,
        facts,
        loadingButton
        // payment
    },
    mixins: [CommonMixin],
    mounted() {
        this.$store.dispatch('updateFormProgress', this.$options.name) 
    },
    methods: {
        paymentOk(payload) {
            this.paymentMethodOk = payload;
        },
        submit() {
            // this.$modal.show('dialog-processing');
            this.$store.dispatch('createQuote');                    
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            site: "template/site",
            namedDrivers: 'customer/getNamedDrivers',
            mainDrivers: 'customer/getMainDrivers',
            quote: 'quote/getQuotation',
            policyType: 'quote/getPolicyType',
            quoteLoading: 'getQuoteLoading'
        })

    }
};
</script>