<template>
    <div class="" id="personal-information" role="tabpanel" aria-labelledby="personal-information-tab">
        <form>
            <fieldset class="common">
                <label for="first-name">First name</label>
                <input type="text" name="first-name" id="first-name" v-model="customer.firstName" />
            </fieldset>

            <fieldset class="common">
                <label for="last-name">Last name</label>
                <input type="text" name="last-name" id="last-name" v-model="customer.lastName" />
            </fieldset>

            <fieldset class="common">
                <p>Date or birth</p>
                <span class="ib">
                    <label for="dob-day">Day</label>
                    <input type="text" name="dob-day" id="dob-day" pattern="\d*" maxlength="2" size="2" required v-model="dobDay" />
                </span>
                <span class="ib">
                    <label for="dob-month">Month</label>
                    <input type="text" name="dob-month" id="dob-month" pattern="\d*" maxlength="2" size="2" required v-model="dobMonth" />
                </span>
                <span class="ib">
                    <label for="dob-year">Year</label>
                    <input type="text" name="dob-year" id="dob-year" pattern="\d*" maxlength="4" size="4" required v-model="dobYear" />
                </span>
            </fieldset>

            <div class="hr"></div>

            <a href="#" class="button-primary" v-if="isCustomer">Saves Changes</a>
            <a href="#" class="button-primary" v-else>Create Profile</a>
        </form>
    </div>
</template>
    
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'personal-information',
    props: {
        customer: Object
    },
    computed: {
        ...mapGetters({
            isCustomer: 'customer/isCustomer'
        }),
        dobDay() {
            return new Date(this.customer.dateOfBirth).getDate();
        },
        dobMonth() {
            return new Date(this.customer.dateOfBirth).getMonth() + 1;
        },
        dobYear() {
            return new Date(this.customer.dateOfBirth).getFullYear();
        }
    }
}
</script>