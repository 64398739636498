<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/password-reset">Password reset</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <h2>Reset your password</h2>
                        <p class="subheading">Please enter your email address below, so we can send you a reset password link via email.</p>
                        <form id="create-account">
                            <fieldset class="common">
                                <label for="email-address">Email address</label>
                                <input type="email" name="email-address" id="email-address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" size="30" v-model="email" required />
                            </fieldset>
                            <a @click="resetPassword" class="button-primary button-large">Reset my password</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import { ACC_PASSWORD_RESET } from "../store/actions/account/account.js";

export default {
  data() {
    return {
      title: "Password reset",
      pageDescription: 'Reset your password if you have forgotten or misplaced it. Then we will send you a link via email to reset your password and log in again.',
      email: ''
    };
  },
  components: {
    heroInner
  },
  methods: {
    resetPassword() {
      const { email } = this;
      this.$store.dispatch('account/' + ACC_PASSWORD_RESET, { email }).then(() => {
          this.$router.push('/password-reset-success')
      })
    }
  },
  metaInfo() {
    return { 
        title: this.title,
        meta: [
          { name: 'description', content: this.pageDescription },
          { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
        ]
    }
  },
  computed: {
    ...mapGetters({
      site: "template/site"
    })
  }
};
</script>