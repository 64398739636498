<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/policy-certificate">Policy certificate</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="inner">
                        <img class="render-of-pdf" alt="Render of the PDF" :src="require('../assets/downloads/policy-certificate/holiday-extras-policy-certificate.jpg')" />
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="policy-cert-blurb-wrapper">
                        <h3>View your policy certificate</h3>
                        <div class="policy-cert-button-wrapper">
                            <a class="button-primary" target="_blank" rel="noopener" :href="'data:application/pdf;base64,' + getPdf" download>Download PDF</a>
                            <a class="button-primary" target="_blank" rel="noopener" href="" @click="openPdf">View PDF in browser</a>
                            <!-- KEPT FOR DEVELOPER USE ONLY -->
                            <!-- <a class="button-primary" href="{{ site.url }}/policy-certificate/render/">View online version</a> -->
                        </div>
                        <div class="hr"></div>
                        <h3>What is an insurance policy certificate?</h3>
                        <p class="subheading">A certificate of insurance is a document used to provide information on specific insurance coverage. The certificate verifies the insurance and usually contains information on types and limits of coverage, insurance company, policy number, named insured, and the policies’ duration.</p>
                        <div class="hr"></div>
                        <h3>Why do I need one?</h3>
                        <p class="subheading">You may need to provide your insurance policy certificate  in case of a claim. Usually a certificate of insurance is requested when liability and large losses are a concern.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
export default {
  data() {
    return {
      title: "Policy certificate",
      pageDescription: 'Your policy certificate is the official document that confirms your insurance policy, this can be viewed online or downloaded as a PDF.'
    };
  },
  components: {
    heroInner
  },
  metaInfo() {
    return { 
        title: this.title,
        meta: [
          { name: 'description', content: this.pageDescription },
          { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
        ]
    }
  },
  mounted() {
    var uid = this.policy.uid;
    this.$store.dispatch('getPolicyPdf', uid);
  },
  methods: {
    openPdf() {
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.getPdf) + "'></iframe>"
      )
    }
  },
  computed: {
    ...mapGetters({
      site: "template/site",
      policy: "getPolicy",
      getPdf: 'getPdf'
    })
  }
};
</script>
