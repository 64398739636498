<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
					<li><router-link to="/get-a-quote">Get a quote</router-link></li>
					<li><router-link to="/your-quote">Your quote</router-link></li>
					<li><router-link to="/important-information">Important information</router-link></li>
					<li><router-link to="/your-details">Your details</router-link></li>
                    <li><router-link to="/your-order">Your order</router-link></li>
                    <li><router-link to="/your-payment">Your payment</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <div class="page-title">
                            <div class="white-bg-box">
                                <h2 class="form-title">Payment</h2>
                            </div>
                            <div class="dashed-hr"></div>
                        </div>
                        <form>
                            <payment v-on:payment-method-success="paymentOk" :key="paymentKey"/>
                            <fieldset class="common" v-if="paymentMethodOk">
                                <div class="flex">
                                    <div class="secure-payment">
                                        <i class="fad fa-lock-alt"></i>
                                        <p class="secure">Secure Payments</p>
                                    </div>
                                    <div class="payment-method">
                                        <img :src="require(`../assets/svg/visa-logo.svg`)" alt="Visa logo" />
                                    </div>
                                    <div class="payment-method">
                                        <img :src="require(`../assets/svg/mastercard-logo.svg`)" alt="Mastercard logo" />
                                    </div>
                                </div>
						    </fieldset>
                            <a class="button-primary button-large button-full processing-payment" v-on:click="submit" v-if="paymentMethodOk">Purchase Insurance</a>
                            <div name="payment-error" v-if="paymentError" class="taurus-invalid">{{paymentError}}</div>                            
                        </form>                
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                </div>
            </div>

            <modal name="dialog-processing" id="dialog-processing" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="false" height="auto" :adaptive="true">
                <div class="body">
                    <p class="heading">Processing payment...</p>
                    <p class="message">Please wait. Do not refresh the browser.</p>
                    <div class="fa-4x">
                        <i class="fad fa-spinner-third fa-spin"></i>
                    </div>
                    <img :src="'../assets/svg/icon-' + site.brand + '-graphic.svg'" alt="SOI graphic logo" class="icon-soi-graphic" />
                </div>
            </modal>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
import facts from '../components/facts.vue';
import CommonMixin from "../mixins/common";
import payment from '../components/payment.vue';

export default {
    name: 'your-payment',
    data() {
        return {
            name: 'your-payment',
            title: "Your order",
            pageDescription: 'Below is a form for your payment details for processing your order.',
            paymentMethodOk: false,
            paymentKey: 1
        };
    },
    components: {
        heroInner,
        usefulLinks,
        facts,
        payment
    },
    mixins: [CommonMixin],
    mounted() {
        this.$store.dispatch('updateFormProgress', this.$options.name); 
    },
    methods: {
        paymentOk(payload) {
            this.paymentMethodOk = payload;
        },
        async submit() {
            this.$modal.show('dialog-processing');

            await this.$store.dispatch('submitPolicy');      
            // console.log('test: ', this.paymentError)
            if(this.paymentError) {
                this.$modal.hide('dialog-processing');
                this.paymentKey += 1
            }
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            site: "template/site",
            namedDrivers: 'customer/getNamedDrivers',
            mainDrivers: 'customer/getMainDrivers',
            quote: 'quote/getQuotation',
            policyType: 'quote/getPolicyType',
            paymentError: 'getPaymentError'
        })
    }
};
</script>