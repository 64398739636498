<template>
    <div class="facts">
        <ul>
            <li>
                <i class="fad fa-check-circle"></i>
                <p class="heading">Trusted brand</p>
                <p>We’re rated excellent on Trustpilot with over 12,000 reviews and counting.</p>
            </li>
            <li>
                <i class="fad fa-check-circle"></i>
                <p class="heading">Instant policy delivery</p>
                <p>We despatch all approved policies instantly and electronically. Saving trees and time. </p>
            </li>
            <li>
                <i class="fad fa-check-circle"></i>
                <p class="heading">For all ages</p>
                <p>We go further than others and cover people from ages {{site.driverAgeMin}} to {{site.driverAgeMax}} years old.</p>
            </li>
            <li>
                <i class="fad fa-check-circle"></i>
                <p class="heading">Complete cover</p>
                <p>We cover vehicles up to the value of £70,000 with up to 9 seats as standard.</p>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'facts-component',
    props: {
        driverAgeMin: String | Number,
        driverAgeMax: String | Number
    },
    computed: {
        ...mapGetters({
            site: 'template/site'
        })
    }
}
</script>