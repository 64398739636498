import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

import Home from "../views/Home.vue";

// Quote journey
import GetQuote from "../views/GetQuote.vue";
import YourQuote from "../views/YourQuote.vue";
import YourDetails from "../views/YourDetails.vue";
import MainDriver from "../views/MainDriver.vue";
import NamedDriver from "../views/NamedDriver.vue";
import YourOrder from "../views/YourOrder.vue";
import Payment from "../views/Payment.vue";
import Confirmation from "../views/Confirmation.vue";

// Account
import LogIn from "../views/LogIn.vue";
import CreateAccount from "../views/CreateAccount.vue";
import PasswordReset from "../views/PasswordReset.vue";
import ChangePassword from "../views/ChangePassword.vue";
import PasswordResetSuccess from "../views/PasswordResetSuccess.vue";
import MyAccount from "../views/my-account/MyAccount.vue";

// Information
import ContactUs from "../views/ContactUs.vue";
// import UsefulInformation from "../views/UsefulInformation.vue";
import Faq from "../views/Faq.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Accessibility from "../views/Accessibility.vue";
import ImportantInformation from "../views/ImportantInformation.vue";
import Legal from "../views/Legal.vue";
import PolicyCertificate from "../views/PolicyCertificate.vue";
import Sitemap from "../views/Sitemap.vue";

// Error page
import ErrorView from "../views/ErrorView.vue";


Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  console.log(store.getters['account/isAuthenticated']);
  if (!store.getters['account/isAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  console.log(store.getters['account/isAuthenticated']);
  if (store.getters['account/isAuthenticated']) {
    next();
    return;
  }
  next('/log-in');
};

const ifHasFormProgress = (to, from, next) => {
  var hasProgress = store.getters['getFormProgress'];
  // console.log(hasProgress);
  if (hasProgress) {
    next();
  } else {
    next('/get-a-quote');
  }
}

const routes = [
  // general
  { path: '/', redirect: { name: 'Home' }},
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs
  },
  // {
  //   path: "/useful-information",
  //   name: "UsefulInformation",
  //   component: UsefulInformation
  // },
  {
    path: "/faq",
    name: "Faq",
    component: Faq
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/accessibility",
    name: "Accessibility",
    component: Accessibility
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: Sitemap
  },
  //quote journey 
  {
    path: "/get-a-quote",
    name: "GetAQuote",
    component: GetQuote
  },
  {
    path: "/your-quote",
    name: "YourQuote",
    component: YourQuote,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/important-information",
    name: "ImportantInformation",
    component: ImportantInformation,
    beforeEnter: ifHasFormProgress
  }, 
  {
    path: "/your-details",
    name: "YourDetails",
    component: YourDetails,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/main-driver/:id?",
    name: "MainDriver",
    component: MainDriver,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/named-driver/:id?",
    name: "NamedDriver",
    component: NamedDriver,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/your-order",
    name: "YourOrder",
    component: YourOrder,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/your-payment",
    name: "Payment",
    component: Payment,
    beforeEnter: ifHasFormProgress
  },
  {
    path: "/policy-certificate",
    name: "PolicyCertificate",
    component: PolicyCertificate
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword
  }  
];
const accRoutes = [
  //account 
  {
    path: "/log-in",
    name: "LogIn",
    component: LogIn,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    component: CreateAccount
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset
  },
  {
    path: "/password-reset-success",
    name: "PasswordResetSuccess",
    component: PasswordResetSuccess
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: MyAccount,
    beforeEnter: ifAuthenticated
  }
];
const errRoutes = [
  { path: '*', component: ErrorView }
]

function siteRoutes() {
  if(store.getters['template/getAccountSection']) {
    return routes.concat(accRoutes).concat(errRoutes);
  } else {
    return routes.concat(errRoutes);
  }
}; 

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",  
  scrollBehavior(to, from, savedPosition){
    if(savedPosition) {
      return savedPosition;
    } else if (to.hash) {      
      //console.log('if hash')
      return {
        selector: to.hash, 
        behavior: 'smooth' 
      }
    } else {
        return { x: 0, y: 0 };
    }
  },
  routes: siteRoutes()
});

export default router;

