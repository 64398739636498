<template>
<div>
    <heroInner :title="title"/>
    <div class="container">

        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

        <nav class="breadcrumbs">
            <ul>
                <li><router-link to="/home">Home</router-link></li>
                <li><a class="active">Frequently asked questions</a></li>
            </ul>
        </nav>

        <div class="row">
            <div class="col-md-8 col-sm-12">
                <div class="inner">              
                    <section class="introduction">
                        <h3>Introduction</h3>
                        <p>
                            This page is designed to provide you with answers to our most frequently asked questions about Car Hire Excess Insurance.
                            If you don’t find the answer to your question on this page please contact us at <a :href="site.onlineContact">{{site.onlineContact}}</a>
                        </p>
                    </section>
                    <section class="faq">
                        <h3>Frequently asked questions</h3>                        
                        <accordion :dataArray="getAccordianArray" bodyProp="body" titleProp="title" />
                    </section>
                   
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <usefulLinks />
                <facts :driverAgeMin="site.driverAgeMin" :driverAgeMax="site.driverAgeMax" />
            </div>
        </div>

    </div>
</div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import usefulLinks from '../components/usefulLinks';
import facts from '../components/facts';
import heroInner from '../components/heroInner';
import accordion from '../components/accordion'; 
import pictureElement from '../components/pictureElement.vue';

export default {
    components: {
        facts,
        usefulLinks,
        heroInner,
        accordion,
        pictureElement
    },
    computed: {
        ...mapGetters({
            site: 'template/site'
        }),
        getAccordianArray() {
            var knowledge = [
                {
                    title: 'What is car hire excess insurance?', 
                    body: `If you hire a car, the rental price may include insurance which covers you for accidents 
                    (Collision Damage Waiver/CDW) and theft (Loss Damage Waiver/LDW). If you have an accident or the 
                    rental vehicle is stolen you will be required to pay a portion of the costs to the car rental company, 
                    what we call “the excess”. Depending on the rental agreement and level of insurance included the portion 
                    you have to pay may be thousands of pounds. Also, damage to tyres, windscreen and windows and the undercarriage 
                    are frequently excluded from the car rental company's insurance and will be entirely the responsibility of the hirer. 
                    Our policy includes cover for damage to these parts of the rental vehicle.`
                },
                {
                    title: 'Why should I buy Car Hire Excess Insurance?', 
                    body: `Car Hire Excess Insurance gives you the comfort to know that if your rental vehicle is damaged or stolen whilst 
                    in your care you won't take a financial hit. Buying in advance can save you a significant amount compared to equivalent 
                    cover from the hire car company at their rental desk. `
                },
                {
                    title: 'What are Collision Damage Waiver (CDW) and Loss Damage Waiver (LDW)?', 
                    body: `In the Americas, rental companies offer insurance, usually inclusive in the rental price and some at an additional cost. 
                    However, depending on what state you are in, you may have limited cover for damage to the rental car and Third Party Liability. 
                    Making sure you have appropriate cover in place is important so you don't get left with a financial hit if you are unfortunate 
                    enough to have an accident whilst on holiday.`
                },
                {
                    title: 'Do I need to leave a deposit when I collect my rental car if I have Car Hire Excess Insurance?', 
                    body: `Yes. Unlike if you take out the rental car company’s insurance you will need to leave a deposit with the rental car company 
                    if not taking out their policy. This deposit is usually a holding deposit against your Credit Card and usually equal to the excess you are liable for.`
                },
                {
                    title: 'What countries am I not covered in?', 
                    body: `Unfortunately there are just a few countries this policy will not cover you in. Our policies do not offer cover the rental 
                    of vehicles or countries, regions or areas where the Foreign, Commonwealth & Development Office (FCDO) has warned against 
                    “all” or “all but essential” travel.`
                },                
                {
                    title: 'Are the other people who travel with me covered?', 
                    body: `Yes. Our policies cover the policyholder, who is the lead driver on the rental agreement, 
                    and all other named drivers providing they are UK residents, have a valid full UK or Internationally recognised driving licence and be 
                    aged between 18 and 85 years, inclusive. If you purchase our Annual cover you will be able to name two lead drivers.`
                },              
                {
                    title: 'Are all vehicles covered?', 
                    body: `While we will cover virtually all rental cars the following vehicles can not be covered under our policies; motor homes, caravans, 
                    quadbikes, commercial vehicles, minibuses, motorcycles or mopeds. We will also not cover vehicles more than 10 years of age, vehicles with 
                    more than 9 seats or vehicles that have a retail purchase price greater than £70,000.`
                }                               
            ];
            var linkedBody = `These FAQ’s contain information on some of our key exclusions regarding vehicles and countries however you must read our 
            <a href="${this.site.cdnUrl}/${this.site.policyWording}" target="_blank" download>policy wording</a> for details of all exclusions. Supplemental liability insurance, 
            or SLI is designed to supplement any liability cover that your car rental company provides. In most cases, this includes third party cover as its main focus.`
            var linkedInfo = {
                    title: 'Are there any policy exclusions?', 
                    body: linkedBody
                } 
            knowledge.push(linkedInfo)
            return knowledge
        }, 
    },
    data () {
        return {
            title: 'Frequently asked questions',
            pageDescription: 'The Frequently Asked Questions section is designed to give you all the information you need to understand what car hire excess is, how it works and how it can benefit you.'
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },    
}
</script>