<template>
    <div>
        <heroInner :title="pageTitle" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
					<li><router-link to="/get-a-quote">Get a quote</router-link></li>
					<li><router-link to="/your-quote">Your quote</router-link></li>
					<li><router-link to="/important-information">Important information</router-link></li>
					<li><router-link to="/your-details">Your details</router-link></li>
                </ul>
            </nav>

            <div class="row">                
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <div class="page-title">
                            <div class="white-bg-box">
                                <h2 class="form-title">Your contact information</h2>
                            </div>
                            <div class="dashed-hr"></div>
                        </div>
                        <!-- START Old or New Customer Option -->
				        <form v-if="!isAuthenticated">
					        <div class="blocked-inputs customer-type">
                                <label>I am a...</label>
                                <fieldset class="selectable-radio-buttons">
                                    <label for="ct-returning-customer" class="selectable">
                                        <input type="radio" id="ct-returning-customer" name="ct-returning-customer" :value="false" v-model="newCustomer">
                                        Returning customer
                                    </label>

                                    <label for="ct-new-customer" class="selectable">
                                        <input type="radio" id="ct-new-customer" name="ct-new-customer" :value="true" v-model="newCustomer">
                                        New customer
                                    </label>
                                </fieldset>
                            </div>
                        </form>
				        <!-- END Old or New Customer Option -->


                        <!-- START Return Customer Form -->
                        <returnCustomerForm v-if="!newCustomer && !isAuthenticated"/> 
                        <div v-html="resetSuccessMsg" style="color: #28a745" v-if="showSuccessMsg"></div>                    
                        <!-- END Return Customer Form -->
                        
                        <!-- START New Customer Form -->
                        <customerDetailsForm v-if="newCustomer"/>                        
                        <!-- END New Customer Form -->
                        
                        <!-- START Existing Customer Display -->
                        <form v-if="isAuthenticated && !editDetails">
                            <div v-if="address.townCity">
                                <fieldset>
                                    {{customer.firstName}} {{customer.lastName}} <br>
                                    {{address.addressLine1}} <br>
                                    {{address.addressLine2}} <br>
                                    {{address.townCity}} <br>
                                    {{address.postCode}} <br>
                                </fieldset>
                                <!-- <fieldset class="common">
                                    <blockquote>
                                        <h3>How we contact you</h3>
                                        To understand how Holiday Extras uses your information please take a look at our <router-link to="/privacy-policy">Privacy Policy</router-link>.
                                        One of the ways we will use your information is to let you know about Holiday Extras products and services via email, and on some occasions via text. If you do not wish to hear from us in this way, then you can choose to opt out of marketing <router-link :to="{ path: '/contact-us', hash: '#marketing' }">here</router-link>. However, we will still contact you with important insurance and service-related updates.
                                    </blockquote>
                                </fieldset> -->
                                <a class="button-secondary button-large" v-on:click="editCustomer">Edit</a>
                                <a class="button-primary button-large" v-on:click="submitSavedDetails">Continue</a>
                            </div>

                            <loadingTransition :loading="address.townCity ? false : true" :height="200" v-else style="position: static"/>
                        </form>                            
                        <!-- END Existing Customer Display -->

                        <!-- START Existing Customer Edit Form -->
                        <editCustomerForm v-if="editDetails" v-on:cancelEdit="cancelEditCustomer"/>                        
                        <!-- END Existing Customer Edit Form -->
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                </div>
            </div>

        </div>
        <modal id="forgotten-password-dialog" name="forgotten-password-dialog" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="false" height="auto" :adaptive="true">
            <div class="body">
                <h2>Reset your password</h2>
                <p class="subheading">Please enter your email address below, so we can send you a reset password link via email.</p>
                <form id="forgotten-password">
                    <fieldset class="common">
                        <label for="email-address-forgotten">Email address</label>
                        <input 
                            type="email"                             
                            id="email-address-forgotten" 
                            v-taurus-validator="'required'"
                            fieldlabel="Email address"
                            size="30" 
                            v-model="email" />
                        <div name="email-address-forgotten"></div>
                        <div v-html="passwordResetError" class="taurus-invalid"></div>
                    </fieldset>                                       
                </form>

                <loadingButton buttonLabel="Reset my password" :isLoading="resetLoading" @onClick="passwordReset()"/> 
                <button @click.prevent="$modal.hide('forgotten-password-dialog')" class="button-primary button-large">Cancel</button>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
import loadingButton from "../components/loadingButton.vue";
import loadingTransition from '../components/loadingTransition.vue'
import CommonMixin from "../mixins/common";

import returnCustomerForm from "../components/forms/returnCustomerForm.vue";
import customerDetailsForm from "../components/forms/customerDetailsForm.vue";
import editCustomerForm from "../components/forms/editCustomerForm.vue";

export default {
    name: 'your-details',
    components: {
        heroInner,
        usefulLinks,
        loadingButton,
        loadingTransition,
        returnCustomerForm,
        customerDetailsForm,
        editCustomerForm
    },
    mixins: [
        CommonMixin
    ],
    data() {
        return {
            pageTitle: "Your Details",
            pageDescription: 'Please enter your details below so we can use your contact information for our records and helps us to process your quote successfully.',
            newCustomer: true,
            email: null,
            editDetails: false,
            resetSuccessMsg: 'We have emailed your email password link.',
            showSuccessMsg: false       
        };
    },
    mounted() {
        if(this.isAuthenticated) {
            this.newCustomer = false;
        }
        this.$store.dispatch('updateFormProgress', this.$options.name) 
    },
    methods: {
        submitSavedDetails() {
            this.$router.push('main-driver/0');
        },
        editCustomer() {
            this.editDetails = true;
        },
        cancelEditCustomer() {
            this.editDetails = false;
        },
        async passwordReset() {
            //validation
            this.$store.commit('account/RESET_PASSWORD_RESET_ERROR')
            if(this.email) {                
                //submit
                await this.$store.dispatch('account/resetUserPassword', this.email);
                if(!this.isPasswordResetError) {
                    this.showSuccessMsg = true;
                    setTimeout(function(){                    
                        this.showSuccessMsg = false;
                    }.bind(this), 5000); 
                    this.$modal.hide('forgotten-password-dialog');
                }
            } else {
                //submit reset password
                this.$store.commit('account/SET_PASSWORD_RESET_ERROR', 'Email is required');
                this.$store.commit('account/SET_PASSWORD_RESET_STATUS', true);           
            }
        }        
    },
    metaInfo() {
        return { 
            title: this.pageTitle,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.pageTitle, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    }, 
    computed: {
        ...mapGetters({
            site: "template/site",
            customer: 'customer/getCustomer',
            address: 'customer/getAddress',
            resetLoading: 'account/isResetLoading',
            isAuthenticated: 'account/isAuthenticated',
            passwordResetError: 'account/getPasswordResetError',
            isPasswordResetError: 'account/isPasswordResetError'
        })
    }
};
</script>