import axios from 'axios';
let baseUrl = process.env.VUE_APP_API_BASE_URL
let key = process.env.VUE_APP_APIKEY
const service = axios.create({
    baseURL: baseUrl,
    headers: {
        "Access-Control-Allow-Origin": "*", 
        'Content-Type': 'application/json',
        "Ocp-Apim-Subscription-Key": key
    }  // <-- requires an actual header
});


export async function getSiteJson(siteData) {
    const response = await service.post(`/site/getresellerjson`, siteData);
    var json = response.data;
    // console.log(response);
    return json;
}