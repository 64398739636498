<template>
  <!-- <main :style="loginStyle"> -->
    <main :id="loginStyle">
    <transition name="router" mode="out-in">
      <headerComp v-bind:key="$route.fullPath" v-if="showComponent"/>
    </transition>
    
    <transition name="router" mode="out-in">
      <navbar v-bind:key="$route.fullPath" v-if="showComponent"/>
    </transition>    

    <transition name="router" mode="out-in">
      <router-view v-bind:key="$route.fullPath"/>
    </transition>      

    <transition name="router">
      <footerComp v-bind:key="$route.fullPath" v-if="showComponent"/>
    </transition> 
    <mobileMenu />
  </main>
</template>

<script>
import navbar from "@/components/navbar.vue";
import headerComp from "@/components/header.vue";
import footerComp from "@/components/footer.vue";
import mobileMenu from "@/components/mobileMenu.vue";
import { mapGetters } from 'vuex';

export default {
  name: 'app',
  components: {
    navbar,
    headerComp,
    footerComp,
    mobileMenu
  },
  data: () => ({
    showComponent: true
  }),
  metaInfo() {
    return {       
      titleTemplate: '%s | ' + this.brandName + ' · Car Hire Excess Insurance',
      meta: [          
        { prefix: 'og: http://ogp.me/ns#', name: 'twitter:title', template: chunk => `${chunk} | ${this.brandName} · Car Hire Excess Insurance`, vmid: 'twitter:title' },
        { 
          prefix: 'og: http://ogp.me/ns#', 
          property: 'og:description', 
          content: 'testy', 
          template: chunky => `${chunky}`, 
          vmid: 'og:description' 
        },
        { vmid: 'og:url', prefix: 'og: http://ogp.me/ns#', property: 'og:url', template: chunk => `${chunk}` },
        { name: 'twitter:image', content: require('./assets/img/social-media/' + this.brand + '/generic.png') },    
        { prefix: 'og: http://ogp.me/ns#', property: 'og:image', content: require('./assets/img/social-media/' + this.brand + '/generic.png') }        
      ],
      link: [        
        { rel: 'apple-touch-icon', sizes: '180x180', href: require('./assets/img/favicons/' + this.brand + '/apple-touch-icon.png') },
        { rel: 'icon', sizes: '32x32', href: require('./assets/img/favicons/' + this.brand + '/favicon-32x32.png') },
        { rel: 'icon', sizes: '16x16', href: require('./assets/img/favicons/' + this.brand + '/favicon-16x16.png') },
        { rel: "mask-icon", href: require('./assets/img/favicons/' + this.brand + '/safari-pinned-tab.svg'), color:"#00c1ff" },
        { rel: "shortcut icon", href: this.favicon }
        //{ rel:"manifest", href: require("../src/assets/img/favicons/holiday-extras/site.webmanifest") }
      ]
    }
  },
  computed: {
    ...mapGetters({
      site: 'template/site',
      accountAuth: 'account/isAuthenticated',
      brand: 'template/getBrand',
      brandName: 'template/getBrandName'
    }),
    loginStyle() {
      // return this.$route.name === 'LogIn' || this.$route.name === 'ChangePassword' ? 'height: 100%;' : '';
      return this.$route.name === 'LogIn' || this.$route.name === 'ChangePassword' ? 'fullHeight' : '';
    },
    favicon() {
      return (require('./assets/img/favicons/' + this.brand + '/favicon.ico'))
    }
  },
  methods: {
    async tryAuth() {
      await this.$store.dispatch("account/refreshToken");
    },
    async getJson() {
      //await this.$store.dispatch("template/getSiteResellerJson");
    },
    showcomponents() {
      // console.log(this.$route);
      // console.log('show comps inner: ', this.$route.matched[0].path !== '*');
      if(this.$route.name !== 'LogIn' && this.$route.name !== 'ChangePassword') {
        if(this.$route.matched[0].path !== '*') {
          this.showComponent = true;
        } else {
          this.showComponent = false;
        }        
      } else {
        this.showComponent = false;
      }
    }
  },
  async created() {
    //await this.getJson();
      
    // console.log('routy: ', this.$route);
    // console.log('routy: ', this.$route.matched[0].path !== '*');
    // console.log('acc section?: ', this.site.hasAccountSection)

    if(this.site.hasAccountSection) {
      this.tryAuth();
    }          
  },
  mounted() {
    // console.log('mounted()');
    this.showcomponents();
  },
  watch:{
    $route (to, from){
      // console.log('routy watch');
      this.showcomponents();
    }
  } 
};
</script>

<style lang="scss" scoped>
#fullHeight {
  height: 100%;
}
</style>

