<template>
    <a class="button-primary button-large"
       :class="classes"       
       v-on:click="buttonClick" v-html="buttonText"></a>    
</template>

<script>
export default {
    name: 'loadingButton',
    props: {
        classes: String,
        isDisabled: {
            type: Boolean,
            default: false
        },
        buttonLabel: String,
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        buttonClick() {
            if(this.isDisabled || this.isLoading) {
                return;
            } else {
                this.$emit('onClick')
            }
        }
    },
    computed: {
        buttonText() {
            if(this.isLoading) {
                return '<i class="iconTag fad fa-spinner-third fa-spin"></i>'
            } else {
                return this.buttonLabel;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.iconTag {
    color: #FFFFFF;
}
</style>