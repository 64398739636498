<template>
    <form>                            
        <label for="radio-mr">Title</label>
        <div class="radio-toolbar">
            <input type="radio" id="radio-mr" name="title" value="Mr" v-model="title">
            <label class="title" for="radio-mr" tabindex="0">Mr</label>
            <input type="radio" id="radio-mrs" name="title" value="Mrs" v-model="title">
            <label class="title" for="radio-mrs" tabindex="0">Mrs</label>
            <input type="radio" id="radio-miss" name="title" value="Miss" v-model="title">
            <label class="title" for="radio-miss" tabindex="0">Miss</label>
            <input type="radio" id="radio-ms" name="title" value="Ms" v-model="title">
            <label class="title" for="radio-ms" tabindex="0">Ms</label>
            <input type="radio" id="radio-mx" name="title" value="Mx" v-model="title">
            <label class="title" for="radio-mx" tabindex="0">Mx</label>
        </div>

        <fieldset class="common">
            <label for="first-name">First name</label>
            <input type="text" id="first-name" v-model="firstName" v-taurus-validator="'required|max:40'" fieldlabel="First name"/>
            <div name="first-name"></div>
        </fieldset>

        <fieldset class="common">
            <label for="last-name">Last name</label>
            <input type="text" id="last-name" v-model="lastName" v-taurus-validator="'required|max:40'" fieldlabel="Last name"/>
            <div name="last-name"></div>
        </fieldset>                            
        
        <fieldset class="common">
            <p>Date or birth</p>
            <span class="ib">
                <label for="dob-day">Day</label>
                <input type="text" id="dob-day" maxlength="2" size="2" v-taurus-validator="'required|maxint:31'" fieldlabel="Day" v-model="day" v-on:keypress="numbersOnly"/>
            </span>
            <span class="ib">
                <label for="dob-month">Month</label>
                <input type="text" id="dob-month" maxlength="2" size="2" v-taurus-validator="'required|maxint:12'" fieldlabel="Month" v-model="month" v-on:keypress="numbersOnly"/>
            </span>
            <span class="ib">
                <label for="dob-year">Year</label>
                <input type="text" id="dob-year" maxlength="4" size="4" v-taurus-validator="'required'" fieldlabel="Year" v-model="year" v-on:keypress="numbersOnly"/>
            </span>
            <div name="dob-day"></div>
            <div name="dob-month"></div>
            <div name="dob-year"></div>
        </fieldset>

        <fieldset class="common">
            <label for="telephone-number">Telephone number</label>
            <input type="tel" id="telephone-number" pattern="[0-9]" maxlength="11" v-model="telephone" v-taurus-validator="'required|max:11'" fieldlabel="Telephone number"/>
            <div name="telephone-number"></div>
        </fieldset>

        <fieldset class="common">
            <label for="address-line-1">Address line 1</label>
            <input type="text" id="address-line-1" v-model="addressLine1" v-taurus-validator="'required'" fieldlabel="Address line 1"/>
            <div name="address-line-1"></div>
        </fieldset>

        <fieldset class="common">
            <label for="address-line-2">Address line 2 <span class="optional">&mdash; Optional</span></label>
            <input type="text" name="address-line-2" id="address-line-2" v-model="addressLine2"/>
        </fieldset>

        <fieldset class="common">
            <label for="towncity">Town/city</label>
            <input type="text" id="towncity" v-model="townCity" v-taurus-validator="'required'" fieldlabel="Town/city"/>
            <div name="towncity"></div>
        </fieldset>

        <fieldset class="common">
            <label for="county">County</label>
            <input type="text" id="county" v-model="county" v-taurus-validator="'required'" fieldlabel="County"/>
            <div name="county"></div>
        </fieldset>

        <fieldset class="common">
            <label for="postcode">Postcode</label>
            <input type="text" class="postcode" id="postcode" v-model="postcode" v-taurus-validator="'required'" fieldlabel="Postcode"/>
            <div name="postcode"></div>
        </fieldset>

        <fieldset class="common">
            <label for="email-address">Email address</label>
            <input type="email" id="email-address" size="30" v-model="email" v-taurus-validator="'required'" fieldlabel="Email address" v-on:blur="doesUserExist" v-on:focus="resetEmailError"/>
            <div name="email-address"></div>
            <div v-if="isExistingEmail" class="taurus-invalid">This email address is already in our system. Please login instead.</div>
        </fieldset>

        <fieldset class="common">
            <label for="newpassword">Password</label>
            <input type="password" id="newpassword" size="30" v-model="password" v-taurus-validator="'required'" fieldlabel="Password"/>
            <div name="newpassword"></div>
        </fieldset>
        <fieldset class="common">
            <blockquote>
                <h3>How we contact you</h3>
                To understand how {{site.brandName}} uses your information please take a look at our <router-link to="/privacy-policy">Privacy Policy</router-link>.
                One of the ways we will use your information is to let you know about {{site.brandName}} products and services via email, and on some occasions via text. If you do not wish to hear from us in this way, then you can choose to opt out of marketing <router-link :to="{ path: '/contact-us', hash: '#marketing' }">here</router-link>. However, we will still contact you with important insurance and service-related updates.
            </blockquote>
        </fieldset>
        <a class="button-primary button-large" v-on:click="submit">Continue</a>
        <div v-html="createError" class="taurus-invalid"></div>
        <div class="taurus-invalid" v-if="formErrorSummary">There are errors in this form</div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';

import commonMixin from '../../mixins/common';

export default {
    name: 'customerDetailsForm',
    data: () => ({
        title: 'Mr',
        firstName: '',
        lastName: '',
        day: '',
        month: '',
        year: '',
        telephone: '',
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        county: '',
        postcode: '',
        email: '',
        password: '',
        formErrorSummary: false
    }),
    mixins: [
        commonMixin
    ],
    methods: {
        doesUserExist() {            
            if(this.email) {
                this.$store.dispatch('account/userExists', this.email)
            }          
        },
        resetEmailError() {
            this.$store.commit('account/RESET_USER_EXIST');
        },
        async submit() {
            this.formErrorSummary = false;
            this.checkFormErrors();
            if(this.checkForm() && !this.isExistingEmail) {
                //make call to store
                var data = {
                    title: this.title,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    emailAddress: this.email,
                    dateOfBirth: this.formatCSharpTime(this.constructDob(this.year, this.month, this.day), 'YYYY/MM/DD'),
                    telephone: this.telephone,
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    town: this.townCity,
                    county: this.county,
                    postcode: this.postcode
                }
                var details = Object.assign({}, data);
                var accData = {
                    email: this.email,
                    password: this.password,
                    brandId: this.$store.getters['template/getBrandId']
                };
                var accDetails = Object.assign({}, accData);
                //create user acc
                await this.$store.dispatch('account/createAccountInline', accDetails);

                if(!this.createError) {
                    await this.$store.dispatch('customer/submitDetails', details);
                    this.$router.push('main-driver/0');                    
                }            
            }       
            //form reset
        },
        checkFormErrors() {            
            this.$nextTick(() => { this.formErrors().length > 0 })
        }
    },
    computed: {
        ...mapGetters({
            isExistingEmail: 'account/isExistingEmail',
            createError: 'account/getCreateError',
            site: 'template/site'
        })
    },
    watch: {
        firstName() {
            this.checkFormErrors();
        },
        lastName() {
            this.checkFormErrors();
        },
        day() {
            this.checkFormErrors();
        },
        month() {
            this.checkFormErrors();
        },
        year() {
            this.checkFormErrors();
        },
        telephone() {
            this.checkFormErrors();
        },
        addressLine1() {
            this.checkFormErrors();
        },
        addressLine2() {
            this.checkFormErrors();
        },
        townCity() {
            this.checkFormErrors();
        },
        county() {
            this.checkFormErrors();
        },
        postcode() {
            this.checkFormErrors();
        },
        email() {
            this.checkFormErrors();
        },
        password() {
            this.checkFormErrors();
        }
    }
}
</script>