<template>
    <div>
        <heroInner :title="title"/>
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/get-a-quote">Get a quote</router-link></li>
                    <li><router-link to="/your-quote">Your quote</router-link></li>
                    <li><router-link to="/important-information">Important information</router-link></li>
                    <li><router-link to="/your-details">Your details</router-link></li>
                    <li><router-link to="/driver-details">Driver details</router-link></li>
                    <li><router-link to="/your-order">Your order</router-link></li>
                    <li><router-link to="/confirmation">Confirmation</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">

                        <h2 class="success-message"><i class="fas fa-check-circle"></i> Thank you for your order</h2>

                        <div class="price">

                            <!-- START Single Trip price -->
                            <div id="your-policy-summary">

                                <table class="order-confirmation">
                                    <tr>
                                        <th>Policy ref</th>
                                        <td>{{submittedPolicy.fullPolicyRef}}</td>
                                    </tr>
                                    <tr>
                                        <th>Purchase date</th>
                                        <td>{{submittedPolicy.purchaseDate | dateTimeFormat}}</td>
                                    </tr>
                                    <tr>
                                        <th>Customer</th>
                                        <td>{{customerFullName}}</td>
                                    </tr>
                                </table>

                                <ul class="disc">
                                    <li>A copy of your order confirmation along with your insurance policy has been sent to <b>{{customer.emailAddress}}</b></li>
                                    <!-- <li>Your policy will be active for <b>{{policyCoverDays}} days</b></li> -->
                                    <li>Your policy will be active for <b v-html="coverDuration()"></b><b> days</b></li>
                                    <li>Your policy will start <b>{{submittedPolicy.coverStartDate | dateTimeFormat }}</b></li>
                                    <li>Your policy will end <b>{{submittedPolicy.coverEndDate | dateTimeFormat }}</b></li>
                                </ul>

                                <div class="policy-cert-button-wrapper">
                                    <router-link class="button-primary button-large button-full" to="/policy-certificate">View policy certificate</router-link>
                                </div>

                                <div class="your-details-box">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <h4>Your details</h4>
                                            <p>{{customerFullName}}<br>
                                            {{customer.emailAddress}}<br>
                                            {{customer.telephone}}</p>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <p v-html="customerAddress"></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- END Single Trip price -->

                            <div class="page-title">
                                <div class="white-bg-box">
                                    <h2 class="form-title">Order summary</h2>
                                </div>
                                <div class="dashed-hr"></div>
                            </div>

                            <table class="order-summary">
                                <tr>
                                    <th>Destination</th>
                                    <td v-html="formatDestination(quote.cover.coverRegionId)"></td>
                                </tr>
                                <tr>
                                    <th>Policy Type</th>
                                    <td v-html="formatPolicyType(quote.cover.tripTypeId)"></td>
                                </tr>
                                <tr>
                                    <th>Start Date</th>
                                    <td>{{submittedPolicy.coverStartDate | shortDateFormat }}</td>
                                </tr>
                                <tr>
                                    <th>End Date</th>
                                    <td>{{submittedPolicy.coverEndDate | shortDateFormat }}</td>
                                </tr>
                                <tr>
                                    <th>Drivers</th>
                                    <td v-html="driverNumber(mainDrivers, namedDrivers)"></td>
                                </tr>
                            </table>

                            <div class="page-title">
                                <div class="white-bg-box">
                                    <h2 class="form-title">Order total</h2>
                                </div>
                                <div class="dashed-hr"></div>
                            </div>

                            <table class="order-summary">
                                <tr>
                                    <th>Subtotal</th>
                                    <td>£{{submittedPolicy.netPremium}}</td>
                                </tr>
                                <tr>
                                    <th>Shipping</th>
                                    <td>£0.00</td>
                                </tr>
                                <tr>
                                    <th>Discount</th>
                                    <td>{{ quote.cover.discount | formatCurrency }}</td>
                                </tr>
                                <tr>
                                    <th>Tax</th>
                                    <td>{{ quote.cover.ipt | formatCurrency }}</td>
                                </tr>
                                <tr>
                                    <th class="semibold">Total</th>
                                    <td class="semibold">{{ quote.cover.totalPremium | formatCurrency }}</td>
                                </tr>
                            </table>

                            <!-- <div class="subtle-gray-box">
                                <h3>Register</h3>
                                <ol>
                                    <li><router-link to="create-account">Create account</router-link> or <router-link to="log-in">Sign in</router-link>.</li>
                                    <li>Save your information.</li>
                                    <li>Checkout faster and enjoy special offers!</li>
                                </ol>
                            </div> -->

                            <div class="subtle-gray-box">
                                <h3>Sign up for updates</h3>
                                <form class="updates-signup">
                                    <label for="email-address">Email address</label>
                                    <input type="email" name="email-address" id="email-address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" size="30" required />
                                    <input type="submit" class="button-primary subscribe" value="Sign up for updates" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                </div>
            </div>

            <div id="dialog-but-first" class="dialog dialog-splash-screen zoom-anim-dialog mfp-hide">
                <div class="body">
                    <p class="heading">But first...</p>
                    <p class="message">We need you to read some important information so we know that this policy is right for you.</p>
                    <router-link to="/important-information" class="button-main-cta">Continue</router-link>
                    <img :src="'../assets/svg/icon-' + site.brand + '-graphic.svg'" alt="SOI Graphic Logo" class="icon-soi-graphic" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import heroInner from '../components/heroInner';
import usefulLinks from '../components/usefulLinks';
import commonMixin from '../mixins/common';

export default {
    name: 'confirmation',
    data () {
        return {
            title: 'Confirmation',
            pageDescription: 'Thank you for your order. Your order has been placed successfully. Your Car Hire Excess Insurance order details are displayed here.'
        }
    },
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    components: {
        heroInner,
        usefulLinks
    },
    async mounted() {
        this.$store.dispatch('updateFormProgress', this.$options.name);
        var uid = this.submittedPolicy.uid;
        await this.$store.dispatch('getPolicyPdf', uid);
    },
    mixins: [commonMixin],
    computed: {
        ...mapGetters({
            site: 'template/site',
            address: 'customer/getAddress',
            customer: 'customer/getCustomer',
            policyCoverDays: 'quote/getPolicyCoverDays',
            quote: 'quote/getQuotation',
            policyType: 'quote/getPolicyType',
            namedDrivers: 'customer/getNamedDrivers',
            mainDrivers: 'customer/getMainDrivers',
            submittedPolicy: 'getPolicy'
        }),
        customerFullName() {
            return this.customer.title + ' ' + this.customer.firstName + ' ' + this.customer.lastName;
        },
        customerAddress() {
            var html = this.address.addressLine1 + '<br>' + this.address.addressLine2 + '<br>' + this.address.townCity + '<br>' + this.address.postCode
            return html;
        },
    },
    methods: {
        coverDuration() {
            return Math.ceil(this.timeDayDuration(this.submittedPolicy.coverStartDate, this.submittedPolicy.coverEndDate));
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name == 'Payment') {
            const answer = window.confirm("Are you sure you want to leave?");
            if(answer) {
                //reset store
                this.$store.dispatch('account/resetAccountState');
                this.$store.dispatch('resetRootState');
                this.$store.dispatch('quote/resetQuoteState');
                this.$store.dispatch('customer/resetCustomerState');
                next('/');
            } else {
                next(false);
            }            
        } else if (to.name != 'Payment') {
            next();
        }
    }
}
</script>