import axios from 'axios';
// tenant id added via interceptor
let baseUrl = process.env.VUE_APP_API_BASE_URL;
let key = process.env.VUE_APP_APIKEY;

const api = axios.create({
    baseURL: baseUrl,
    headers: {
        "Ocp-Apim-Subscription-Key": key,
        "Access-Control-Allow-Origin": "*"
    }
});

export default api;
