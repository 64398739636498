<template>
    <div class="" id="log-in-details" role="tabpanel" aria-labelledby="log-in-details-tab">
        <form>
            <div class="row">
                <div class="col-md-6">
                    <fieldset class="common">
                        <label for="username">Email Address</label>
                        <input type="text" name="username" id="username" value="memblin@taurus.gi" readonly/>
                    </fieldset>
                </div>
                <div class="col-md-6">
                    <fieldset class="common">
                        <label for="newEmail">New Email</label>
                        <input type="text" name="newEmail" id="newEmail" value=""/>
                    </fieldset>
                </div>
                <div class="col-md-12">
                    <a href="#" class="button-primary">Saves Changes</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="hr seperator"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4>Reset your password</h4>
                    <p>This will send an email to the registered user.</p> <!-- open modal make user confirm -->
                    <a href="#" class="button-primary">Reset Password</a>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-12">
                    <div class="hr"></div>
                    <a href="#" class="button-primary">Saves Changes</a>
                </div>
            </div> -->
        </form>
    </div>
</template>

<script>
export default {
    name: 'account-details',
    props: {
        account: Object
    }
}
</script>