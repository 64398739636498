<template>
    <div class="error-wrapper">
	<div class="row">
		<div class="flex-item">
			<div class="graphic-wrapper">
				<!-- IE Rendering fix -->
				<canvas width="626" height="420"></canvas>
				<!--  START SVG  -->
				<svg version="1.1" id="screens-flickering-status-codes" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="626" height="420" viewBox="0 0 626 420" xml:space="preserve">
				  <path class="background" d="M0 0h626v420H0z"/>
				  <path fill="#FFF" d="M10.558 136.228c-4.565 0-8.28-3.714-8.28-8.278 0-4.563 3.714-8.276 8.28-8.276h31.374c4.567 0 8.282 3.712 8.282 8.276 0 4.565-3.715 8.278-8.282 8.278H10.558zm0-15.241c-3.841 0-6.967 3.124-6.967 6.963 0 3.84 3.126 6.964 6.967 6.964h31.374c3.843 0 6.969-3.124 6.969-6.964 0-3.839-3.127-6.963-6.969-6.963H10.558z"/>
				  <path class="background" d="M560.377 252.579a9.405 9.405 0 01-9.405 9.405H447.516a9.405 9.405 0 01-9.405-9.405v-154.3a9.405 9.405 0 019.405-9.405h103.457a9.405 9.405 0 019.405 9.405v154.3z"/>
				  <path fill="#FFF" d="M550.972 262.774H447.516c-5.621 0-10.195-4.573-10.195-10.195v-154.3c0-5.622 4.574-10.194 10.195-10.194h103.457c5.62 0 10.195 4.573 10.195 10.194v154.3c-.001 5.622-4.576 10.195-10.196 10.195zM447.516 89.664c-4.75 0-8.616 3.865-8.616 8.615v154.3c0 4.75 3.865 8.615 8.616 8.615h103.457c4.751 0 8.616-3.865 8.616-8.615v-154.3c0-4.75-3.865-8.615-8.616-8.615H447.516z"/>
				  <path class="device-screen" fill="#00BFFF" d="M449.157 101.126h100.25v143.037h-100.25z"/>
				  <path fill="#FFF" d="M550.159 245.241h-101.83V100.625h101.829v144.616zm-100.25-1.579h98.67V102.204h-98.67v141.458z"/>
				  <path class="background" d="M514.528 253.073a3.136 3.136 0 01-3.138 3.135h-24.294a3.136 3.136 0 110-6.271h24.294a3.136 3.136 0 013.138 3.136z"/>
				  <path fill="#FFF" d="M511.391 256.997h-24.294a3.93 3.93 0 01-3.927-3.924 3.93 3.93 0 013.927-3.925h24.294a3.93 3.93 0 013.927 3.925 3.93 3.93 0 01-3.927 3.924zm-24.294-6.27a2.35 2.35 0 00-2.347 2.346 2.349 2.349 0 002.347 2.345h24.294a2.349 2.349 0 002.348-2.345 2.35 2.35 0 00-2.348-2.346h-24.294z"/>
				  <path class="background" d="M480.588 282.445a6.428 6.428 0 01-6.428 6.428h-50.08a6.429 6.429 0 01-6.427-6.428V181.817a6.428 6.428 0 016.427-6.428h50.08a6.428 6.428 0 016.428 6.428v100.628z"/>
				  <path fill="#FFF" d="M474.16 289.663h-50.08c-3.979 0-7.216-3.238-7.216-7.218V181.817c0-3.98 3.237-7.218 7.216-7.218h50.08c3.979 0 7.218 3.238 7.218 7.218v100.628c0 3.98-3.239 7.218-7.218 7.218zm-50.079-113.484a5.644 5.644 0 00-5.638 5.639v100.628a5.643 5.643 0 005.638 5.638h50.08a5.646 5.646 0 005.639-5.638V181.817a5.646 5.646 0 00-5.639-5.639h-50.08z"/>
				  <path class="device-screen" fill="#00BFFF" d="M426.275 185.088h45.092v85.794h-45.092z"/>
				  <path fill="#FFF" d="M472.456 271.307h-46.671v-87.374h46.671v87.374zm-45.093-1.579h43.513v-84.216h-43.513v84.216z"/>
				  <path class="background" d="M458.846 279.696a2.244 2.244 0 01-2.244 2.244h-14.963a2.246 2.246 0 110-4.489h14.963a2.244 2.244 0 012.244 2.245z"/>
				  <path fill="#FFF" d="M456.603 282.729H441.64a3.037 3.037 0 01-3.035-3.034 3.037 3.037 0 013.035-3.034h14.963a3.038 3.038 0 013.034 3.034 3.039 3.039 0 01-3.034 3.034zm-14.964-4.488a1.456 1.456 0 100 2.909h14.963c.801 0 1.455-.652 1.455-1.454 0-.803-.653-1.455-1.455-1.455h-14.963z"/>
				  <path fill="#444" d="M464.033 279.696h7.184"/>
				  <path fill="#FFF" d="M464.033 278.906h7.184v1.579h-7.184z"/>
				  <path fill="#444" d="M427.025 279.696h7.182"/>
				  <path fill="#FFF" d="M427.025 278.906h7.182v1.579h-7.182z"/>
				  <path class="background" d="M408.715 232.994c0 7.362-5.969 13.331-13.33 13.331h-66.651c-7.362 0-13.331-5.969-13.331-13.331V53.035c0-7.362 5.969-13.331 13.331-13.331h66.651c7.362 0 13.33 5.969 13.33 13.331v179.959z"/>
				  <path fill="#FFF" d="M395.385 247.114h-66.651c-7.786 0-14.12-6.334-14.12-14.12V53.035c0-7.786 6.334-14.12 14.12-14.12h66.651c7.786 0 14.12 6.334 14.12 14.12v179.959c0 7.786-6.334 14.12-14.12 14.12zm-66.651-206.62c-6.915 0-12.541 5.626-12.541 12.541v179.959c0 6.915 5.627 12.541 12.541 12.541h66.651c6.914 0 12.541-5.626 12.541-12.541V53.035c0-6.915-5.627-12.541-12.541-12.541h-66.651z"/>
				  <path fill="#444" d="M328.178 69.697h67.762"/>
				  <path fill="#FFF" d="M328.178 68.907h67.762v1.579h-67.762z"/>
				  <path fill="#444" d="M328.178 101.913h67.762"/>
				  <path fill="#FFF" d="M328.178 101.123h67.762v1.579h-67.762z"/>
				  <path fill="#444" d="M328.178 134.128h67.762"/>
				  <path fill="#FFF" d="M328.178 133.338h67.762v1.579h-67.762z"/>
				  <path class="background" d="M375.389 186.893c0 7.363-5.967 13.33-13.33 13.33s-13.33-5.967-13.33-13.33c0-7.361 5.968-13.33 13.33-13.33 7.363 0 13.33 5.969 13.33 13.33z"/>
				  <path fill="#FFF" d="M362.059 201.013c-7.786 0-14.119-6.334-14.119-14.12 0-7.785 6.334-14.12 14.119-14.12s14.12 6.334 14.12 14.12-6.335 14.12-14.12 14.12zm0-26.66c-6.915 0-12.541 5.626-12.541 12.54 0 6.915 5.626 12.541 12.541 12.541s12.541-5.625 12.541-12.541c0-6.915-5.626-12.54-12.541-12.54z"/>
				  <path class="background" d="M369.278 222.997a7.219 7.219 0 01-7.219 7.22 7.22 7.22 0 01-7.221-7.22 7.22 7.22 0 017.221-7.221 7.218 7.218 0 017.219 7.221z"/>
				  <path fill="#FFF" d="M362.059 231.007c-4.417 0-8.011-3.593-8.011-8.01s3.593-8.011 8.011-8.011 8.009 3.593 8.009 8.011c0 4.417-3.591 8.01-8.009 8.01zm0-14.442a6.439 6.439 0 00-6.431 6.431 6.439 6.439 0 006.431 6.43 6.438 6.438 0 006.43-6.43 6.439 6.439 0 00-6.43-6.431z"/>
				  <path class="background" d="M279.153 224.378a8.632 8.632 0 01-8.631 8.633H76.662a8.632 8.632 0 01-8.631-8.633V97.057a8.632 8.632 0 018.631-8.632h193.86a8.63 8.63 0 018.631 8.632v127.321z"/>
				  <path fill="#FFF" d="M270.522 233.801H76.662c-5.195 0-9.421-4.227-9.421-9.423V97.057c0-5.195 4.226-9.422 9.421-9.422h193.86c5.195 0 9.421 4.227 9.421 9.422v127.321c0 5.196-4.226 9.423-9.421 9.423zM76.662 89.215c-4.324 0-7.841 3.518-7.841 7.843v127.321c0 4.325 3.517 7.843 7.841 7.843h193.86c4.324 0 7.842-3.518 7.842-7.843V97.057c0-4.324-3.518-7.843-7.842-7.843H76.662z"/>
				  <path class="device-screen" fill="#00BFFF" d="M81.633 101.412h184.869v117.251H81.633z"/>
				  <path fill="#FFF" d="M266.816 220.133H80.368v-118.83h186.448v118.83zm-184.868-1.579h183.289V102.882H81.948v115.672z"/>
				  <g>
				    <path class="background" d="M135.647 233.011h71.933v17.624h-71.933z"/>
				    <path fill="#FFF" d="M208.369 251.424h-73.512v-19.203h73.512v19.203zm-71.932-1.578h70.353v-16.044h-70.353v16.044z"/>
				  </g>
				  <g>
				    <path class="background" d="M222.687 264.301v-5.035a8.632 8.632 0 00-8.633-8.632h-84.88a8.631 8.631 0 00-8.632 8.632v5.035h102.145z"/>
				    <path fill="#FFF" d="M223.476 265.091H119.752v-5.824c0-5.195 4.227-9.421 9.422-9.421h84.88c5.195 0 9.422 4.226 9.422 9.421v5.824zm-102.145-1.579h100.565v-4.245c0-4.324-3.518-7.842-7.843-7.842h-84.88c-4.325 0-7.843 3.518-7.843 7.842v4.245z"/>
				  </g>
				  <g>
				    <path class="background" d="M403.229 280.636v-5.634c0-7.688-6.232-13.92-13.92-13.92-.747 0-1.479.06-2.195.173v19.381h16.115z"/>
				    <path fill="#FFF" d="M404.019 281.425h-17.695v-20.844l.666-.105a14.794 14.794 0 012.318-.184c8.113 0 14.711 6.599 14.711 14.711v6.422zm-16.116-1.58h14.536v-4.843c0-7.24-5.89-13.131-13.131-13.131-.466 0-.936.025-1.406.075v17.899z"/>
				  </g>
				  <g>
				    <path class="background" d="M387.114 280.636h-11.725v15.245c0 7.686 6.233 13.919 13.919 13.919 7.688 0 13.92-6.233 13.92-13.919v-15.245h-16.114z"/>
				    <path fill="#FFF" d="M389.309 310.59c-8.11 0-14.709-6.599-14.709-14.709v-16.036h29.42v16.036c-.001 8.11-6.599 14.709-14.711 14.709zm-13.13-29.165v14.456c0 7.24 5.89 13.13 13.13 13.13 7.241 0 13.131-5.89 13.131-13.13v-14.456h-26.261z"/>
				  </g>
				  <g>
				    <path class="background" d="M387.114 261.255c-6.644 1.053-11.725 6.806-11.725 13.747v5.634h11.725v-19.381z"/>
				    <path fill="#FFF" d="M387.903 281.425H374.6v-6.423c0-7.281 5.211-13.39 12.391-14.526l.912-.146v21.095zm-11.724-1.58h10.145v-17.634c-5.932 1.366-10.145 6.605-10.145 12.79v4.844z"/>
				  </g>
				  <g>
				    <path class="background" d="M352.665 285.441a7.642 7.642 0 001.452-4.485V160.181a7.689 7.689 0 00-7.689-7.689H164.465a7.69 7.69 0 00-7.689 7.689v120.775c0 1.677.543 3.222 1.453 4.485h194.436z"/>
				    <path fill="#FFF" d="M353.069 286.231H157.825l-.236-.328a8.41 8.41 0 01-1.602-4.947V160.181c0-4.675 3.803-8.479 8.478-8.479h181.963c4.675 0 8.479 3.804 8.479 8.479v120.775a8.414 8.414 0 01-1.602 4.947l-.236.328zm-194.422-1.58h193.6a6.83 6.83 0 001.08-3.695V160.181c0-3.804-3.094-6.9-6.899-6.9H164.465c-3.804 0-6.899 3.096-6.899 6.9v120.775c0 1.318.374 2.587 1.081 3.695z"/>
				  </g>
				  <g>
				    <path class="device-screen" fill="#00BFFF" d="M166.388 163.705h178.119v111.486H166.388z"/>
				    <path fill="#FFF" d="M345.296 275.98H165.598V162.915h179.697V275.98zm-178.118-1.579h176.539V164.494H167.178v109.907z"/>
				  </g>
				  <g>
				    <path class="background" d="M369.495 313.954l-16.829-28.513H158.23l-16.83 28.513s-4.217 6.406 5.607 6.406h88.257l1.602-5.766h37.162l1.602 5.766h88.259c9.822 0 5.606-6.406 5.606-6.406z"/>
				    <path fill="#FFF" d="M363.889 321.15h-88.86l-1.601-5.767h-35.962l-1.602 5.767h-88.858c-3.634 0-5.944-.868-6.867-2.581-1.212-2.248.526-4.936.601-5.049l17.038-28.869h195.336l17.06 28.901c.053.081 1.792 2.768.58 5.016-.922 1.714-3.232 2.582-6.865 2.582zm-87.659-1.579h87.659c2.964 0 4.857-.607 5.476-1.752.758-1.404-.517-3.411-.529-3.431l-16.622-28.156H158.68l-16.6 28.124c-.032.051-1.316 2.052-.548 3.468.62 1.143 2.513 1.748 5.474 1.748h87.657l1.602-5.767h38.362l1.603 5.766z"/>
				  </g>
				  <g>
				    <path class="background" d="M236.866 314.594l-1.602 5.766H275.629l-1.601-5.766z"/>
				    <path fill="#FFF" d="M276.668 321.15h-42.443l2.041-7.346h38.362l2.04 7.346zm-40.365-1.579h38.288l-1.163-4.188h-35.962l-1.163 4.188z"/>
				  </g>
				  <g>
				    <path class="background" d="M353.584 310.108H157.311L165 292.595h180.895z"/>
				    <path fill="#FFF" d="M354.792 310.898h-198.69l8.382-19.093h181.927l8.381 19.093zm-196.272-1.579h193.854l-6.995-15.934H165.516l-6.996 15.934z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M166.388 366.111H140.5a.79.79 0 010-1.58h25.888a.789.789 0 110 1.58z"/>
				    <path fill="#FFF" d="M153.444 379.055a.79.79 0 01-.79-.79v-25.889a.79.79 0 011.58 0v25.889a.79.79 0 01-.79.79z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M301.793 375.203c-7.439 0-13.491-6.052-13.491-13.491 0-7.438 6.052-13.49 13.491-13.49 7.438 0 13.49 6.052 13.49 13.49 0 7.439-6.052 13.491-13.49 13.491zm0-25.402c-6.568 0-11.911 5.343-11.911 11.911s5.343 11.912 11.911 11.912 11.911-5.343 11.911-11.912c-.001-6.568-5.344-11.911-11.911-11.911z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M108.997 21.958c-3.937 0-7.14-3.203-7.14-7.14s3.203-7.14 7.14-7.14 7.14 3.203 7.14 7.14-3.203 7.14-7.14 7.14zm0-12.701a5.568 5.568 0 00-5.561 5.561 5.568 5.568 0 005.561 5.561 5.568 5.568 0 005.561-5.561 5.567 5.567 0 00-5.561-5.561z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M289.091 47.824c-5.897 0-10.696-4.798-10.696-10.696s4.798-10.696 10.696-10.696 10.696 4.798 10.696 10.696-4.798 10.696-10.696 10.696zm0-19.813c-5.026 0-9.116 4.089-9.116 9.117 0 5.027 4.091 9.116 9.116 9.116 5.027 0 9.116-4.089 9.116-9.116.001-5.027-4.089-9.117-9.116-9.117z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M238.62 378.252h-31.04a.79.79 0 010-1.58h31.04a.789.789 0 110 1.58z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M298.998 413.258h-79.254a.79.79 0 110-1.58h79.254a.79.79 0 010 1.58z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M214.93 42.174h-89.949a.789.789 0 110-1.578h89.949a.789.789 0 110 1.578z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M250.232 9.257h-29.936a.79.79 0 110-1.58h29.936a.79.79 0 110 1.58z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M265.2 65.634h-29.936a.789.789 0 110-1.579H265.2a.79.79 0 110 1.579z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M588.355 65.634h-29.936a.79.79 0 010-1.579h29.936a.79.79 0 110 1.579z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M587.393 318.69c0-3.937 3.203-7.14 7.14-7.14s7.14 3.203 7.14 7.14-3.203 7.14-7.14 7.14-7.14-3.203-7.14-7.14zm1.579 0a5.568 5.568 0 005.561 5.561 5.568 5.568 0 005.561-5.561 5.568 5.568 0 00-5.561-5.561 5.568 5.568 0 00-5.561 5.561z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M403.742 341.001c0-5.898 4.798-10.696 10.696-10.696 5.897 0 10.696 4.798 10.696 10.696s-4.798 10.696-10.696 10.696c-5.897-.001-10.696-4.799-10.696-10.696zm1.58 0c0 5.027 4.089 9.116 9.116 9.116 5.026 0 9.116-4.089 9.116-9.116 0-5.027-4.091-9.117-9.116-9.117-5.026 0-9.116 4.089-9.116 9.117z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M488.6 346.046h89.949a.789.789 0 100-1.578H488.6a.789.789 0 100 1.578z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M453.298 313.13h29.936a.79.79 0 100-1.58h-29.936a.79.79 0 100 1.58z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M469.024 54.574h-25.888a.79.79 0 010-1.58h25.888a.789.789 0 110 1.58z"/>
				    <path fill="#FFF" d="M456.08 67.518a.79.79 0 01-.79-.79V40.839a.79.79 0 011.58 0v25.889a.79.79 0 01-.79.79z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M584.066 228.902c-4.565 0-8.28-3.714-8.28-8.278 0-4.563 3.714-8.276 8.28-8.276h31.374c4.567 0 8.282 3.712 8.282 8.276 0 4.565-3.715 8.278-8.282 8.278h-31.374zm0-15.24c-3.841 0-6.967 3.124-6.967 6.963 0 3.84 3.126 6.964 6.967 6.964h31.374c3.843 0 6.969-3.124 6.969-6.964 0-3.839-3.127-6.963-6.969-6.963h-31.374z"/>
				  </g>
				  <g>
				    <path fill="#FFF" d="M522.135 33.723c-7.439 0-13.491-6.052-13.491-13.491 0-7.438 6.052-13.49 13.491-13.49 7.438 0 13.49 6.052 13.49 13.49 0 7.439-6.052 13.491-13.49 13.491zm0-25.401c-6.568 0-11.911 5.343-11.911 11.911s5.343 11.912 11.911 11.912 11.911-5.343 11.911-11.912c0-6.568-5.343-11.911-11.911-11.911z"/>
				  </g>
				</svg>
				<!--  END SVG  -->
			</div>
		</div>
		<div class="flex-item">
			<div class="text-wrapper">
				<div class="inner-wrapper response-status-codes">
					<h1 class="error-animation-transition">404</h1>
					<h2>Page not found.</h2>
					<p>This might be because;</p>
					<ul>
						<li>You have typed the web address incorrectly.</li>
						<li>The page you were looking for may have been moved, updated or deleted.</li>
					</ul>
					<p>Please try the following options instead:</p>
					<ul>
						<li>
							<a :href="'mailto:' + site.email + '?subject=Broken link on your website&body=Hello,%0D%0A%0D%0AI am writing this email to let you know that I encountered an error page whilst using your website.%0D%0A%0D%0AI have copied the link to the page that I saw before the error page below;%0D%0A[copy and paste link here]%0D%0A%0D%0AKind regards,%0D%0A[Your Name]'">
                                Send us an email
                            </a> to let us know we’ve got a broken link, so we can fix it.
                        </li>
						<li>Try one of the links below, to take you back to safety.</li>
					</ul>
					<div class="button-group">
						<router-link class="button-primary" to="/home">Home page</router-link>
						<a class="button-secondary previous-page" @click="$router.go(-1)">Previous page</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            site: 'template/site'
        })
    }
}
</script>