<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/password-reset-success">Password reset success</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <h2>Your password has been reset</h2>
                        <p class="subheading">We have emailed you with a link for you to visit to complete the password reset process. Please <strong>check your emails</strong>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
export default {
  data() {
    return {
      title: "Password reset success",
      pageDescription: 'Your password has been reset successfully. We have emailed you with a link for you to visit to complete the password reset process.'
    };
  },
  components: {
    heroInner
  },
  metaInfo() {
    return { 
        title: this.title,
        meta: [
          { name: 'description', content: this.pageDescription },
          { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
        ]
    }
  },
  computed: {
    ...mapGetters({
      site: "template/site"
    })
  }
};
</script>