<template>
    <div>
        <heroInner :title="title"/>
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
            
            <nav class="breadcrumbs">
                <ul>                   
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/get-a-quote">Get a quote</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <div class="page-title">
                            <div class="white-bg-box">
                                <h2 class="form-title">{{formTitle}}</h2>
                            </div>
                            <div class="dashed-hr"></div>
                        </div>
                        <form>
                            <label>Policy type</label>
                            <radioToolbar v-model="tripTypeId" 
                                          v-on:selectedItem="showTrip" 
                                          :dataArr="getTripType" 
                                          :inputName="'policyType'"
                                          id="trip-comp"
                                          :showInfo="true" 
                                          v-taurus-validator="'required'" fieldlabel="Policy type"
                                          v-on:showTripInfo="showTripInfoDialog"/>     
                            <div name="trip-comp"></div>
                        

                            <!-- START Forms Wrapper -->
                            <div class="forms-wrapper">
                                
                                <loadingTransition :loading="loading" :height="loadingHeight" />                            

                                <!-- START Single Trip form -->
                                <form ref="singleform" class="st-form">

                                    <div class="blocked-inputs travelling-to">
                                        <div id="travelling-container">
                                            <label>Travelling to</label>
                                            <a class="init-destination-dialog" @click.prevent="showInfoDialog" href="">
                                                <i class="fas fa-question-circle"></i>
                                            </a>
                                        </div>                                    
                                        <selectableRadios :dataArr="getDestinations" v-model="coverRegionId" :showHelp="false"/>
                                    </div>

                                    <fieldset class="common">
                                        <label class="block-label" for="st-departure-date">Departure date</label>
                                        <div class="flatpickr">
                                            <input id="st-departure-date"  type="text" placeholder="DD/MM/YYYY" v-model="coverStartDate" v-taurus-validator="'required'" fieldlabel="Departure date">                                        
                                            <a class="departure-date-button" title="toggle" data-toggle>
                                                <i class="far fa-calendar-alt"></i>
                                            </a>                                        
                                        </div>
                                        <div name="st-departure-date"></div>
                                        <div v-html="departDateError" class="taurus-invalid"></div>
                                    </fieldset>
                                    

                                    <fieldset class="common" v-show="single">
                                        <label class="block-label" for="st-return-date">Return date</label>
                                        <div class="flatpickr">
                                            <input id="st-return-date" type="text" placeholder="DD/MM/YYYY" v-model="coverEndDate" v-taurus-validator="'required_if:tripTypeId,1'" fieldlabel="Return date">
                                            <a class="return-date-button" title="toggle" data-toggle>
                                                <i class="far fa-calendar-alt"></i>
                                            </a>
                                        </div>
                                        <div name="st-return-date" invalidfeedback="Return date is required if you have selected single trip"></div>
                                        <div v-html="returnDateError" class="taurus-invalid"></div>
                                    </fieldset>

                                    <fieldset class="common" v-show="multi">
                                        <label>Cover for</label>
                                        <radioToolbar v-model="groupTypeId" v-on:selectedItem="showCover" :dataArr="getGroupType" :inputName="'groupType'" />
                                    </fieldset>

                                    <!-- START Forms Wrapper -->
                                    <div class="forms-wrapper-inner" ref="innerForm" id="in-form">

                                        <loadingTransition :loading="innerLoading" :height="innerLoadingHeight"/>

                                        <!-- <div class="cover-for-family" ref="familyForm" style="display:block;">
                                                                                
                                        </div> -->
                                        <fieldset class="common">
                                            <label class="block-label" for="amt-main-drivers">Number of main drivers</label>
                                            <div class="stepper">
                                                <!-- <a class="stepper-decrease step" title="Subtract 1" v-on:click="incrementor('-', numberMainDrivers, 'main')">-</a>
                                                <input id="amt-main-drivers" name="amt-main-drivers" type="text" v-model="numberMainDrivers" disabled>
                                                <a class="stepper-increase step" title="Add 1" v-on:click="incrementor('+', numberMainDrivers, 'main')">+</a> -->
                                                <a class="stepper-decrease step" title="Subtract 1">-</a>
                                                <input id="amt-main-drivers" name="amt-main-drivers" type="text" v-model="numberMainDrivers" disabled>
                                                <a class="stepper-increase step" title="Add 1">+</a>
                                            </div>                                           
                                        </fieldset> 
                                        <fieldset class="common">
                                            <blockquote v-if="tripTypeId == 2 && groupTypeId == 2">
                                                Only 2 main drivers are available on the Family annual policy.
                                            </blockquote>
                                            <blockquote v-if="tripTypeId == 2 && groupTypeId == 1">
                                                Only 1 main driver is available on the Individual annual policy.
                                            </blockquote>
                                            <blockquote v-if="tripTypeId == 1">
                                                Only 1 main driver is available on the Single trip policy.
                                            </blockquote>
                                        </fieldset>
                                        
                                        <fieldset class="common">
                                            <label class="block-label" for="st-named-drivers">Number of additional named drivers</label>
                                            
                                            <div class="stepper">
                                                <a class="stepper-decrease step" title="Subtract 1" v-on:click="incrementor('-', numberNamedDrivers, 'named')">-</a>
                                                <input id="st-named-drivers" name="st-named-drivers" type="number" v-model="numberNamedDrivers" disabled>
                                                <a class="stepper-increase step" title="Add 1" v-on:click="incrementor('+', numberNamedDrivers, 'named')">+</a>
                                            </div>
                                        </fieldset>

                                        <fieldset class="common">
                                            <blockquote>
                                                A person authorised to drive the insured vehicle independently of any driver, but is unable to hire the insured vehicle without the main driver.
                                            </blockquote>
                                        </fieldset>

                                        <fieldset class="common selectable-checkboxes">
                                            <div class="blocked-inputs i-can-confirm">
                                                <label for="st-driver-age-confirm" class="selectable" :class="driverAgeConfirm ? 'selected' : ''">
                                                    <input type="checkbox" id="st-driver-age-confirm" v-model="driverAgeConfirm" v-taurus-validator="'equal_to:true'" fieldlabel="Confirm age">
                                                    I can confirm that all drivers are UK residents aged between {{site.driverAgeMin}} to {{site.driverAgeMax}}
                                                </label>
                                                <div name="st-driver-age-confirm" invalidfeedback="Please confirm drivers age"></div>
                                                <label for="st-driver-licence-confirm" class="selectable" :class="driverLicenseConfirm ? 'selected' : ''">
                                                    <input type="checkbox" id="st-driver-licence-confirm" v-model="driverLicenseConfirm" v-taurus-validator="'equal_to:true'" fieldlabel="Confirm licenses">
                                                    I can confirm all drivers hold a valid full UK driving licence
                                                </label>   
                                                <div name="st-driver-licence-confirm" invalidfeedback="Please confirm drivers licenses"></div>                                         
                                            </div>
                                        </fieldset>

                                        <loadingButton buttonLabel="Submit" classes="no-float" :isLoading="isQuoteLoading" @onClick="submitForm"/>
                                        <div class="taurus-invalid" v-if="formErrorSummary">There are errors in this form</div>
                                    </div>
                                    <!-- END Forms Wrapper -->               

                                </form>
                                <!-- END Single Trip form -->
                            </div>
                            <!-- END Forms Wrapper -->
                        </form>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                </div>
            </div>
            <modal id="info-dialog" name="info-dialog" classes=" zoom-anim-dialog dialog-splash-screen" height="auto" :clickToClose="true" :scrollable="true" :adaptive="true">
                <div class="body">
                    <p class="heading">Europe</p>
                    <p class="message">Albania, Andorra, Armenia, Austria, Azerbaijan, Azores, Balearics, Belarus, Belgium, Bosnia Herzegovina, Bulgaria, Canary Islands, Channel Islands, Croatia, Cyprus, Czech Republic, Denmark, Egypt, Estonia, Faroe Islands, Finland, France, Germany, Gibraltar, Greece (including Greek Islands), Hungary, Iceland, Ireland (Republic), Israel, Italy, Lapland, Latvia, Liechtenstein, Lithuania, Luxembourg, Macedonia, Madeira, Malta, Moldova, Monaco, Montenegro, Morocco, Netherlands, Norway, Poland, Portugal, Romania, Russian Federation, San Marino, Serbia, Slovakia, Slovenia, Spain, Sweden, Switzerland, Tunisia, Turkey, Ukraine, United Kingdom and Vatican City.</p>                    
                    <p class="heading">Worldwide excluding USA, Canada, Mexico and the Caribbean</p>
                    <p class="message">All countries in the world excluding the USA, Canada, Mexico, the islands of the Caribbean and Bermuda.</p>
                    <p class="heading">Worldwide including USA, Canada, Mexico and the Caribbean</p>
                    <p class="message">All countries of the world.</p>
                    <p class="message">Note: No cover is provided under either a Single Trip or Annual Multi-Trip policy for any trip in, to, or through Afghanistan, Burundi, Central African Republic, Chad, Iran, Iraq, Liberia, Libya, Myanmar, North Korea, South Sudan, Sudan, Syria, Yemen or countries regions or areas where the Foreign, Commonwealth & Development Office (FCDO) has warned against “all” or “all but essential” travel” </p>
                    <a @click="$modal.hide('info-dialog')" class="button-main-cta">Close</a>
                </div>
            </modal>
            <modal id="trip-info-dialog" name="trip-info-dialog" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="true" height="auto" :adaptive="true">                
                <div class="body">
                    <p class="heading">Single Trip</p>
                    <p class="message">Cover for one single vehicle rental during your trip, up to 94 days in duration.</p>
                    <p class="heading">Annual Multi Trip</p>
                    <p class="message">If you are taking more than one trip a year, an Annual Multi-Trip policy may be more suitable. This covers you for multiple vehicle rentals within the destination area you have chosen during a 12 month period for up to 62 days.</p>
                    <a @click="$modal.hide('trip-info-dialog')" class="button-main-cta">Close</a>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import commonMixin from "../mixins/common.js";

import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
import loadingTransition from "../components/loadingTransition";
import flatpickr from 'flatpickr';
import selectableRadios from '../components/selectableRadios.vue';
import radioToolbar from "../components/radioToolbar.vue";
import loadingButton from "../components/loadingButton.vue";

export default {
    name: 'get-a-quote',
    data() {
        return {
            title: "Get a quote",
            pageDescription: "Get a quote for quality affordable car hire excess insurance today! Simply enter your details using the form below and we'll do the rest.",
            formTitle: 'Single Trip',
            tripTypeId: "1",
            coverRegionId: "2",
            coverStartDate: '',
            coverEndDate: '',
            numberNamedDrivers: 0,
            driverAgeConfirm: false,
            driverLicenseConfirm: false,
            numberMainDrivers: 0,
            groupTypeId: 1,

            validationErrors: null,
            departDateError: null,
            returnDateError: null,
            ageConfirmError: null,
            licenseError: null,
            destinationError: null,

            loadingHeight: 100,
            innerLoadingHeight: 100,
            loading: false,      
            innerLoading: false,            
            multi: false,
            single: true,
            family: false,
            infoId: 2,

            fpInstanceDepart: null,
            fpInstanceReturn: null,

            formErrorSummary: false
        };
    },
    components: {
        heroInner,
        usefulLinks,
        loadingTransition,
        selectableRadios,
        radioToolbar,
        loadingButton
    },
    mixins: [
        commonMixin
    ],
    mounted() {        
        this.$store.dispatch('updateFormProgress', this.$options.name)      
        if(this.quotation.cover.coverStartDate) {
            this.restoreQuote();
        } else {
            this.showTrip(this.tripTypeId);
        }

        var departDate = flatpickr('#st-departure-date', {
            dateFormat: "d/m/Y",
			disableMobile: false,
			minDate: "today",
		});
        departDate.config.onChange.push(this.onDepartDateChange);
        this.fpInstanceDepart = departDate;
        var returnDate = flatpickr('#st-return-date', {
			dateFormat: "d/m/Y",
			disableMobile: false,
			minDate: new Date().fp_incr(1) // 1 day from now
		});
        returnDate.config.onChange.push(this.onReturnDateChange);
        this.fpInstanceReturn = returnDate;
    },
    beforeDestroy() {
        document.querySelector("#st-departure-date")._flatpickr.destroy();
        document.querySelector("#st-return-date")._flatpickr.destroy();
    },
    methods: {
        onReturnDateChange(selectedDates, dateStr, instance) {         
            this.returnDateError = null;

            if(this.isBeforeDate(this.coverStartDate, dateStr)) {
                this.returnDateError = 'Return date cannot be before departure date'
            }
            if(this.isMoreThanYear(this.coverStartDate, dateStr) && this.tripTypeId.toString() == "2") {
                this.returnDateError = 'Cannot have more than 365 days cover for annual multi-trip'
            }
            if(this.tripDuration(this.coverStartDate, dateStr, "DD/MM/YYYY") > 94 && this.tripTypeId.toString() == "1") {
                this.returnDateError = 'Cannot have more than 94 days cover for a single trip'
            }
        },
        onDepartDateChange(selectedDates, dateStr, instance) {             
            this.departDateError = null;            
            if(this.isBeforeDate(dateStr, this.coverEndDate)) {
                this.departDateError = 'Departure date cannot be after return date'
            }
            if(this.isMoreThanYear(dateStr, this.coverEndDate) && this.tripTypeId.toString() == "2") {
                this.departDateError = 'Cannot have more than 365 days cover for annual multi-trip'
            }
            if(this.tripDuration(dateStr, this.coverEndDate, "DD/MM/YYYY") > 94 && this.tripTypeId.toString() == "1") {
                this.departDateError = 'Cannot have more than 94 days cover for a single trip'
            }
            if(this.isOutsideFutureBooking(dateStr, this.site.stAdvanceBookingDays) && this.tripTypeId.toString() == "1") {
                this.departDateError = 'Cannot have a departure date of more than 365 days in the future'
            }
            if(this.isOutsideFutureBooking(dateStr, this.site.amtAdvanceBookingDays) && this.tripTypeId.toString() == "2") {
                this.departDateError = 'Cannot have a departure date of more than 90 days in the future'
            }
        }, 
        showTrip(id) {
            //console.log(id);
            var tt = this.getTripType.find(t => t.id == id);
            var trip = tt.description;
            
            this.loadingHeight = this.$refs.singleform.clientHeight
            this.loading = true;

            if(trip == 'Annual multi-trip') {                              
                setTimeout(function(){                    
                    this.multi = true;
                    this.single = false;
                    this.formTitle = trip;                    
                    //this.formReset();
                    this.setDrivers(); 
                    this.resetDatePickers();
                    this.loading = false;
                }.bind(this), 750)                    
            }
            else {                
                setTimeout(function(){                    
                    this.single = true;
                    this.multi = false;
                    this.formTitle = trip;
                    //this.formReset();                    
                    this.setDrivers();
                    this.resetDatePickers();
                    this.loading = false;
                }.bind(this), 750)
            }                                    
        },
        Add(val) {
            return parseInt(val) + 1;
        },
        Sub(val) {
            return parseInt(val) - 1;
        }, 
        incrementor(inc, model, driver) {
            var newVal = 0;

            if(this.tripTypeId == 1) {
                var max = this.site.stNamedDriverMax;
                var min = this.site.stNamedDriverMin;
                if(inc == '+') {                    
                    newVal = this.Add(model)
                    if(newVal >= max) {
                        newVal = max;
                    }
                }
                else if(inc == '-') {
                    newVal = this.Sub(model)
                    if(newVal <= min) {
                        newVal = min;
                        //disable input
                    }
                }
                this.numberNamedDrivers = newVal; 
            }
            if(this.tripTypeId == 2) {
                var namedMax = this.site.amtNamedDriverMax;
                var namedMin = this.site.amtNamedDriverMin;
                var mainMax = this.site.amtMainDriverMax;
                var mainMin = this.site.amtMainDriverMin;
                
                if(driver == 'named') {

                    if(inc == '+') {                    
                        newVal = this.Add(model)
                        if(this.groupTypeId == 1) { //ind amt 8 max
                            if(newVal >= this.site.stNamedDriverMax) {
                                newVal = this.site.stNamedDriverMax;
                            }
                        } else {
                            if(newVal >= namedMax) {
                                newVal = namedMax;
                            }
                        }
                        
                    }
                    else if(inc == '-') {
                        newVal = this.Sub(model)
                        if(newVal <= namedMin) {
                            newVal = namedMin;
                        }
                    }
                    this.numberNamedDrivers = newVal;
                }
                else if(driver == 'main') {
                    if(inc == '+') {                    
                        newVal = this.Add(model)
                        if(newVal >= mainMax) {
                            newVal = mainMax;
                        }
                    }
                    else if(inc == '-') {
                        newVal = this.Sub(model)
                        if(newVal <= mainMin) {
                            newVal = mainMin;
                        }
                    }
                    this.numberMainDrivers = newVal;
                }
            }
        },
        formReset() {            
            this.coverRegionId = 2,
            this.coverStartDate = '',
            this.coverEndDate = '',
            this.numberNamedDrivers = 0,
            this.driverAgeConfirm = false,
            this.driverLicenseConfirm = false,            
            this.groupTypeId = 1,
            this.family = false
            this.setDrivers();                        
        },
        setDrivers() {
            if(parseInt(this.tripTypeId) == 1) {
                this.numberMainDrivers = 1
            } else {
                if(parseInt(this.groupTypeId) == 1) { //ind
                    this.numberMainDrivers = 1                    
                } else { //fam
                    this.numberMainDrivers = 2
                }
            } 
        },
        showCover(id) {
            var groupType = this.getGroupType.find(g => g.id == id);
            var cover = groupType.description;

            this.innerLoadingHeight = this.$refs.innerForm.clientHeight;

            this.innerLoading = true;

            if(cover == 'Family') {
                setTimeout(function(){                    
                    this.family = true;
                    this.setDrivers();   
                    this.numberNamedDrivers = this.numberNamedDrivers > this.site.amtNamedDriverMax ? this.site.amtNamedDriverMax : this.numberNamedDrivers                
                    this.innerLoading = false;
                }.bind(this), 750)                
            }
            else {
                setTimeout(function(){                    
                    this.family = false;
                    this.setDrivers();                    
                    this.innerLoading = false;
                }.bind(this), 750)  
            }
                          
        },
        submitForm() {            
            //basic form validation            
            // use this.dateTimeNow() for todays datetime, will need to adjust policy api to allow time part
            //console.log(this.dateTimeNow());
            this.formErrorSummary = false;
            this.checkFormErrors(); 
            if(this.checkForm() && this.isNullOrWhitespace(this.departDateError)
                && this.isNullOrWhitespace(this.returnDateError)) {
                //get main drivers
                this.setDrivers();
                //if today use datetime now else use formatCSharpTime
                var q = {
                    tripTypeId: this.tripTypeId,
                    coverRegionId: this.coverRegionId,
                    coverStartDate: this.formatCSharpTime(this.coverStartDate),
                    coverEndDate: this.tripTypeId.toString() == "2" ? "" : this.formatCSharpTime(this.coverEndDate),
                    numberNamedDrivers: this.numberNamedDrivers,
                    driverAgeConfirm: this.driverAgeConfirm,
                    driverLicenseConfirm: this.driverLicenseConfirm,
                    groupTypeId: this.groupTypeId,
                    numberMainDrivers: this.numberMainDrivers
                }
                var quote = Object.assign({}, q);
                //console.log(quote);
                this.$store.dispatch('quote/postQuote', quote);
            }
        },
        restoreQuote() {
            if(this.quotation.cover.coverStartDate) {
                // console.log('restore')
                // console.log(this.quotation.cover)
                this.tripTypeId = this.originQuote.TripTypeId.toString();
                // console.log(this.tripTypeId)
                //console.log(this.quotation.cover.groupTypeId)
                this.groupTypeId = this.originQuote.GroupTypeId;
                this.formTitle = this.originQuote.TripTypeId == 1 ? 'Single Trip' : 'Annual multi-trip';
                this.single = (this.originQuote.TripTypeId == 1) ? true : false;
                this.multi = (this.originQuote.TripTypeId == 1) ? false : true;
                this.family = (this.originQuote.GroupTypeId == 2) ? true : false; 

                this.coverRegionId = this.originQuote.CoverRegionId.toString();
                this.coverStartDate = this.formatDisplayTime(this.originQuote.CoverStartDate);
                this.coverEndDate = this.formatDisplayTime(this.originQuote.CoverEndDate);
                this.numberMainDrivers = this.getMainDrivers.length; 
                this.numberNamedDrivers = this.getNamedDrivers.length;  
                this.showTrip(this.tripTypeId);               
            }
        },
        showInfoDialog(val) {
            this.infoId = val;
            this.$modal.show('info-dialog');
        },
        showTripInfoDialog() {
            this.$modal.show('trip-info-dialog');
        },
        resetDatePickers() {
            var start = this.coverStartDate;
            var end = this.coverEndDate;
            
            this.fpInstanceDepart.setDate(start, true);
            this.fpInstanceReturn.setDate(end, true);
        },
        checkFormErrors() {
            this.$nextTick(() => {
                this.formErrorSummary = this.formErrors().length > 0 
            });            
        }
    },
    computed: {
        ...mapGetters({
            site: "template/site",
            getDestinations: "template/getCoverRegion",
            getTripType: "template/getTripType",
            getGroupType: "template/getGroupType",
            quotation: "quote/getQuotation",
            getNamedDrivers: "customer/getNamedDrivers",
            getMainDrivers: "customer/getMainDrivers",
            isQuoteLoading: "quote/isQuoteLoading",
            originQuote: 'quote/getOriginQuote'
        })   
    },    
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    watch: {
        coverStartDate() {
            this.checkFormErrors(); 
            this.onDepartDateChange("", this.coverStartDate, "");
            this.onReturnDateChange("", this.coverEndDate, "");
        },
        coverEndDate() {            
            this.checkFormErrors(); 
            this.onReturnDateChange("", this.coverEndDate, "");
            this.onDepartDateChange("", this.coverStartDate, "");
        },            
        driverAgeConfirm() {
            this.checkFormErrors(); 
        },
        driverLicenseConfirm() {
            this.checkFormErrors();            
        },
    }
};
</script>

<style lang="scss" scoped>
.st-named-drivers::-webkit-outer-spin-button,
.st-named-drivers::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.st-form {
    display: block;
}

#travelling-container {
    display: inline-flex;
    align-items: center;
}
</style>