import service from '../index.js';
// let baseUrl = process.env.VUE_APP_API_BASE_URL
// let key = process.env.VUE_APP_APIKEY
// const service = axios.create({
//     baseURL: baseUrl,
//     headers: {
//         'Content-Type': 'application/json',
//         "Access-Control-Allow-Origin": "*",
//         "Ocp-Apim-Subscription-Key": key
//     }  
// });

async function getCustomer(id) {
    return await service.get(`/customer/getCustomer?guid=${id}`);
    
};

async function createCustomer(customer) {
    return await service.post(`/customer/createcustomer`, customer);
};

async function updateCustomer(customer) {
    return await service.post(`/customer/updatecustomer`, customer);
};

export default {
    getCustomer,
    createCustomer,
    updateCustomer
};