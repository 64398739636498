<template>
    <div class="" :id="'sidebar-' + tabId" role="tabpanel" :aria-labelledby="'sidebar-' + tabId + '-tab'">
        <h1>Policies</h1>
        <p>Take a look at your existing and previous policies that you have with us.</p>
        

        <tabbed-nav :tabs="tabs" :defaultTab="currentTab" v-on:newTab="switchTab" v-if="!showSingle">
            <component v-bind:is="'active'" v-bind="currentTabProps" @viewPolicy="showPolicy"></component> 
        </tabbed-nav>

        <single-policy @showPolicies="showPolicy" :policy="singlePolicy" v-else/>

    </div>
</template>

<script>
import Active from './tabs/active.vue';
import SinglePolicy from './tabs/singlePolicy.vue';
import tabbedNav from '../../tabbedNav.vue';

export default {
    name: 'account-policies',
    props: {
        policies: Array
    },
    components: {
        Active,
        SinglePolicy,
        tabbedNav
    },
    data: () => ({
        tabs: [ 
            { id: 'active', text: 'Active' },
            { id: 'inactive', text: 'Inactive' }
        ],
        currentTab: 'active',
        showSingle: false,
        singlePolicy: {}
    }),
    methods: {
        switchTab(t) {
            this.currentTab = t;
        },
        showPolicy(policy) {
            if(policy) {
                console.log(policy);
                this.singlePolicy = policy;
            }            
            this.showSingle = !this.showSingle;
        }
    },
    computed: {
        tabId() {
            return this.$options.name.split('-')[1];
        },
        currentTabComp: function() {
            return this.currentTab;
        },
        currentTabProps: function() {
            if(this.currentTab == 'active') {
                return { policies: this.policies.filter(p => p.active == true) };
            }
            if(this.currentTab == 'inactive') {
                return { policies: this.policies.filter(p => p.active == false) };
            }
        }
    }
}
</script>