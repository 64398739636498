<template>
    <!-- START Tabbed Nav -->
    <div class="tabbed-nav">
        <!-- START Tabs -->
        <div class="wrapper-tabbed-nav">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" v-for="(tab, idx) in tabs" :key="idx">
                    <a :class="['nav-link', {active: currentTab == tab.id}]" 
                        :id="tab.id + '-tab'" 
                        data-toggle="tab" 
                        :data-target="tab.id + '#'" 
                        role="tab" 
                        :aria-controls="tab.id" 
                        :aria-selected="currentTab == tab.id ? true : false"
                        @click="switchTab(tab.id)">{{ tab.text }}</a>
                </li>
            </ul>
        </div>
        <!-- END Tabs -->

        <!-- START Tab Content -->
        <div class="tab-content" id="myTabContent">
            <transition name="fadeoutin" mode="out-in">
                <slot></slot>
            </transition>                
        </div>
        <!-- END Tab Content -->

    </div>
    <!-- END Tabbed Nav -->
</template>

<script>

export default {
    name: 'tabbed-nav',
    props: {
        tabs: Array,
        defaultTab: String
    },
    data: () => ({
        currentTab: '',
    }),
    mounted() {
        this.currentTab = this.defaultTab ? this.defaultTab : tabs[0].id
    },
    methods: {
        switchTab(newId) {
            this.currentTab = newId;
            this.$emit('newTab', this.currentTab)
        }
    },
    computed: {
        currentTabComp: function() {
            return this.currentTab;
        }
    }
}
</script>