import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "./assets/css/style.min.css";

import "./assets/css/vue-transitions.scss";
import 'velocity-animate';

import VueMeta from 'vue-meta';
import taurusValidation from 'taurus_simple_vue_validation';
import "taurus_simple_vue_validation/src/Styles/styles.css";

import VModal from 'vue-js-modal/dist/index.nocss.js';
import 'vue-js-modal/dist/styles.css';
import "./assets/css/vue-modal-fix.scss";

import interceptorSetup from './api/interceptors.js';

Vue.use(VModal);
Vue.use(VueMeta);

Vue.use(taurusValidation, {
  feedback: { //sets feedback message on controls (the message is overrideable)
    valid: false, 
    invalid: true
  },
  control: { //sets input validation (control border color)
    valid: false, 
    invalid: false
  }
})

interceptorSetup(store);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
