<template>
    <div class="radio-toolbar">
        <template v-for="(d, i) in dataArr">
            <input type="radio" 
                   :id="getId(d.description)" 
                   :name="inputName" 
                   :value="d.id" 
                   :key="i" 
                   v-on:click="$emit('selectedItem', d.id)"
                   v-on:input="$emit('input', $event.target.value)" 
                   :checked="i == 0 ? 'checked' : ''">
            <label :key="'l' + i" :id="getId(d.description)" :for="getId(d.description)" tabindex="0" >{{d.description}}</label>
        </template>

        <a class="init-destination-dialog" @click.prevent="$emit('showTripInfo')" href="" v-if="showInfo">
            <i class="fas fa-question-circle"></i>
        </a>

        <!-- <input type="radio" id="radio-annual-multi-trip" name="policyType" value="Annual multi-trip" v-model="policyType" v-on:click="showTrip('Annual multi-trip')">
        <label for="radio-annual-multi-trip" tabindex="0">Annual multi-trip</label> -->
    </div>
</template>

<script>
export default {
    name: 'radioToolbar',
    props: {
        dataArr: Array,
        inputName: String,
        showInfo: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getId(val) {
            //console.log(val.trim().replace(' ', '-').toLowerCase());
            if(val) {
                var id = 'radio-'
                return id + val.trim().replace(' ', '-').toLowerCase();
            } else {
                return '';
            }            
        }
    }
}
</script>