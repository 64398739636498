<template>
    <div class="flex-wrapper wrapper-change-password">
        <div class="flex-container">
            <div class="flex-item">
                <div class="logo-flex">
                    <router-link to="/home" title="Return to the home page">
                        <img :src="require(`../assets/svg/${site.brand}-logo.svg`)" alt="SOI Logo" />
                    </router-link>                        
                    <span class="pipe"></span>
                    <p>Car Hire Excess Insurance</p>
                </div>
                <div class="row no-gutters">
                    <div class="col-lg-12 col-sm-12">
                        <form id="change-password">
                            <h2>Change password</h2>
                            <p>Please enter the new password you would like to use in the fields below and we will apply your new password to your account.</p>

                            <fieldset class="common">
                                <label for="password">New password</label>
                                <input type="password" id="password" size="30" v-model="password" v-taurus-validator="'required|min:6'" fieldlabel="Password" />
                                <div name="password"></div>
                            </fieldset>

                            <fieldset class="common">
                                <label for="password-confirm">Re-enter your new password</label>
                                <input type="password" id="password-confirm" size="30" v-model="passwordConfirm" v-taurus-validator="'required|same_as:password'" fieldlabel="Password Confirm" />
                                <div name="password-confirm"></div>
                                <div class="taurus-invalid" v-if="isPasswordResetError">{{getPasswordResetError}}</div>
                            </fieldset>
                            <div v-if="loading" class="loading-div">
                                <i class="fad fa-spinner-third fa-spin"></i>
                            </div>
                            
                            <a class="button-primary button-large button-full action-change-password" @click="submit" v-else>Change password</a>                                
                            
                        </form>
                        
                        <!-- <button @click="test">test</button> -->
                    </div>
                </div>
            </div>
        </div>
        <modal id="dialog-password-changed" name="dialog-password-changed" classes="dialog-splash-screen zoom-anim-dialog" :clickToClose="false" height="auto" :adaptive="true">
            <div class="body">
                <p class="heading">You password has been changed successfully!</p>
                <p class="message">Please log in to the system again to access your account.</p>
                <div class="fa-4x">
                    <i class="fad fa-spinner-third fa-spin"></i>
                </div>
                <img :src="getSiteUrl + '/assets/svg/icon-' + site.brand + '-graphic.svg'" alt="SOI graphic logo" class="icon-soi-graphic" />
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'change-password',
    data() {
        return {
            title: 'Change password',
            pageDescription: 'Enter the new password you would like to use in the fields below and we will apply your new password to your account. Then you are good to go!',
            code: null,
            email: null,
            password: null,
            passwordConfirm: null,
            loading: false
        }
    },
    mounted() {
        this.code = this.$route.query.code;
        this.email = this.$route.query.email;
    },
    methods: {
        test() {
            // console.log(this)
            this.$modal.show('dialog-password-changed');
        },
        async submit() {
            this.loading = true;
            if(this.checkForm()) {
                var data = {
                    email: this.email,
                    code: this.code,
                    password: this.password
                }
                var payload = Object.assign({}, data);
                await this.$store.dispatch('account/resetPassword', payload);
            }
            this.loading = false;
        }
    },
    metaInfo() {
        return {             
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            site: 'template/site',
            getSiteUrl: 'template/getSiteURL',
            isPasswordResetError: 'account/isPasswordResetError',
            getPasswordResetError: 'account/getPasswordResetError'
        })
    }
}
</script>

<style lang="scss" scoped>
.loading-div {
    text-align: center;
    & i {
        font-size: 40px;
    }
}
</style>