import router from '../../router/index.js';
import moment from 'moment';
import Vue from 'vue';
import quoteService from '../../api/services/quoteService.js';

const quoteInitialState = () => {
    return {
        quotation: {
            cost: 0,
            maxDuration: 0,
            maxNumTrips: 0,
            excessReimbursement: 0,
            misfuelCover: 0,
            personalPossessions: 0,
            singlePairSetItem: 0,
            tobaccoAlcoholFragrances: 0,
            rentalKeyCoverDays: 0,
            theftLossKeys: 0,
            lockOut: 0,
            breakdownRecovery: 0,
            cover: {
                policyType: '',
                coverFor: '',
                destination: '',
                startDate: '',
                endDate: '',
                mainDrivers: 2,
                namedDrivers: 0
            }
        },
        product: {
            maxDuration: 0,
            maxNumTrips: 0,
            excessReimbursement: 0,
            misfuelCover: 0,
            personalPossessions: 0,
            singlePairSetItem: 0,
            tobaccoAlcoholFragrances: 0,
            rentalKeyCoverDays: 0,
            theftLossKeys: 0,
            lockOut: 0,
            breakdownRecovery: 0
        },
        originQuote: null,
        loading: false        
    }
};

const state = quoteInitialState();

const getters = {
    getQuotation: state => {
        return state.quotation;
    },
    getPolicyType: state => {
        return state.quotation.cover.policyType;
    },
    getPolicyCoverDays: state => {
        var start = moment(state.quotation.cover.coverStartDate, 'YYYY/MM/DD')
        var end = moment(state.quotation.cover.coverEndDate, 'YYYY/MM/DD')

        return end.diff(start, 'days', true)
    },
    getOriginQuote: state => {
        return state.originQuote;
    },
    isQuoteLoading: state => state.loading
};
const mutations = {
    CREATE_QUOTATION(state, q) {
        state.quotation = q.product;
        Vue.set(state.quotation, 'cost', q.cost);
        Vue.set(state.quotation, 'cover', q.cover);
    },
    COMMIT_ORIGIN_QUOTE(state, q) {
        state.originQuote = q;
    },
    COMMIT_LOADING(state, l) {
        state.loading = l
    },
    RESET_STATE(state) {
        Object.assign(state, quoteInitialState())
    }
};
const actions = {
    async postQuote({ dispatch, commit, rootState }, quote) {       
        var _quote = {};
        var site = rootState.template.site;
        
        commit('COMMIT_LOADING', true);

        _quote = new Quote(0, quote.tripTypeId, quote.coverRegionId, quote.coverStartDate, 
            quote.coverEndDate, quote.numberNamedDrivers, quote.groupTypeId, quote.numberMainDrivers, 
            site.brandId, site.saleCountryId, site.channelId, site.leadTypeId, site.rateSetId, 
            site.productId, site.currencyId);
            delete _quote.Id; //remove Id from object only used when updating/returning (saves creating 2 classes)

        //console.log(_quote);
        commit('COMMIT_ORIGIN_QUOTE', _quote);
        const response = await quoteService.getQuoted(_quote).catch(function (error) {
            commit('COMMIT_LOADING', false);
            if (error.response) {
            //   console.log(error.response.data);                            
            } 
        });

        if(response && response.status === 200) {
            //console.log(response);
            commit('CREATE_QUOTATION', response.data);
            dispatch('customer/setNamedDrivers', _quote.NumberNamedDrivers, { root: true });
            dispatch('customer/setMainDrivers', _quote.NumberMainDrivers, { root: true });
            commit('COMMIT_LOADING', false);
            router.push({ name: 'YourQuote' });
        }
    },
    async getQuote({ dispatch, commit, rootState}, quote) {
        
        commit('COMMIT_LOADING', true);
        
        const response = await quoteService.getQuoted(quote).catch(function (error) {
            if (error.response) {
            //   console.log(error.response.data);    
              commit('COMMIT_LOADING', false);          
            } 
        });

        if(response && response.status === 200) {
            //console.log(response);
            commit('CREATE_QUOTATION', response.data);
            dispatch('customer/setNamedDrivers', quote.NumberNamedDrivers, { root: true });
            dispatch('customer/setMainDrivers', quote.NumberMainDrivers, { root: true });
            commit('COMMIT_LOADING', false);
        }
    },
    resetQuoteState({ commit }) {
        commit('RESET_STATE');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

class Quote {
    constructor(Id, PolicyType, Destination, StartDate,
        EndDate, NamedDrivers, CoverFor, MainDrivers, BrandId, SaleCountryId, 
        ChannelId, LeadTypeId, RateSetId, ProductId, CurrencyId) {
            this.Id = parseInt(Id),
            this.TripTypeId = parseInt(PolicyType),
            this.CoverRegionId = parseInt(Destination),
            this.CoverStartDate = StartDate,
            this.CoverEndDate = EndDate,
            this.NumberNamedDrivers = NamedDrivers,
            this.GroupTypeId = parseInt(CoverFor),
            this.NumberMainDrivers = MainDrivers,
            
            this.BrandId = BrandId,
            this.SaleCountryId = SaleCountryId
            this.ChannelId = ChannelId
            this.LeadTypeId = LeadTypeId
            this.RateSetId = RateSetId
            this.ProductId = ProductId
            this.CurrencyId = CurrencyId
    }
};
  