import customerService from "../../api/services/customerService";

const defaultState = () => {
    return {
        customer: {
            id: '',
            title: 'Mr',
            firstName: '',
            lastName: '',
            emailAddress: '',
            telephone: ''
        },
        address: {
            addressLine1: '',
            addressLine2: '',
            town: '',
            county: '',
            postcode: ''
        },
        mainDrivers: [],
        namedDrivers: [],
        driver: {
            title: '',
            firstName: '',
            lastName: '',
            dateOfBirth: ''
        },
        isLoading: false,
        isUpdateError: false
    }    
};

// base state
const state = defaultState();

// data getters (reactive)
const getters = {
    getCustomer: state => {
        return state.customer;
    },
    getAddress: state => {
        return state.address;
    },
    getNamedDrivers: state => {
        return state.namedDrivers;
    },
    getMainDrivers: state => {
        return state.mainDrivers;
    },
    isCustomer: state => {
        if(state.customer.firstName) {
            return true;
        } else {
            return false;
        }        
    },
    getCustomerFullName: state => {
        return state.customer.firstName + ' ' + state.customer.lastName;
    },
    getCustomerLoading: state => state.isLoading,
    getUpdateError: state => state.isUpdateError
};
// commit data changes
const mutations = {
    SET_CUSTOMER(state, c) {
        state.customer = c;
    },
    SET_ADDRESS(state, a) {
        state.address = a;
    },
    ADD_NAMED_DRIVER(state, payload) {
        state.namedDrivers.splice(payload.index, 1, payload.driver);
    },
    SET_NAMED_DRIVERS(state, d) {
        state.namedDrivers = new Array(parseInt(d));
    },
    ADD_MAIN_DRIVER(state, payload) {
        state.mainDrivers.splice(payload.index, 1, payload.driver);
    },
    SET_MAIN_DRIVERS(state, d) {
        state.mainDrivers = new Array(parseInt(d));
    },
    SET_CUSTOMER_LOADING(state, l) {
        state.isLoading = l;
    },
    SET_IS_UPDATE_ERROR(state, e) {
        state.isUpdateError = e;
    },
    RESET_STATE(state) {
        Object.assign(state, defaultState())
    }
};
// actions commit mutations, can contain arbitrary asynchronous operations
const actions = {
    resetCustomerState({ commit }) {
        commit('RESET_STATE');
    },
    async submitDetails({ dispatch, commit, rootState }, details) {
        var cust = {
            userUUID: rootState.account.userGUID,
            title: details.title,
            firstName: details.firstName,
            lastName: details.lastName,
            dateOfBirth: details.dateOfBirth,
            emailAddress: details.emailAddress,
            telephoneNumber: details.telephone,
            addressLine1: details.addressLine1,
            addressLine2: details.addressLine2,
            townCity: details.town,
            county: details.county,
            postcode: details.postcode,
            countryId: 1,
            emailMarketingOptIn: true,
            postMarketingOptIn: false,
            smsMarketingOptIn: false,
            phoneMarketingOptIn: false,
            emailValidated: false            
        }
        var _customer = Object.assign({}, cust);
        // console.log('pre-send: ', _customer);

        var response = await customerService.createCustomer(_customer).catch(function (error) {
            if (error.response) {
            //   console.log(error.response.data);              
            } 
        });

        if(response && response.status === 200) {
            var dbCustomer = response.data;
            // console.log('create cust db: ', dbCustomer);
            commit('SET_CUSTOMER', dbCustomer);
            commit('SET_ADDRESS', dbCustomer.addresses[0]);
            //dispatch('account/updateUsersCustomerGuid', {emailAddress: dbCustomer.emailAddress, customerUID: dbCustomer.userUUID}, {root:true})
        }
    },
    async updateDetails({ dispatch, commit, rootState }, details) {
        commit('SET_CUSTOMER_LOADING', true);
        commit('SET_IS_UPDATE_ERROR', false); 
        var cust = {
            title: details.title,
            firstName: details.firstName,
            lastName: details.lastName,
            dateOfBirth: details.dateOfBirth,
            emailAddress: details.emailAddress,
            telephoneNumber: details.telephone,
            addressLine1: details.addressLine1,
            addressLine2: details.addressLine2,
            townCity: details.town,
            county: details.county,
            postcode: details.postcode,
            countryId: 1,
            emailMarketingOptIn: true,
            postMarketingOptIn: false,
            smsMarketingOptIn: false,
            phoneMarketingOptIn: false,
            emailValidated: false,
            uid: details.uid            
        }

        var _customer = Object.assign({}, cust);
        // console.log('pre-send: ', _customer);
        //console.log(cust);
        var response = await customerService.updateCustomer(_customer).catch(function (error) {
            if (error.response) {
            //   console.log('err: ', error.response.data);
              commit('SET_CUSTOMER_LOADING', false);  
              commit('SET_IS_UPDATE_ERROR', true);            
            } 
        });

        if(response && response.status === 200) {
            var dbCustomer = response.data;
            commit('SET_CUSTOMER', dbCustomer);
            commit('SET_ADDRESS', dbCustomer.addresses[0]);
            //dispatch('account/updateUsersCustomerGuid', {emailAddress: dbCustomer.emailAddress, customerUID: dbCustomer.userUUID}, {root:true})
            commit('SET_CUSTOMER_LOADING', false); 
        }
    },
    addNamedDriver({ commit, state }, payload) {     
        commit('ADD_NAMED_DRIVER', payload)
    },
    setNamedDrivers({ commit }, drivers) {
        commit('SET_NAMED_DRIVERS', drivers)
    },
    addMainDriver({ commit }, payload) {
        commit('ADD_MAIN_DRIVER', payload)
    },
    setMainDrivers({ commit }, drivers) {
        commit('SET_MAIN_DRIVERS', drivers)
    },
    async getCustomer({commit}, payload) {
        // console.log(payload)
        const response = await customerService.getCustomer(payload).catch(function (error) {
            if (error.response) {
            //   console.log(error.response.data);              
            } 
        });
        //console.log(response);
        if(response && response.status === 200) {
            commit('SET_CUSTOMER', response.data);
            commit("SET_ADDRESS", response.data.addresses[0])
        }
    }
};
// export all objects, import into main store index.js
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

class Customer {
    constructor(title, firstName, lastName, emailAddress, telephone) {
        this.Title = title,
        this.FirstName = firstName,
        this.LastName = lastName,
        this.EmailAddress = emailAddress,
        this.Telephone = telephone;
    }
}

class Address {
    constructor(addressLine1, addressLine2, town, county, postcode) {
        this.AddressLine1 = addressLine1,
        this.AddressLine2 = addressLine2,
        this.Town = town,
        this.County = county,
        this.Postcode = postcode;
    }
}

class Driver {
    constructor(title, firstName, lastName, dateOfBirth, isMainDriver) {
        this.Title = title,
        this.FirstName = firstName,
        this.LastName = lastName,
        this.DateOfBirth = dateOfBirth,
        this.IsMainDriver = isMainDriver
    }
}