<template>
    <aside class="sidebar">
        <h4>Useful links</h4>
        <ul>
            <li><a :href="`${site.cdnUrl}/${site.ipidDocFile}`" target="_blank" download>Insurance product information document</a></li>
            <li><a :href="`${site.cdnUrl}/${site.tobaDocFile}`" target="_blank" download>Initial disclosure document</a></li>
            <li><a :href="`${site.cdnUrl}/${site.policyWording}`" target="_blank" download>Policy wording document</a></li>
            <li><router-link to="/privacy-policy">Privacy policy</router-link></li>
        </ul>
    </aside>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            site: 'template/site'
        })        
    }
}
</script>