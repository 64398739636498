<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/legal">Legal</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <h2>Legal</h2>
                        <p class="subheading">Providing information in accordance with Gibraltar law and the financial services commission (FSC) regulations.</p>
                        <div class="main-content">
                            <div class="internal-anchor-links">
                                <h3 id="link1">Company information</h3>
                                <p>{{ site.legal_company_info }}</p>

                                <h3 id="link2">Registered Office</h3>
                                <p>Mansion House,<br>
                                    143 Main Street,<br>
                                    Gibraltar,<br>
                                    GX11 1AA</p>

                                <h3 id="link3">Head office address</h3>
                                <p>Suite 2209 Eurotowers,<br>
                                    Europort Road,<br>
                                    Gibraltar,<br>
                                    GX11 1AA</p>

                                <h3 id="link4">Statement</h3>
                                <p>{{ site.legal_statement }} These details can be checked on the Financial Services Register by visiting <a href="https://www.fca.org.uk/" class="link">www.fca.org.uk</a> or by contacting the Financial Conduct Authority on <a class="link" href="tel:08001116768">0800 111 6768</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <aside class="sidebar" id="sidebar">
                        <h4>Skip to section</h4>
                        <ul>
                            <li><a href="#link1">Company information</a></li>
                            <li><a href="#link2">Registered Office</a></li>
                            <li><a href="#link3">Head office address</a></li>
                            <li><a href="#link4">Statement</a></li>
                        </ul>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
export default {
  data() {
    return {
      title: "Important Information",
      pageDescription: 'Providing legal information in accordance with Gibraltar law and the financial services commission (FSC) regulations.'
    };
  },
  components: {
    heroInner
  },
  metaInfo() {
    return { 
        title: this.title,
        meta: [
          { name: 'description', content: this.pageDescription },
          { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
        ]
    }
  },
  computed: {
    ...mapGetters({
      site: "template/site"
    })
  }
};
</script>