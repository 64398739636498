<template>
    <footer>
	<div class="container">
		<div class="row">
			<div class="col-md-3 col-sm-12">
				<p class="heading">Navigation</p>
				<ul>
					<li><router-link to="/home">Home</router-link></li>
					<li><router-link to="/get-a-quote">Get a quote</router-link></li>
					<!-- <li><router-link to="/useful-information">Useful information</router-link></li> -->
					<li><router-link to="/faq">Frequently Asked Questions</router-link></li>
					<li><router-link to="/contact-us">Contact us</router-link></li>
				</ul>
			</div>
			<div class="col-md-3 col-sm-12">
				<p class="heading">Car hire excess</p>
				<ul>
					<li><router-link :to="{ path: '/home', hash: '#what-is-car-hire-excess-insurance' }">What is car hire excess insurance?</router-link></li>
					<li><router-link :to="{ path: '/home', hash: '#who-is-eligible' }">Who is eligible?</router-link></li>
					<li><router-link :to="{ path: '/home', hash: '#what-is-included' }">What is included?</router-link></li>
				</ul>
			</div>
			<div class="col-md-3 col-sm-12">
				<p class="heading">Miscellaneous</p>
				<ul>
					<li><router-link to="/privacy-policy">Privacy policy</router-link></li>
					<li><router-link to="/accessibility">Accessibility</router-link></li>
					<li><router-link to="/sitemap">Sitemap</router-link></li>
					<li><router-link to="/legal">Legal</router-link></li>
				</ul>
			</div>
			<div class="col-md-3 col-sm-12">
				<p class="heading">Find out more</p>
				<ul>
					<li><a href="https://www.holidayextras.com/about-us/about-us.html">About us</a></li>
					<li><a href="https://www.holidayextras.com/careers.html">Careers</a></li>
					<li><a href="https://www.linkedin.com/company/holiday-extras/">LinkedIn</a></li>
					<li><a href="https://uk.trustpilot.com/review/holidayextras.com">Trustpilot</a></li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<p class="disclaimer" v-html="site.regulatoryStatement"></p>
			</div>
		</div>
	</div>
	<div class="copyright">
		<div class="container">
			&copy; {{ new Date().getFullYear() }} {{ site.copyright }}
		</div>
	</div>
</footer>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            site: 'template/site'
        })
    }
}
</script>