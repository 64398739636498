<template>
    <div class="" id="your-addresses" role="tabpanel" aria-labelledby="your-addresses-tab">
        <form>
            <div class="row">
                <div class="col-md-6">
                    <fieldset class="address">
                        <label for="home-address">Home Address</label>
                        <input type="text" name="home-address" id="home-address" value="24 Viaduct Road, Chelmsford, CM1 1TS" />
                    </fieldset>
                    <div class="map" id="map-home-address"></div>
                </div>
                <div class="col-md-6">
                    <fieldset class="address">
                        <label for="billing-address">Billing Address</label>
                        <input type="text" name="billing-address" id="billing-address" value="12 Station Rd, Great Dunmow, Felsted, Dunmow CM6 3HB" />
                    </fieldset>
                    <div class="map" id="map-billing-address"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="hr"></div>
                    <a href="#" class="button-primary">Saves Changes</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'your-addresses',
    props: {
        addresses: Array
    }
}
</script>