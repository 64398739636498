import service from '../index.js';


async function getQuoted(quote) {
    return await service.post(`/quote/getquote`, quote);
};

async function createQuote(quote) {
    return await service.post(`/quote/createquote`, quote);
};

async function purchaseQuote(payload) {
    return await service.post(`quote/PurchaseQuote`, payload);
};

async function getQuoteById(payload) {
    return await service.get(`quote/GetPolicy?id=${payload}`);
};

async function getPolicyPdf(payload) {
    return await service.get(`quote/getPdf/${payload}`)
}

export default {
    getQuoted,
    getQuoteById,
    createQuote,
    purchaseQuote,
    getPolicyPdf
};