<template>
    <div class="hero-inner">
        <div class="container">
            <h1>{{ title }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>