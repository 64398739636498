import accountAPI from '../index.js';

// let baseUrl = process.env.VUE_APP_API_BASE_URL
// let key = process.env.VUE_APP_APIKEY
// const accountAPI = axios.create({
//     baseURL: baseUrl,
//     headers: {"Ocp-Apim-Subscription-Key": key},
//     // withCredentials: true, 
//     // credentials: 'include'
// });



async function refresh() {
    return await accountAPI.post('/account/refresh');
};

async function login(payload) {
    return await accountAPI.post("/account/signin", payload);
};

async function logout(payload) {
    return await accountAPI.post("/account/revoke", payload).catch(err => console.log(err));
};

async function createAccount(payload) {
    return await accountAPI.post('/account/registeruser', payload);
};

async function resetUserPassword(payload) {
    return await accountAPI.post(`/account/resetuserpassword?email=${payload}`);
};

async function resetPassword(payload) {
    return await accountAPI.post('/account/resetPassword', payload);
}

async function updateUsersCustomerUID(payload) {
    return await accountAPI.post('/account/UpdateUsersCustomerUID', payload);
};

async function isUser(payload) {
    return await accountAPI.get(`/account/isuser?email=${payload}`);
}

export default {
    refresh,
    login,
    logout,
    createAccount,
    resetUserPassword,
    updateUsersCustomerUID,
    isUser,
    resetPassword
};