import axios from 'axios';
import {getSiteJson} from '../../api/services/siteService';
import hxData from '../json/hx.json';
import soiData from '../json/soi.json';

const state = process.env.VUE_APP_BRAND_ID == '1' ? soiData : hxData;

const getters = {
  site: state => {
    return state.site;
  },
  getBrandId: state => {
    return state.site.brandId ? state.site.brandId : 61
  },
  getBrand: state => {
    return state.site.brand ? state.site.brand : 'holiday-extras'  
  },
  getBrandName: state => {
    return state.site.brandName ? state.site.brandName : 'Holiday Extras'  
  },
  getAccountSection: state => {
    return state.site.hasAccountSection;
  },
  getTripType: state => {
    return state.tripType;
  },
  getCoverRegion: state => {
    return state.coverRegion;
  },
  getGroupType: state => {
    return state.groupType;
  },
  getSiteURL: state => {
    if (process.env.VUE_APP_MODE == 'production') {
      return state.site.url;
    } else if (process.env.VUE_APP_MODE == 'staging'){
      return state.site.stagingUrl;
    } else {
      return state.site.localUrl;
    }
  },
  getTenant: state => {
    return state.site.tenantId;
  }
};

const mutations = {
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
