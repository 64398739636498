<template>
<div class="navbar">
	<div class="container">
		<nav>
			<ul>
                <li v-for="(link, idx) in site.navLinks" :key="idx">
                    <div v-if="link.dropdown">
                        <!-- <a class="parent" href="#">{{ link.text }} <i class="fal fa-angle-down"></i></a> -->
                        <ul class="nav-dropdown">
                            <li v-for="(sublink, idx) in link.dropdown" :key="idx"><a :href="getSiteUrl + sublink.url ">{{ sublink.text }}</a></li>                            
                        </ul>
                    </div>
                    <router-link v-else :to="link.url">{{ link.text }}</router-link>
                </li>
                <!-- v-if needs to work on user token -->
                <li v-if="isAuthenticated"><router-link to="/my-account">My account</router-link></li>			
			</ul>
		</nav>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'navbar',
    data () {
        return {
            page: {
                url: '/'
            }    
        }
  },
  computed: {
      ...mapGetters({
          site: 'template/site',
          getSiteUrl: 'template/getSiteURL',
          isAuthenticated: 'account/isAuthenticated',
      })
  }

}
</script>

