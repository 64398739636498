import service from '../index.js';
// let baseUrl = process.env.VUE_APP_API_BASE_URL
// let key = process.env.VUE_APP_APIKEY
// const service = axios.create({
//     baseURL: baseUrl,
//     headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Ocp-Apim-Subscription-Key": key
//     }, 
//     // withCredentials: true, 
//     // credentials: 'include'
// });

const merchantId = 'HXCarhireXSInsPremGBP';

export const braintreeService = {
  getToken,
  getClientToken
}


async function getToken () {
    var response = await service.get('/payment/getClientToken').catch(err => console.log(err));
    //console.log(response);
    return response.data;
}

async function getClientToken (customer) {
    var url = `/payment/getClientToken/merchant/${merchantId}/customer/${customer}`
    //console.log(url)
    var response = await service.get(url)
        .catch(err => console.log(err));
    return response;
}