<template>
    <div class="" :id="'sidebar-' + tabId" role="tabpanel" :aria-labelledby="'sidebar-' + tabId + '-tab'">
        <h1>Welcome back {{userName}}</h1>
    </div>
</template>

<script>
export default {
    name: 'account-welcome',
    props: {
        userName: String
    },
    computed: {
        tabId() {
            return this.$options.name.split('-')[1];
        }
    }
}
</script>