<template>
    <form id="return-customer">
        <p>Please enter your username and password to access the car hire excess insurance system.</p>

        <fieldset class="common">
            <label for="customer-email-address">Email address</label>
            <input type="email" id="customer-email-address" size="30" v-model="custEmailAddress" v-taurus-validator="'required'" fieldlabel="Email address"/>
            <div name="customer-email-address"></div>
        </fieldset>

        <fieldset class="common">
            <label for="password">Password</label>
            <input type="password" id="password" size="30" v-model="password" v-taurus-validator="'required'" fieldlabel="Password"/>
            <div name="password"></div>
            <div class="taurus-invalid">{{getLoginError}}</div>    
        </fieldset>

        <loadingButton buttonLabel="Log in" :isLoading="isAccountLoading" @onClick="logIn"/> 
        
        <a @click.prevent="resetPassword" href="" class="link forgotten-password">Forgotten password?</a>                                                                              
    </form>
</template>

<script>
import { mapGetters } from 'vuex';

import loadingButton from '../loadingButton.vue'

export default {
    name: 'returnCustomerForm',
    data: () => ({
        custEmailAddress: '',
        password: ''
    }),
    components: {
        loadingButton
    },
    methods: {
        async logIn() {
            if(this.checkForm()) {
                var payload = {                    
                    email: this.custEmailAddress,
                    password: this.password
                }
                await this.$store.dispatch('account/login', payload);
            }
        },
        resetPassword() {
            this.$store.commit('account/RESET_PASSWORD_RESET_ERROR');
            this.$modal.show('forgotten-password-dialog');
        }
    },
    computed: {
        ...mapGetters({
            getLoginError: 'account/getLoginError',
            isAccountLoading: 'account/isAccountLoading'
        })
    }
}
</script>