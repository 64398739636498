<template>
    <transition name="fade" mode="in-out">
        <!-- START Loading Transition Screen -->
        <div class="loading-transition-screen" v-show="loading" ref="loading" :style="{'height': height + 'px', 'display': 'block'}">
            <div class="fa-4x">
                <i class="fad fa-spinner-third fa-spin"></i>
            </div>
            <h4>Loading...</h4>
        </div>
        <!-- END Loading Transition Screen -->
    </transition>    
</template>

<script>
export default {
    props: {
        loading: Boolean,
        height: Number
    }
}
</script>
