<template>
    <picture :class="classes">
        <source :type="'image/' + s.imgType" 
                :media="s.media" 
                :srcset="getImage(s)" 
                v-for="(s, idx) in settings" :key="idx" />
        <img :alt="altText" :src="require(`../assets/img/${imgName}@${delimeters[0]}.png`)" />
    </picture>    
</template>

<script>
export default {
    name: '',
    props: {
        imgTypes: Array, //['webp','png']
        media: String, //'['(min-width: 480px)','(max-width: 480px)']
        delimeters: Array, //['1x', '2x']
        imgName: String, //'vw-golf-holiday-extras'
        classes: String, //'car'
        altText: String, //'A photograph of a white Volskwagen Golf car.',
        path: String, //'../assets/img/'
        settings: Array
    },
    data: () => ({
        testArray: [
            {imgType: 'webp', media: '(min-width: 480px)'},
            {imgType: 'webp', media: '(max-width: 480px)'},
            {imgType: 'png', media: '(min-width: 480px)'},
            {imgType: 'png', media: '(max-width: 480px)'}
        ],
        testArray1: [
            {imgType: 'webp', media: '(min-width: 480px)'},
            {imgType: 'png', media: '(min-width: 480px)'},
        ]
    }),
    methods: {
        getImage(imgext) {
            var app = this;            
            var srcString = '';
            app.delimeters.forEach(function(e) {
                var pic = require(`../assets/img/${app.imgName}@${e}.${imgext.imgType}`);
                if(srcString == '') {
                    srcString = `${pic} ${e}`;
                }
                else {
                    srcString += `,${pic} ${e}`;
                }                
            });
            
            return srcString;
        },
    }
}
</script>