//import axios from "axios";
import router from '../../router/index.js';
import accountService from '../../api/services/accountService.js'; 
var refreshTokenTimeout;

const accountInitialState = () => {
    return {
        refreshStatus: "",
        user: {
            userName: "",
            customerId: null,
            token: null,
            roles: null
        },
        loginError: '',
        createError: null,
        userGUID: null,
        userEmailExists: false,
        passwordResetError: '',
        isPasswordResetError: false,
        loading: false,
        resetLoading: false
    };
};

const state = accountInitialState();

const getters = {
    isAuthenticated: state => !!state.user.token,
    getUser: state => state.user,
    getUsername: state => state.user.userName,
    getCustomerId: state => state.user.customerId,
    getLoginError: state => state.loginError,
    isExistingEmail: state => state.userEmailExists,
    isPasswordResetError: state => state.isPasswordResetError,
    getPasswordResetError: state => state.passwordResetError,
    getCreateError: state => state.createError,
    isAccountLoading: state => state.loading,
    isResetLoading: state => state.resetLoading
};

const mutations = {    
    RESET_STATE(state) {
        Object.assign(state, accountInitialState())
    },
    SET_REFRESH_STATUS(state, data) {
        state.refreshStatus = data;
    },
    SET_USER(state, payload) {
        state.user = payload
    },
    RESET_USER(state) {
        state.user = {
            userName: "",
            customerId: null,
            token: null,
            roles: null
        };
    },
    SET_LOGIN_ERROR(state, payload) {
        state.loginError = payload;
    },
    RESET_LOGIN_ERROR(state) {
        state.loginError = '';
    },
    SET_CREATE_ERROR(state, payload) {
        state.createError = payload;
    },
    RESET_CREATE_ERROR(state) {
        state.createError = null;
    },
    SET_USER_GUID(state, payload) {
        state.userGUID = payload;
    },
    RESET_USER_EXIST(state) {
        state.userEmailExists = false;
    },
    SET_USER_EXIST(state, payload) {
        state.userEmailExists = payload;
    },
    SET_PASSWORD_RESET_ERROR(state, payload) {
        state.passwordResetError = payload
    },
    RESET_PASSWORD_RESET_ERROR(state) {
        state.passwordResetError = '';
    },
    SET_PASSWORD_RESET_STATUS(state, payload) {
        state.isPasswordResetError = payload
    },
    RESET_PASSWORD_RESET_STATUS(state) {
        state.isPasswordResetError = false;
    },
    COMMIT_LOADING(state, l) {
        state.loading = l
    },
    COMMIT_RESET_LOADING(state, l) {
        state.resetLoading = l
    }
};

const actions = {
    resetAccountState({ commit }) {
        commit('RESET_STATE');
    },
    async login({ dispatch, commit }, payload) {
        commit('COMMIT_LOADING', true);
        commit('RESET_LOGIN_ERROR')        
        const response = await accountService.login(payload).catch(function (error) {
            commit('COMMIT_LOADING', false);
            if (error.response) {
            //   console.log(error.response.data);
              commit('SET_LOGIN_ERROR', error.response.data);              
            } 
          });
        //console.log(response);
        if(response && response.status === 200) {          
            const token = response.data.token;
            // console.log(response.data);            
            // console.log("accountstore login token: ", token);

            var user = {
                userName: response.data.username,
                customerId: response.data.customerUID,
                token: response.data.token,
                roles: response.data.roles
            };
            commit('SET_USER', user);
            dispatch('customer/getCustomer', response.data.customerUID,{root:true})
            //start refresh timer
            //dispatch('startRefreshTimer');
            commit('COMMIT_LOADING', false);
        }
    },
    async refreshToken({ dispatch, commit, state }, payload) {
        // console.log('async refreshToken');
        // console.log(payload)

        const response = await accountService.refresh().catch(err => {
            if (err.response) {
                if(err.response.status == 401) {
                    console.log('Unauthorized');
                } else {
                    console.log('error response: ', err.response) 
                }                
            } else if (err.request) {
                console.log('error request: ', err.request) 
            } else {
                console.log('error: ', err)              
            }        
        });
        
        //  console.log(response);

        if(!response) { //api returns null if user cannot be found by token or token not active
            console.log("problem with user");
        }

        if(response && response.status === 200) {
            const token = response.data.token
            // console.log("accountstore refresh token: ", token);
            var user = {
                userName: response.data.username,
                customerId: response.data.customerId,
                token: response.data.token,
                roles: response.data.roles
            };
            commit('SET_USER', user);
            //start refresh timer
            dispatch('startRefreshTimer');
        }
    },
    async logout({ dispatch, commit, state }) { 
        // var payload = {token: state.user.token}
        // console.log(payload)
        // const response = await accountService.logout(payload).catch(function (error) {
        //     if (error.response) {
        //       console.log(error.response.data);              
        //     } 
        // });

        // if(response && response.status === 200) {
        //     dispatch('stopRefreshTimer');
        //     commit('RESET_USER');
        //     if(router.history.current.name !== 'Home') {
        //         router.push({ name: 'Home' });
        //     }            
        // }
        dispatch('stopRefreshTimer');
        commit('RESET_USER');
        if(router.history.current.name !== 'Home') {
            router.push({ name: 'Home' });
            router.go();
        } else {
            router.go();
        }      
    },
    async resetUserPassword({ dispatch, commit }, payload) {
        commit('COMMIT_RESET_LOADING', true);
        commit('RESET_PASSWORD_RESET_ERROR');
        const response = await accountService.resetUserPassword(payload).catch(function (error) {
            commit('COMMIT_RESET_LOADING', false);
            if (error.response) {
              commit('SET_PASSWORD_RESET_ERROR', error.response.data);
              commit('SET_PASSWORD_RESET_STATUS', true);
            } 
          });
        if(response && response.status === 200) {
            commit('SET_PASSWORD_RESET_STATUS', false);
            commit('COMMIT_RESET_LOADING', false);
        }
    },
    async resetPassword({ dispatch, commit }, payload) {
        commit('COMMIT_LOADING', true);
        commit('RESET_PASSWORD_RESET_ERROR');
        const response = await accountService.resetPassword(payload).catch(function (error) {
            commit('COMMIT_LOADING', false);
            if (error.response) {
              console.log(error.response.data);
              commit('SET_PASSWORD_RESET_ERROR', error.response.data);
              commit('SET_PASSWORD_RESET_STATUS', true);
            } 
          });
        if(response && response.status === 200) {
            commit('COMMIT_LOADING', false);
            commit('SET_PASSWORD_RESET_STATUS', false);
            router.push({ name: 'Home' });
        }
    },
    createAccount({ dispatch }, payload) {
        accountService.createAccount(payload).then(resp => {
            router.push({ name: 'Home' });
        })
        .catch(err => {
            console.log(err);
        });
    },
    async createAccountInline({ commit }, payload) {
        commit('COMMIT_LOADING', true);
        commit('RESET_CREATE_ERROR');
        var response = await accountService.createAccount(payload).catch(function (error) {
            commit('COMMIT_LOADING', false);
            if (error.response) {
                console.log(error.response.data);
                commit('SET_CREATE_ERROR', error.response.data);
            }
        });

        if(response && response.status === 200) {
            commit('COMMIT_LOADING', false);
            commit('SET_USER_GUID', response.data)            
        }
    },
    async updateUsersCustomerGuid({ commit }, payload) {
        commit('COMMIT_LOADING', true);
        var response = await accountService.updateUsersCustomerUID(payload).catch(function (error) {
            commit('COMMIT_LOADING', false);
            if (error.response) {
              console.log(error.response.data);              
            } 
        });
        commit('COMMIT_LOADING', false);
    },
    startRefreshTimer({dispatch}) {
        // parse json obj from base64 encoded jwt
        const jwtToken = JSON.parse(atob(state.user.token.split('.')[1]));
        // console.log(jwtToken);
        // set timer 
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(() => dispatch('refreshToken', 'auto-refreshed'), timeout);
    },
    stopRefreshTimer() {
        clearTimeout(refreshTokenTimeout);
    },
    async userExists({ commit }, payload) {
        // console.log('newuser email: ', payload)
        commit('RESET_USER_EXIST');

        var response = await accountService.isUser(payload).catch(function (error) {
            if (error.response) {
            //   console.log(error.response.data);              
            } 
        });

        if(response && response.status === 200) {
            commit('SET_USER_EXIST', response.data);
        }
    }    
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};