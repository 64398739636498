<template>
    <fieldset class="selectable-radio-buttons">
        <template v-for="d in dataArr">
            <label :key="`label-${d.id}`"
                :for="d.name" 
                class="selectable" 
                :class="localValue == d.id.toString() ? 'selected' : ''">
                <input type="radio" 
                    :id="d.name"                    
                    :value="d.id"
                    v-model="localValue"
                    v-taurus-validator="rules" 
                    :fieldlabel="fieldlabel"
                    v-if="addvalidation && i == 0">
                <input type="radio" 
                    :id="d.name" 
                    :name="d.name" 
                    :value="d.id"                   
                    v-model="localValue"
                    v-else>
                    {{ d.description }}
            </label>
            <a class="init-destination-dialog" @click.prevent="$emit('showInfo', d.id)" :key="`info-${d.id}`" href="" v-if="showHelp">
                <i class="fas fa-question-circle"></i>
            </a>
        </template>
        <!-- <label v-for="(d, i) in dataArr" 
               :key="i"
               :for="d.name" 
               class="selectable" 
               :class="localValue == d.id.toString() ? 'selected' : ''">
               <input type="radio" 
                   :id="d.name"                    
                   :value="d.id"
                   v-model="localValue"
                   v-taurus-validator="rules" 
                   :fieldlabel="fieldlabel"
                   v-if="addvalidation && i == 0">
            <input type="radio" 
                   :id="d.name" 
                   :name="d.name" 
                   :value="d.id"                   
                   v-model="localValue"
                   v-else>
            {{ d.description }}
        </label>
        <a class="init-destination-dialog" @click="this.$emit('showInfo', selectedValue)">
    		<i class="fas fa-question-circle"></i>
    	</a> -->
        <div :name="dataArr[0].name" v-if="addvalidation"></div>
    </fieldset>
</template>

<script>
export default {
    name: 'selectableRadios',
    data: () => ({
        dataVal: null
    }),
    model: {
        prop: 'selectedValue',
        event: 'change'
    },
    props: {
        dataArr: Array,
        value: [Number, String, Boolean],
        selectedValue: [Number, String, Boolean],
        addvalidation: {
            default: false,
            type: Boolean
        },
        rules: String,
        fieldlabel: String,
        showHelp: {
            default: true,
            type: Boolean
        }
    },
    computed: {
        localValue: {
            get () {
                return this.selectedValue
            },
            set () {
                this.$emit('change', event.target.value)
            }
        }
    }    
}
</script>