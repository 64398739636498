<template>
    <div>
        <label for="diff-billing-address" class="selectable">
            <input type="checkbox" id="diff-billing-address" name="diff-billing-address" v-model="diffBillingAddress">
            Use existing billing address
        </label>
        <form v-if="!diffBillingAddress">
            <fieldset class="common">
                <label for="address-line-1">Address line 1</label>
                <input type="text" name="address-line-1" id="address-line-1" v-model="addressLine1"/>
            </fieldset>

            <fieldset class="common">
                <label for="address-line-2">Address line 2 <span class="optional">&mdash; Optional</span></label>
                <input type="text" name="address-line-2" id="address-line-2" v-model="addressLine2"/>
            </fieldset>

            <fieldset class="common">
                <label for="towncity">Town/city</label>
                <input type="text" name="towncity" id="towncity" v-model="town"/>
            </fieldset>

            <fieldset class="common">
                <label for="county">County</label>
                <input type="text" name="county" id="county" v-model="county"/>
            </fieldset>

            <fieldset class="common">
                <label for="postcode">Postcode</label>
                <input type="text" name="postcode" class="postcode" id="postcode" v-model="postcode"/>
            </fieldset>
        </form>
        <loading-transition :loading="loading" :height="loadingHeight" v-if="loading" id="loading-tran"/>
        <div ref="braintreewrapper" id="braintree-form"/>    
        <!-- <button @click.prevent="sub" v-if="!paymentMethodRequestSuccess && instanceLoaded">Add payment method</button>     -->
        <loadingButton buttonLabel="Add payment method" :isLoading="paymentMethodLoading" @onClick="sub" v-if="!paymentMethodRequestSuccess && instanceLoaded"/>   
    </div>
</template>

<script>
import braintree from 'braintree-web-drop-in';
import { braintreeService } from '../api/services/paymentService';
import { mapGetters } from 'vuex';
import loadingTransition from './loadingTransition.vue';
import loadingButton from './loadingButton.vue'

export default {
    name: 'payment',
    components: {
        loadingTransition,
        loadingButton
    },
    data: () => ({
        dropInInstance: null,        
        nonce: null,
        options: {
            dataCollector: true,
            threeDSecure: true,
            vaultManager: false,
            collectDeviceData: true,
            requestThreeDSecureVerification: true,
            card: {
                cardholderName: true
            },
            locale: 'en_GB'
        },
        paymentMethodRequestSuccess: false,
        diffBillingAddress: true,
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        postcode: '',
        loading: false,
        paymentMethodLoading: false,
        loadingHeight: 200,
        instanceLoaded: false
    }),
    methods: {
        async init(token) {             
            const options = {
                ...this.options,                
                authorization: token,
                container: this.$refs.braintreewrapper
            }
            this.loading = false;
            braintree.create(options, (err, dii) => {  
                // this.loading = false;
                this.dropInInstance = dii;                           
                if (err) {
                    if (this.onError) {
                        this.onError(err)
                    }
                    return
                } else {
                    this.instanceLoaded = true;
                    if (this.onCreate) {
                        this.onCreate(dii)
                        
                    }
                }                
            });
        },
        async OnCreate(dii) {
            // console.log('created: ', dii);
            var test= 'tesr';
        },
        async createToken () {
            // console.log('createToken web');
            try {
                var paymentObj = await braintreeService.getClientToken(this.customer.uid);
                // console.log(paymentObj);
                return paymentObj.data.token;
            } catch (err) {
                return err;
            }
        },
        async onError (error) {
            try {
                console.log('onError', error);
            } catch (err) {
                throw err;
            }
        },
        sub() {
            //use billing name/address when filled out!!!
            this.paymentMethodLoading = true;
            var threeDSecureParameters = {
                amount: this.policy.totalPremium,
                email: this.customer.emailAddress,
                //mobilePhoneNumber: "07922 664069",
                billingAddress: {
                    givenName: this.customer.firstName,
                    surname: this.customer.lastName,
                    phoneNumber: this.customer.telephoneNumber,
                    streetAddress: this.custAddress.addressLine1,
                    extendedAddress: this.custAddress.addressLine2,
                    locality: this.custAddress.county,
                    //region: "ESS",
                    postalCode: this.custAddress.postCode,
                    countryCodeAlpha2: "GB"
                },
                additionalInformation: null,
            };

            this.dropInInstance.requestPaymentMethod({threeDSecure: threeDSecureParameters}, (err, payload) => {
                if (err) {
                    if (this.onError) {
                        this.onError(err)
                    }
                    this.paymentMethodRequestSuccess = false;
                    this.$emit('payment-method-success', false)
                } else {
                    this.nonce = payload
                    this.$store.commit('COMMIT_NONCE', payload)
                    this.paymentMethodRequestSuccess = true;
                    this.$emit('payment-method-success', true)
                }
                this.paymentMethodLoading = false;
            });            
        }
    },
    async mounted() {
        this.paymentMethodRequestSuccess = false;
        this.$emit('payment-method-success', false);
        if (this.dropInInstance) {
            this.paymentMethodRequestSuccess = true;
            this.$emit('payment-method-success', true);
            return;
        } 
        this.loading = true;
        this.init(await this.createToken());
    },
    computed: {
        ...mapGetters({
            custAddress: 'customer/getAddress',
            customer: 'customer/getCustomer',
            policy: 'getPolicy'
        })
    }
}
</script>

<style lang="scss" scoped>
#braintree-form {
    margin-bottom: 20px;
}
#loading-tran {
    position: static;
}
</style>