<template>
  <div>
    <div class="hero-home-page">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <h1>Car Hire Excess Insurance</h1>
            <p class="intro">
              Hiring a car when travelling abroad allows you to take to the 
              road at your own pace. However, 
              we’re well aware that this can initially seem like a daunting task. 
              Once you’ve secured your hire car, in the unfortunate event of an accident, 
              you don’t want to be left short. As such, we want to make sure 
              you're as protected as possible. This is where {{ site.brandName }}’ 
              Car Hire Excess comes in.              
            </p>
            <router-link class="button-main-cta" to="/get-a-quote">Get a better deal</router-link>
          </div>
          <div class="col-md-5">
            <picture-element  :delimeters="['1x','2x']"
                              :imgName="`vw-golf-${site.brand}`" 
                              classes="car"  
                              altText="A photograph of a white Volskwagen Golf car." 
                              path="./assets/img/" 
                              :settings="[{imgType: 'webp', media: '(min-width: 480px)'},{imgType: 'png', media: '(min-width: 480px)'},]"/>            
          </div>
        </div>
      </div>
    </div>
    <!-- END Home page hero section  -->

    <div class="container">
      <!-- START What is care hire excess insurance? section -->
      <section
        class="what-is-car-hire-excess-insurance"
        id="what-is-car-hire-excess-insurance"
      >
        <div class="row no-gutters">
          <div class="col-md-6 col-sm-12">
            <div class="white-box">
              <h3>What is car hire excess insurance?</h3>
              <p>
                Car Hire Excess Insurance is an additional insurance coverage 
                that can be purchased outside of your car hire agreement and 
                can supplement the basic insurance provided under that agreement. 
              </p>
              <p>
                This type of cover will insure you against your agreed upon excess 
                amount - in other words, the voluntary amount you would have to pay 
                your rental company if your car is damaged or stolen.
              </p>
              <p>
                 An often overlooked part of the insurance provided under your car 
                 rental agreement means this can range between £500 - £2,000 
                 (sometimes more), resulting in you having to pay out of your own pocket, 
                 despite having insurance under your rental agreement.
              </p>
              <p>
                Holiday Extras’ Car Hire Excess completely eliminates this, giving 
                you peace of mind that you’re covered, should anything unfortunate happen. 
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="quote-box">
              <svg
                width="43px"
                height="38px"
                class="quote-mark"
                viewBox="0 0 43 38"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
                <title>“</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="g1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="g2"
                    transform="translate(-751.000000, -704.000000)"
                    fill="#61D9FF"
                    fill-rule="nonzero"
                  >
                    <g id="g3" transform="translate(240.000000, 646.000000)">
                      <g id="g4" transform="translate(0.000000, 28.000000)">
                        <path
                          class="fill"
                          d="M531,68 L511,68 L511,52.3995098 C511,46.656017 512.180065,42.1233826 514.54023,38.8014706 C516.900395,35.4795586 520.946331,32.5457644 526.678161,30 L531,38.2892157 C527.475078,39.9656947 525.038321,41.6343871 523.689655,43.2953431 C522.340989,44.9562992 521.590039,46.9199233 521.436782,49.1862745 L531,49.1862745 L531,68 Z M554,68 L534,68 L534,52.3995098 C534,46.656017 535.180065,42.1233826 537.54023,38.8014706 C539.900395,35.4795586 543.946331,32.5457644 549.678161,30 L554,38.2892157 C550.475078,39.9656947 548.038321,41.6343871 546.689655,43.2953431 C545.340989,44.9562992 544.590039,46.9199233 544.436782,49.1862745 L554,49.1862745 L554,68 Z"
                          id="path1"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <p class="quote">
                …what a lot of people don’t realise, is that often if the hire
                car is damaged in any way, you are typically liable to pay the
                excess anyway. This is often between £500 and £2,000…
              </p>
              <router-link class="button-main-cta" to="/get-a-quote">Get a better deal</router-link>
              <img
                :src="require(`../assets/svg/icon-${site.brand}-graphic.svg`)"
                alt="quote"
                class="soi-graphic"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- END What is care hire excess insurance? section -->

      <!-- START Who, when, where, why section -->
      <section class="who-when-where-why" id="who-is-eligible">
        <div class="row-wrapper">
          <div class="white-box">
            <h3>Who is eligible?</h3>
            <p>
              You are eligible if you're a UK resident aged between {{site.driverAgeMin}}-{{site.driverAgeMax}} with a full, 
              valid UK driving licence. 
            </p>
            <i class="fad fa-users"></i>
          </div>
          <div class="dashed-horizontal-line"></div>
          <div class="white-box">
            <h3>How can I secure excess cover?</h3>
            <p>
              Simply click <router-link to="/get-a-quote">here</router-link>, and follow the instructions, and you’re all set!
            </p>
            <i class="fad fa-mouse"></i>
          </div>
        </div>
        <div class="row-middle">
          <div class="dashed-vertical-line-left"></div>
          <div class="middle-spacer"></div>
          <div class="dashed-vertical-line-right"></div>
        </div>
        <div class="row-wrapper">
          <div class="white-box">
            <h3>Where am I covered?</h3>
            <p>
              We offer excess cover in the UK, Europe and Worldwide.
            </p>
            <i class="fad fa-globe-europe"></i>
          </div>
          <div class="dashed-horizontal-line"></div>
          <div class="white-box">
            <h3>Do I need it?</h3>
            <p>
              Though not essential, excess charges can often put you hundreds, 
              even thousands of pounds out of pocket. This is completely 
              eliminated by car hire excess insurance, as a fraction of the price 
              subject to policy conditions and exclusions.
            </p>
            <i class="fad fa-money-bill-alt"></i>
          </div>
        </div>
      </section>
      <!-- END Who, when, where, why section -->

      <!-- START What's included section -->
      <section class="whats-included" id="what-is-included">
        <div class="dashed-box">
          <div class="white-out">
            <h2>What is included?</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="included-service">
              <h3>Excess/Deposit reimbursement</h3>
              <i class="fad fa-hand-holding-usd"></i>
              <p>
                We offer reimbursement up to £7,500 for any one incident , 
                with a limit of £10,000 for the policy year if you 
                purchase annual cover.
              </p>
            </div>
          </div>          
          <div class="col-md-6 col-sm-12">
            <div class="included-service">
              <h3>Key replacement</h3>
              <i class="fad fa-key"></i>
              <p>
                If your car keys are lost, stolen, or damaged during your trip, 
                we offer cover up to £500 for key replacement up to £2,000 
                within the policy year, and £200 for lockout expenses.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="included-service">
              <h3>Personal possessions</h3>
              <i class="fad fa-headphones"></i>
              <p>
                We cover you for up to £300 for personal possessions. 
                This includes £150 for single item, pair or sets, and £50 for tobacco, 
                alcohol and fragrances.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="included-service">
              <h3>Accidental misfuelling</h3>
              <i class="fad fa-gas-pump"></i>
              <p>
                If you accidentally fill up your rental car with the wrong fuel, 
                we pay up to £500 for any misfuelling, up to £1,000 within the policy year.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="included-service-last">
              <h3>Rental vehicle breakdown cover</h3>
              <i class="fad fa-car"></i>
              <p>
                We will pay up to £500 for cost of recovering your broken down or damaged rental vehicle.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="included-service-last">
              <h3>UK cover available</h3>
              <i class="fad fa-map-marked-alt"></i>
              <p>
                If you’re planning on a staycation, our car hire excess cover is 
                available in the UK as well as worldwide. 
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- END What's included section -->

      <!-- START CTA Banner w/ man and tablet -->
      <div class="cta-banner">
        <div class="row">
          <div class="col">
            <p class="quote">
              Don’t get caught out by hidden excess charges from car rental companies. 
              Save money and have peace of mind with a Car Hire Excess policy from {{site.brandName}}. 
            </p>
            <router-link class="button-main-cta" to="/get-a-quote">
              Get a better deal
            </router-link>
          </div>
          <div class="col-md-auto">
            <picture-element  :delimeters="['1x','2x']"
                              :imgName="`man-and-tablet`" 
                              classes="man-and-tablet"  
                              altText="A photograph of a really happy man wearing a shirt and tie with his first in the air, smiling looking at a tablet device."
                              path="../assets/img/" 
                              :settings="[{imgType: 'webp', media: '(min-width: 480px)'},{imgType: 'png', media: '(min-width: 480px)'},]"/>
          </div>
        </div>
      </div>
      <!-- END CTA Banner w/ man and tablet -->
    </div>
    <!--  END .container -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import PictureElement from '../components/pictureElement.vue';

export default {      
  name: "Home",
  data() {
    return {
      title: 'Home',
      pageDescription: 'We offer great deals on Car Hire Excess Insurance, come and see how you could save money and get the right cover that protects you and your vehicle today.'
    };
  },
  components: {
    PictureElement
  },
  mounted() {
    this.$nextTick(() => { // scrolls to content linked in footer on page load
      if(this.$route.hash) {
        const el = document.querySelector(this.$route.hash);
        el && el.scrollIntoView();
      }
    })
  },
  metaInfo() {
    return { 
      title: this.title,
      meta: [
        { name: 'description', content: this.pageDescription },
        { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
        { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
        { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      site: "template/site",
      brand: 'template/getBrand',
      brandName: 'template/getBrandName'
    })
  }
};
</script>
