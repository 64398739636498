<template>
    <div class="container">

        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

        <nav class="breadcrumbs">
            <ul>
                <li><router-link to="/home">Home</router-link></li>
                <li><a class="active">Privacy Policy</a></li>
            </ul>
        </nav>

        <div class="row">
            <div class="col-md-8 col-sm-12">
                <div class="inner">
                    <h2>Privacy &amp; Cookie Policy</h2>
                    <p class="subheading">{{site.brandName}} Cover Limited, ERGO Travel Insurance Services Ltd and Taurus Insurance Services (hereafter referred to as “<strong>We</strong>”), as the Data Controllers, are committed to protecting and respecting your privacy in accordance with the current General Data Protection Regulation ("<strong>Regulation</strong>").</p>
                    <p>{{site.brandName}} Cover Limited (“<strong>{{site.brandName}}</strong>”) are also the data controller in respect of your marketing data. Refer to section 10 of this Privacy Notice.</p>
                    <p>Below is a summary of the main ways in which we process your personal data.</p>
                    <div class="main-content">
                        <div class="internal-anchor-links">                            
                            <h3 id="link1">1. How we use your personal data</h3>
                            <p>We use the personal data we hold about you for the purposes of providing a contract of insurance, handling claims and any other related purposes (this may include underwriting decisions made via automated means), for offering renewal, research or statistical purposes and to provide you with information, products or services that you request from us or which we feel may interest you.</p>
                            <p>Most commonly, we will use your personal data in the following circumstances:</p>
                            <ul id="privacy-list">
                                <li>Where we need to perform the contract we are about to enter into or have entered into with you</li>
                                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests</li>
                                <li>Where we need to comply with a legal or regulatory obligation</li>
                            </ul>
                            <p>We will also use your data to safeguard against fraud and money laundering and to meet our general legal obligation or regulatory obligation.</p>
                            <h3 id="link2">2. Special categories of personal data</h3>
                            <p>Some of the personal information, such as information relating to health or criminal convictions, may be required by us for the specific purposes of the claims handling process. The provision of such data is conditional for us to be able to provide insurance or manage a claim. Such data will only be used for the specific purposes set out in the claims administrator <a class="link" href="https://www.ergotravelinsurance.co.uk/privacy-statement/" target="_blank" rel="noopener">Privacy Statement</a>.</p>
                            <h3 id="link3">3. Disclosure of your personal data</h3>
                            <p>We disclose your personal data to third parties involved in providing products or services to us, or to service providers who perform services on our behalf. These include our group companies, affinity partners, brokers, agents, third party administrators, reinsurers, other insurance intermediaries, insurance reference bureaus, credit agencies, medical service providers, fraud detection agencies, loss adjusters, external law firms, external accountants and auditors, regulatory authorities, and as may be required by law.</p>
                            <h3 id="link4">4. International transfers of data</h3>
                            <p>We may transfer your personal data to destinations outside the United Kingdom (“<strong>UK</strong>”) or European Economic Area (“<strong>EEA</strong>”). Where we transfer your personal data outside of the UK or EEA, we will ensure that it is treated securely and in accordance with any applicable Data Protection legislation.</p>
                            <h3 id="link5">5. Your rights</h3>
                            <p>You have the right to ask {{site.brandName}} not to process your data for marketing purposes, to see a copy of the personal information we hold about you, to have your data deleted (subject to certain exemptions), to have any inaccurate or misleading data corrected or deleted, to restrict the processing of your data, to ask us to provide a copy of your data to any controller and to lodge a complaint with the local data protection authority.</p>
                            <p>Taurus have appointed a UK Data Representative who is responsible for overseeing questions in relation to this policy. If you have any questions, including any requests to exercise your legal rights, please contact them using the details below:</p>
                            <p>E-Mail: uk.dp.representative@taurussupport.com </p>
                            <p>Write: Taurus Support Services Ltd, 29a Crown Street, Brentwood, Essex, England, CM14 4BA</p>
                            <h3 id="link6">6. Retention</h3>
                            <p>Your data will not be retained for longer than is necessary and will be managed in accordance with our data retention policy. In most cases the retention period will be for a period of seven (7) years following the expiry of the insurance contract, or our business relationship with you, unless we are required to retain the data for a longer period due to business, legal or regulatory requirements.</p>
                            <h3 id="link7">7. Sharing of personal data</h3>
                            <p>In addition to the third parties mentioned above, we may disclose your information to third parties for our legitimate business interests or as follows to supply certain services. In some cases, those third parties may require access to some or all of your personal data that we hold. Details of our third party supplier Privacy policies can be found under the section Third Party Policies.</p>
                            <p>Whenever fraud prevention agencies transfer your personal data outside of the UK or EEA, they too will impose contractual obligations on the recipients of that data to protect your personal data to the standard required in the EEA. They also require the recipient to subscribe to ‘international frameworks’ intended to allow secure data sharing.</p>
                            <h3 id="link8">8. More information</h3>
                            <p>For more details regarding the Cookie Policy of {{site.brandName}} please go to <a class="link" :href="site.brandCookiePolicy" target="_blank" rel="noopener">{{site.brandCookiePolicy}}</a></p>
                            <p>For more details and the Taurus Privacy and Cookie Policy in full please download the complete <a class="link" href="https://www.taurus.gi/content/docs/Taurus-Privacy-Policy-v20210511.pdf" target="_blank" rel="noopener">Taurus Privacy Policy document</a>.</p>
                            <h3 id="link9">9. Thirty party policies</h3>
                            <p>PayPal (Europe) S.à r.l. et Cie, S.C.A. operating as Braintree, Merchant Card Payment services provider: <a class="link" href="https://www.braintreepayments.com/gb/legal/braintree-privacy-policy" target="_blank" rel="noopener">https://www.braintreepayments.com/gb/legal/braintree-privacy-policy</a></p>
                            <!-- <p>Great Lakes Insurance SE, policy underwriter: <a class="link" href="https://www.munichre.com/glise/en/general/privacy.html" target="_blank" rel="noopener">https://www.munichre.com/glise/en/general/privacy.html</a></p> -->
                            <p>ERGO Travel Insurance Services Ltd, policy administration: <a class="link" href="https://www.ergotravelinsurance.co.uk/privacy-statement/" target="_blank" rel="noopener">https://www.ergotravelinsurance.co.uk/privacy-statement/</a></p>
                            <h3 id="link10">10. Marketing</h3>
                            <p>10.1 Purpose</p>
                            <p>{{site.brandName}} use the marketing contact data provided for the purpose for which it was collected: to send requested content and to respond to marketing questions.  {{site.brandName}} also use marketing contact data to send notices for direct marketing of products where you have provided your consent.</p>
                            <p>10.2 Legal Basis</p>
                            <p>{{site.brandName}} process marketing data based on consent given by individuals who opt-in. {{site.brandName}} may also process the marketing data where there is a legitimate interest to do so but only where your interests or rights are not overridden by doing so.</p>
                            <p>10.3 What personal data do {{site.brandName}} collect?</p>
                            <p>Marketing data includes your contact details such as name, physical address, email and telephone number.</p>
                            <p>10.4 Where your data is stored and who it is shared with</p>
                            <p>{{site.brandName}} share marketing data with carefully selected suppliers that carry out certain functions, including technology and data management partners who help us to administer the services and companies that help with IT services, storing and combining data, marketing, advertising campaign, and market research.</p>
                            <p>10.5 Retention</p>
                            <p>If you’ve agreed to receive marketing communications, your personal data will be retained for up to 12 months after your insurance ends, or such other time that may be required for legal and audit purposes or where required by law.  After this period, your data will be securely erased.  If your personal data is needed after this period for analytical, historical or other legitimate business purposes, appropriate measures will be taken to anonymise this personal data.</p>
                            <p>You have the right at any time to ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by selecting the ‘no marketing’ option on the forms we use to collect your data. You can also exercise this right at any later time by using the unsubscribe link on any marketing e-mail you receive, or by contacting us.</p>
                            <p>10.6 Contact details:</p>
                            <p>If you have any questions or comments about how your marketing data is managed, or wish to contact us to exercise any of your rights please contact:</p>
                            <p>Address:  Customer Experience, {{site.brandName}}, Ashford Road, Newingreen, Hythe, Kent.  CT21 4JF</p>
                            <p><a :href="site.onlineContact" class="link" target="_blank" rel="noopener">Contact us online</a></p>                            
                            <p>10.7 Data Subject Requests</p>
                            <p>You have the right to make a Data Subject Access Request in many circumstances. That is a request for access to the personal data that is held about you, there is no charge for this unless the request is manifestly unfounded or excessive.</p>
                            <p>{{site.brandName}} may ask for proof of identity and sufficient information about your interactions to assist in locating your personal data. If someone is acting on your behalf they will need to provide written and signed confirmation from you that you have given your authority to that person/company for them to make the request. Evidence of this will need to be provided before and data is provided to you (or another person acting on your behalf).  Data will not be provided if it includes the personal data of other individuals or there is any other lawful reason to withhold that information.</p>
                            <p>Please see the section above titled ‘Contact details’ if you need to make a Data Subject Access Request.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <aside class="sidebar" id="sidebar">
                    <h4>Skip to section</h4>
                    <ul>
                        <!-- <li><a href="#link1">Data Protection</a></li> -->
                        <li><a href="#link1">1. How we use your personal data</a></li>
                        <li><a href="#link2">2. Sensitive personal data</a></li>
                        <li><a href="#link3">3. Disclosure of your personal data</a></li>
                        <li><a href="#link4">4. International transfers of data</a></li>
                        <li><a href="#link5">5. Your rights</a></li>
                        <li><a href="#link6">6. Retention</a></li>
                        <li><a href="#link7">7. Sharing of personal data</a></li>
                        <li><a href="#link8">8. More information</a></li>
                        <li><a href="#link9">9. Third party policies</a></li>
                        <li><a href="#link10">10. Marketing</a></li>
                    </ul>
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        title: 'Privacy policy',
        pageDescription: 'The details on how we collect and process your personal information through our website and services.'
    }),
    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            site: 'template/site'
        })
    }
}
</script>

<style lang="scss" scoped>
#privacy-list {
    list-style: disc;
    list-style-position: outside;
}
</style>