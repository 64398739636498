<template>
    <div>
        <heroInner :title="title" />
        <div class="container">

            <a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>

            <nav class="breadcrumbs">
                <ul>
                    <li><router-link to="/home">Home</router-link></li>
                    <li><router-link to="/contact-us">Contact us</router-link></li>
                </ul>
            </nav>

            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="inner">
                        <h2>Get in touch</h2>
                        <p><a :href="site.onlineContact" target="_blank">Contact us online</a></p>
                        <p>Use this service to contact us, however please note that you may find the information that you are looking for in our <router-link to="/faq" class="link">Frequently asked questions</router-link>.</p>
                        <section class="bordered" id="quotes">
                            <h2>Claims</h2>
                            <p>To discuss an existing insurance claim:</p>
                            <p class="label">Phone</p>
                            <a :href="'tel:' + site.telephoneQuote">{{ site.telephoneQuote }}</a>
                            <p>Lines open 09:00-17:30 Mon-Fri</p>
                            <p class="label">Email</p>
                            <a :href="'mailto:' + site.emailAddressQuote" class="link">{{ site.emailAddressQuote }}</a>
                        </section>
                        <section class="bordered" id="complaints">
                            <h2>Complaints</h2>
                            <p>Any enquiry about a claim in the first instance should be addressed in writing to:</p>
                            <p>The Complaints Manager<br>
                            <span v-html="site.addressHtml"></span>
                            </p>
                            <p class="label">Email</p>
                            <a :href="'mailto:' + site.emailAddressComplaints" class="link">{{ site.emailAddressComplaints }}</a>
                        </section>
                        <section class="bordered" id="marketing">
                            <h2>Opt Out of Marketing</h2>
                            <p>If you wish to opt out of marketing and not receive information about Holiday Extras products and services please:</p>
                            <p><a :href="site.onlineContact" target="_blank">Contact us online</a></p>
                            <p>However, we will still contact you with important insurance and service-related updates.</p>                            
                        </section>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <usefulLinks />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
export default {
  data() {
    return {
      title: "Contact us",
      pageDescription: 'Get in touch with us today to see how we can provide quality car hire excess insurance. Contact information for quotes and complaints.'
    };
  },
  metaInfo() {
    return { 
        title: this.title,
        meta: [
          { name: 'description', content: this.pageDescription },
          { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
          { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
        ]
    }
  },
  components: {
    heroInner,
    usefulLinks
  },
  computed: {
    ...mapGetters({
      site: "template/site"
    })
  }
};
</script>