<template>
<header>
    <div class="container">
        <div class="logo-nav-wrapper">
            <div class="row align-items-center">
                <div class="col">
                    <a :href="getSiteUrl + '/home'" title="Return to the home page" class="logo-link logo-animation-transition-in"></a>
                </div>
                <div class="col-md-auto justify-content-end">
                    <div class="contact-info">
                        <!-- v-if needs to work on user token -->
                        <div class="row" v-if="!isAuthenticated">
                            <div class="col-md-auto" v-if="site.hasTelephoneDisplayed">
                                <p class="sub">Call</p>
                                <p class="contact"><a v-bind:href="'tel:' + site.telephoneNumber">{{ site.telephoneNumber }}</a></p>
                            </div>
                            <div class="col-md-auto" v-if="site.hasTelephoneDisplayed">
                                <p class="sub">Lines open</p>
                                <p class="contact">{{ site.linesOpen }}</p>
                            </div>
                            <div class="col-md-auto" v-else>                                
                                <p class="contact"><a :href="site.onlineContact" target="_blank">Contact us online</a></p>
                            </div>
                            <div class="col-md-auto" v-if="site.hasAccountSection">
                                <router-link class="button-login" to="/log-in"><i class="fas fa-sign-in-alt"></i> Log in</router-link>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-auto">
                                <p class="sub">Logged in as</p>
                                <p class="contact">{{userName}}</p>
                            </div>
                            <div class="col-md-auto">
                                <a class="button-logout" @click="logout">Log out <i class="fas fa-sign-out-alt"></i></a>
                            </div>
                        </div>
                    </div>
                    <!-- Toggle mobile menu -->
                    <a class="hamburger hamburger--spin hidden" @click="toggleNav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="mobile-border"></div>
</header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


export default {
    data () {
        return {            
            toggle: false
        }
    },
    mounted() {
        this.showMenu();
        // console.log(this.$route.name);
    },
    methods: {
        ...mapActions({
            logoutAsync: "account/logout"
        }),
        toggleNav() {
            this.toggle = !this.toggle;
            this.showMenu();            
        },
        showMenu() {

            var contents = document.getElementsByClassName('responsive-menu-contents')
            var overlay = document.getElementsByClassName('responsive-menu-overlay')
            var hamburger = document.getElementsByClassName('hamburger')
            var html = document.getElementsByTagName("html")[0];
            
            if(this.toggle) {     
                hamburger[0].classList.add('is-active');
                Velocity(contents, 'fadeIn')
                Velocity(overlay, 'fadeIn')
                html.classList.add('prevent-scroll');
            }
            else {
                hamburger[0].classList.remove('is-active');
                Velocity(contents[0], "fadeOut");
                Velocity(overlay[0], "fadeOut");
                html.classList.remove('prevent-scroll');
            }
        },
        async logout() {
            await this.logoutAsync();
        }
    },
    computed: {
        ...mapGetters({
            site: 'template/site',
            getSiteUrl: 'template/getSiteURL',
            isAuthenticated: 'account/isAuthenticated',
            userName: 'account/getUsername'
        })
    }
}
</script>