import moment from 'moment';

export default {
    data: () => ({}),
    methods: {
        formatCSharpTime(val, format) {
            // console.log("f ", format)
            // console.log("val: ", val);
            if(!val) return '';
            if(format) {
                return moment(val, format).format('YYYY-MM-DD');
            } else {
                return moment(val, "DD/MM/YYYY").format('YYYY-MM-DD');
            }            
        },
        addYearToDate(date, years, format) {
            if(!date) return '';
            if(format) {
                return moment(date).add(years, 'y').subtract(1, 'minute').format(format);
            } else {
                return moment(date).add(years, 'y').subtract(1, 'minute').format('DD/MM/YYYY');
            }            
        },
        formatDisplayTime(val) {
            if(!val) return '';
            return moment(val).format('DD/MM/YYYY');
        },
        formatDestination(val) {
            if(!val) return '';
            var types = this.$store.getters['template/getCoverRegion'];
            return types.find(t => t.id == parseInt(val)).description;            
        },
        formatPolicyType(val) {
            if(!val) return '';
            var types = this.$store.getters['template/getTripType'];
            return types.find(t => t.id == parseInt(val)).description;            
        },
        driverNumber(mainDrivers, namedDrivers) {
            var mdriver = 'x' + mainDrivers.length + ' Main driver';
            var ndriver = (namedDrivers.length) ? '<br> x' + namedDrivers.length + ' Named driver' : '';
            return mdriver + ndriver;            
        },
        constructDob(year, month, day) {
            return new Date(year + '-' + month + '-' + day).toISOString();
        },
        deconstructDob(dob) {
            var _dob = dob.split('/');
            // console.log(_dob);
            var dobObj = {
                day: _dob[2],
                month: _dob[1],
                year: _dob[0]
            }
            return dobObj;
        },
        sqlDatetimeToUserDisplay(val) {
            if(!val) return '';
            // console.log("sql -> user: ", moment(val).format('YYYY/MM/DD'))
            return moment(val).format('YYYY/MM/DD');
        },
        isBeforeDate(startDate, endDate) {
            return moment(endDate, 'DD/MM/YYYY').
                isBefore(moment(startDate, 'DD/MM/YYYY'));
        },
        isMoreThanYear(startDate, endDate) {
            return moment(endDate, 'DD/MM/YYYY')
                .isAfter(moment(startDate, 'DD/MM/YYYY').add(1, 'y'));
        },
        isOutsideFutureBooking(departDate, daysInFuture) {
            return moment(departDate, 'DD/MM/YYYY').isAfter(moment(new Date()).add(daysInFuture, 'd'));
        },
        tripDuration(startDate, endDate, inFormat) {
            if(!startDate || !endDate) return '';
            var start = moment(startDate, inFormat)
            var end = moment(endDate, inFormat)   
            var roundUp = end.minute() || end.second() || end.millisecond() ? end.add(1, 'hour').startOf('hour') : end.startOf('hour');
            // console.log('round up: ', roundUp.toString());  // outputs date rounded up to nearest hour
            // console.log(inFormat)
            // console.log(start)
            // console.log(end);
            // console.log('test: ', end.diff(start, 'days'))
            return end.diff(start, 'days');
        },
        isOver18(dob) {
            return Math.floor(moment(new Date()).diff(moment(dob,"YYYY-MM-DD"),'years',true));
        },
        isNullOrWhitespace(input) {
            return !input || !input.trim();
        },
        timeDayDuration(startDate, endDate) {
            var start = moment(startDate, 'YYYY/MM/DD h:mm:ss')
            var end = moment(endDate, 'YYYY/MM/DD h:mm:ss') 
            return end.diff(start, 'days', true);
        },
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        dateTimeNow() {
            return moment().format('YYYY-MM-DD hh:mm:ss')
        },
    },
    filters: {
        dateTimeFormat: function(val) {
            if(!val) return '';            
            return moment(val).format('DD/MM/YYYY, HH:mm:ss')
        },
        longDateFormat: function(val) {
            if(!val) return '';            
            return moment(val).format('dddd, MMMM Do YYYY')
        },
        shortDateFormat: function(val) {
            if(!val) return '';
            return moment(val).format('DD/MM/YYYY');
        },
        formatCurrency: function(val) {
            if(!val) return '£0.00';
            return '£' + val.toFixed(2);
        },        
    }
}