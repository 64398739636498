import api from './index.js';


const setup = (store) => {
  api.interceptors.request.use(function(config) {
    config.headers['X-Api-TenantId'] = store.getters['template/getTenant'];
    // console.log(config)
    return config;
  });

  if(store.getters["template/getAccountSection"]) {
    api.interceptors.request.use(request => {
      var user = store.getters["account/getUser"];
      if(user && user.token) {
        request.headers.common.Authorization = `Bearer ${user.token}`;
      }  
      return request;
    });
  
    // Add a response interceptor
    api.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      async function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        console.log("---interceptor error---");
        // console.log(error);
        
        const originalRequest = error.config;
        console.log(error);
        console.log(originalRequest);
        console.log(error.response);
        
        if (error.response.status === 401 && originalRequest.url.includes("/account/refresh")) { //both tokens expired
          // console.log("1st");
          //remove user from store
          store.commit("account/RESET_USER");        
          //stop counter 
          store.dispatch("account/stopRefreshTimer");
          return Promise.reject(error);
        } else if (error.response.status === 401 && !originalRequest._retry) {
          console.log("---else-if---")
          originalRequest._retry = true;
          await store.dispatch("account/refreshToken");
          return api(originalRequest);
        }
        console.log("error");
        return Promise.reject(error);
      }
    );
  }  
};
export default setup;