<template>
    <div>
		<heroInner :title="formTitle"/>
        <div class="container">

			<a class="back-link" @click="$router.go(-1)"><i class="fas fa-caret-left"></i>Back</a>
			
			<nav class="breadcrumbs">
				<ul>
					<li><router-link to="/home">Home</router-link></li>
					<li><router-link to="/get-a-quote">Get a quote</router-link></li>
					<li><router-link to="/your-quote">Your quote</router-link></li>
					<li><router-link to="/important-information">Important information</router-link></li>
					<li><router-link to="/your-details">Your details</router-link></li>					
					<li><router-link to="/main-driver/0">Main driver</router-link></li>
				</ul>
			</nav>

			<div class="row">
				<div class="col-md-8 col-sm-12">
					<div class="inner">
						<div class="page-title">
							<div class="white-bg-box">
								<h2 class="form-title">Main Driver {{selectedDriverIndex + 1}}</h2>
							</div>
							<div class="dashed-hr"></div>
						</div>
						<form>
                            <label for="radio-mr">Title</label>
                            <div class="radio-toolbar">
                                <input type="radio" id="radio-mr" name="title" value="Mr" v-model="title">                                
                                <label class="title" for="radio-mr" tabindex="0">Mr</label>
                                <input type="radio" id="radio-mrs" name="title" value="Mrs" v-model="title">
                                <label class="title" for="radio-mrs" tabindex="0">Mrs</label>
                                <input type="radio" id="radio-miss" name="title" value="Miss" v-model="title">
                                <label class="title" for="radio-miss" tabindex="0">Miss</label>
                                <input type="radio" id="radio-ms" name="title" value="Ms" v-model="title">
                                <label class="title" for="radio-ms" tabindex="0">Ms</label>
                                <input type="radio" id="radio-mx" name="title" value="Mx" v-model="title">
                                <label class="title" for="radio-mx" tabindex="0">Mx</label>  
                                <div name="radio-title" v-if="!hasTitle" class="taurus-invalid">Driver title is required</div>                              
                            </div>

                            <fieldset class="common">
                                <label for="first-name">First name</label>
                                <input type="text" id="first-name" v-model="firstName" v-taurus-validator="'required'" fieldlabel="First name"/>
                                <div name="first-name"></div>
                            </fieldset>

                            <fieldset class="common">
                                <label for="last-name">Last name</label>
                                <input type="text" id="last-name" v-model="lastName" v-taurus-validator="'required'" fieldlabel="Last name"/>
                                <div name="last-name"></div>
                            </fieldset>

                            <fieldset class="common">
                                <p>Date of birth</p>
                                <span class="ib">
                                    <label for="dob-day">Day</label>
                                    <input type="text" id="dob-day" pattern="\d*" maxlength="2" size="2" v-model="day" v-taurus-validator="'required|maxint:31'" fieldlabel="Dob day" v-on:keypress="numbersOnly"/>                                    
                                </span>
                                <span class="ib">
                                    <label for="dob-month">Month</label>
                                    <input type="text" id="dob-month" pattern="\d*" maxlength="2" size="2" v-model="month" v-taurus-validator="'required|maxint:12'" fieldlabel="Dob month" v-on:keypress="numbersOnly"/>                                    
                                </span>
                                <span class="ib">
                                    <label for="dob-year">Year</label>
                                    <input type="text" id="dob-year" pattern="\d*" maxlength="4" size="4" v-model="year" v-taurus-validator="'required'" fieldlabel="Dob year" v-on:keypress="numbersOnly"/>                                    
                                </span>
                                <div name="driverAge" v-if="!isAdult" class="taurus-invalid">Driver is required to be over 18</div>
                                <div name="dob-day"></div>
                                <div name="dob-month"></div>
                                <div name="dob-year"></div>
                            </fieldset>

                            <fieldset>
                                <p>For example: 31 3 1970</p>
                            </fieldset>

                            <a class="button-primary button-large" v-on:click="submit">Continue</a>
                            <div class="taurus-invalid" v-if="formErrorSummary">There are errors in this form</div>
                        </form>
					</div>
				</div>
				<div class="col-md-4 col-sm-12">
					<usefulLinks />
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import heroInner from "../components/heroInner";
import usefulLinks from "../components/usefulLinks";
import CommonMixin from "../mixins/common";

export default {    
  	data() {
    	return {
            pageDescription: 'Please enter the details of the main driver for our records.',
			formTitle: "Main Driver",
			selectedDriverIndex: 0,
			title: 'Mr',
			firstName: '',
			lastName: '',
			isAdult: true,
			day: '',
			month: '',
			year: '',
            hasTitle: true,
            formErrorSummary: false
        };
    },
    mixins: [
        CommonMixin
    ],
	components: {
		heroInner,
		usefulLinks
	},
	created() {
		if(this.$route.params.id) {
			this.selectedDriverIndex = parseInt(this.$route.params.id);
		}
        //if only one main driver default to policy holder
        if(this.mainDrivers && !this.mainDrivers[this.selectedDriverIndex] && this.selectedDriverIndex == 0) {
            // console.log('loading default info.....');
            this.title = this.customer.title;
            this.firstName = this.customer.firstName;
            this.lastName = this.customer.lastName;
            dateObj = this.deconstructDob(this.sqlDatetimeToUserDisplay(this.customer.dateOfBirth));
            // console.log(dateObj);
            this.day = dateObj.day;
            this.month = dateObj.month;
            this.year = dateObj.year;
        }
        //if going back to edit 
		if(this.mainDrivers && this.mainDrivers[this.selectedDriverIndex]) {
            // console.log('....loading data')
            // console.log('....loading user: ', this.mainDrivers[this.selectedDriverIndex])
            //load saved data
            this.title = this.mainDrivers[this.selectedDriverIndex].title;
            this.firstName = this.mainDrivers[this.selectedDriverIndex].firstName;
            this.lastName = this.mainDrivers[this.selectedDriverIndex].lastName;
            var dateObj = this.deconstructDob(this.sqlDatetimeToUserDisplay(this.mainDrivers[this.selectedDriverIndex].dateOfBirth));
            this.day = dateObj.day;
            this.month = dateObj.month;
            this.year = dateObj.year;
        }
        this.$store.dispatch('updateFormProgress', 'main-driver/0') 
	},
	methods: {
		submit() {
            this.isAdult = true; 
            this.hasTitle = true; //clear validations
            if(!this.isNullOrWhitespace(this.title)) {
                if(this.isOver18(this.dob) >= parseInt(this.site.driverAgeMin)) {
                    if(this.checkForm()) {
                        var driver = {
                            title: this.title,
                            firstName: this.firstName,
                            lastName: this.lastName,
                            dateOfBirth: this.formatCSharpTime(this.constructDob(this.year, this.month, this.day), 'YYYY/MM/DD'),
                            isMainDriver: true
                        }
                        var _driver = Object.assign({}, driver);
                        // include the index, (replaces object in store)
                        var payload = {
                            index: this.selectedDriverIndex,
                            driver: _driver
                        }

                        this.$store.dispatch('customer/addMainDriver', payload); // dispatch to store

                        if(this.selectedDriverIndex < this.mainDrivers.length - 1) { // cycle through till limit
                            var newidx = (this.selectedDriverIndex + 1).toString();
                            this.$router.push({ name: 'MainDriver', params: { id: newidx } });
                        }
                        else if (this.namedDrivers.length) { // named drivers are present
                            this.$router.push({ name: 'NamedDriver', params: { id: 0 } }); //push first index
                        } else { // if no named drivers move onto your-order
                            this.$router.push({ name: 'YourOrder' });
                        }
                    } 
                } else {
                    this.isAdult = false; //set age validation
                } 
            } else {
                this.hasTitle = false;
            }
                                 
        },
        checkFormErrors() {      
            this.$nextTick(() => { this.formErrors().length > 0 })
        }
	},
    metaInfo() {
        return { 
            title: this.formTitle,
            meta: [
                { name: 'description', content: this.pageDescription },
                { name: 'twitter:title', content: this.formTitle, vmid: 'twitter:title' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:description', content: this.pageDescription, vmid: 'og:description' },
                { prefix: 'og: http://ogp.me/ns#', property: 'og:url', content: window.location.href, vmid: 'og:url' }
            ]
        }
    },
	computed: {
		...mapGetters({
			site: "template/site",
			quote: 'quote/getQuotation',
            mainDrivers: 'customer/getMainDrivers',
            namedDrivers: 'customer/getNamedDrivers',
            customer: 'customer/getCustomer'
		}),
        dob() {
            return this.constructDob(this.year, this.month, this.day);
        }
	},
    watch: {
        firstName() {
            this.checkFormErrors();
        },
        lastName() {
            this.checkFormErrors();
        },
        day() {
            this.checkFormErrors();
        },
        month() {
            this.checkFormErrors();
        },
        year() {
            this.checkFormErrors();
        }        
    }
};
</script>